import { useEffect, useState, useRef } from 'react';
import { FilterButton, ActionButton, AltButton } from '../../common/Buttons/Buttons';
import { Header } from '../../common/Header/Header.jsx';
import todos from '../../../images/todos.svg';
import { useForm } from 'react-hook-form';
import particulares from '../../../images/particulares.svg';
import afiliados from '../../../images/afiliados.svg';
import { useTranslation } from 'react-i18next';
import { IncidenceColumn } from '../../common/Cards/Cards';
import {
  SearchInput,
  FilterDatePicker,
} from '../../common/Forms/Input/Input';
import * as schemas from '../../../assets/schemas/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { FilterCaseButton, ActivateButtons } from '../../common/Buttons/Buttons';
import { caseService } from '../../../services/caseService';
import { stagesService } from '../../../services/stagesService';
import { paymentService } from '../../../services/paymentService';
import { membershipService } from '../../../services/membershipService';
import { gpsService } from '../../../services/gpsService';
import { faultsService } from '../../../services/faultsService';
import { productService } from '../../../services/productService';
import authService from '../../../services/authService';
import { servicesService } from '../../../services/servicesService';
import { providersService } from '../../../services/providersService';
import { particularCustomerService } from '../../../services/particularCustomerService';
import { rateService } from '../../../services/rateService';
import { usersService } from '../../../services/usersService';
import {
  NewIncidenceModal,
  CancelCaseModal,
  FailedCaseModal,
  CancelSingleServiceModal,
  CancelServiceModal,
  UploadImageModal,
  ServiceImageModal,
  FinishCaseModal,
  SolvedByAVModal,
  FinishTowCaseModal,
  FailureAssignTowModal,
  FollowingModal,
  ChangeCaseStageModal,
  ChangeProviderModal,
  ChangeOperatorModal,
  ChangeTowModal,
  MessageAVModal,
  MessageTowModal,
  CreatePaymentModal,
  EditCashbackModal,
  SelectServicePaymentModal,
  SelectCashbackPaymentModal,
  AllDevicesMapModal,
  RollbackStageModal
} from '../../common/Modals/Modals';
import { useHistory } from 'react-router-dom';
import {
  enumStatus,
  afiliatedServices,
  particularServices,
  capitalize,
  PaymentStatusOptions,
  authorizedByOptions,
  lastServiceEnum,
  PaymentStatus,
  paymentMethods as metodosDePago,
  getProvidersEnum, userTypeOptions, userType, statusEnum, incidenceColumnsEnum, sumErrorMessage
} from '../../../assets/helpers/options';
import { checkFilterAndWarn, filterParams, hideForOperators, translateStatus, translateFullStatus, filterOption, filterOptionById, calculateMiddlePoint } from '../../../assets/helpers/funciones';
import { operationsRoles } from '../../../assets/helpers/roles';
import { notify } from '../../../assets/helpers/toast';
import moment from 'moment';
import { CraneCounter } from '../../miscelaneos/CraneCounter/CraneCounter.jsx';
import './Services.scss';

const filterButtons = [
  { name: 'Todos', value: '', image: todos },
  { name: 'Afiliados', value: 'Afiliado', image: afiliados },
  { name: 'Particulares', value: 'Particular', image: particulares },
];

export const Services = () => {
  const intervalRef = useRef(null);
  const { t } = useTranslation();
  const history = useHistory();
  const [fileUploaded, setFileUploaded] = useState([]);

  const [showCraneCounterModal, setShowCraneCounterModal] = useState({
    show: false,
    src: '',
    zoom: 1,
  });


  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    setValue: setValue2,
    reset: reset2,
    watch: watch2,
    setError: setError2,
    control: control2,
  } = useForm({
    resolver: yupResolver(schemas.CreatePaymentSchema(t)),
  });

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
    setValue: setValue3,
    reset: reset3,
    watch: watch3,
    control: control3,
  } = useForm({
    resolver: yupResolver(schemas.editCashbackSchema(t)),
  });


  const setBsRate = (e) => {
    const { name, value } = e?.target;


    if (name.includes('amount')) {
      setValue2(
        name.replace('amount', 'amountBsS'),
        (value * dollarRate).toFixed(2)
      );
    }
  };

  const [caseTypeFilter, setCaseTypeFilter] = useState(filterButtons[0].value);
  const [showIncidenceModal, setShowIncidenceModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [faultOptions, setFaultOptions] = useState([]);
  const [providersOptions, setProvidersOptions] = useState([]);
  const [towOptions, setTowOptions] = useState([]);
  const [externalTowOptions, setExternalTowOptions] = useState([]);
  const [operatorsOptions, setOperatorsOptions] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [selectedTow, setSelectedTow] = useState('');
  const [towType, setTowType] = useState(userType?.INTERNO);
  const [caseStatus, setCaseStatus] = useState('');
  const [dollarRate, setDollarRate] = useState('');
  const [devicesLocation, setDevicesLocation] = useState({
    isLoading: false,
    data: []
  });
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [filteredMethods, setFilteredMethods] = useState([]);
  const [errorsDropzone, setErrorsDropzone] = useState({});
  const [cashBackImage, setCashBackImage] = useState([]);
  const [sinceDate, setSinceDate] = useState(
    // new Date(Date.now() - 3600 * 1000 * 24)
    ''
  );
  const [untilDate, setUntilDate] = useState(
    // new Date(Date.now() + 3600 * 1000 * 24)
    ''
  );
  const [showChangeStatusModal, setShowChangeStatusModal] = useState({
    isOpen: false,
    selected: '',
  });
  const [showImageModal, setShowImageModal] = useState({
    isOpen: false,
    label: 'Servicio AV',
    message: '(PNG, JPG, JEPG, máx. 3MB)',
    selected: '',
  });
  const [showFollowingModal, setShowFollowingModal] = useState({
    isOpen: false,
    selected: '',
    uploaded: '',
  });
  const [showServiceImageModal, setShowServieImageModal] = useState({
    isOpen: false,
    selected: '',
    uploaded: '',
  });
  const [showFinishCaseModal, setShowFinishCaseModal] = useState({
    isOpen: false,
    selected: '',
    uploaded: '',
  });
  const [showSolvedByAVModal, setShowSolvedByAVModal] = useState({
    isOpen: false,
    selected: '',
    uploaded: '',
  });
  const [showFinishTowCaseModal, setFinishTowCaseModal] = useState({
    isOpen: false,
    selected: '',
    uploaded: '',
  });
  const [showFailureAssignModal, setShowFailureAssignModal] = useState({
    isOpen: false,
    selected: '',
    uploaded: '',
  });
  const [showChangeOperatorModal, setShowChangeOperatorModal] = useState({
    isOpen: false,
    selected: '',
    uploaded: '',
  });
  const [changeProviderModal, setChangeProviderModal] = useState({
    isOpen: false,
    selected: '',
    uploaded: '',
  });
  const [paymentModal, setPaymentModal] = useState({
    isOpen: false,
    selected: '',
    uploaded: '',
  });
  const [cashbackModal, setCashbackModal] = useState({
    isOpen: false,
    selected: '',
    uploaded: '',
  });
  const [selectServicePayment, setSelectServicePayment] = useState({
    isOpen: false,
    services: '',
  });
  const [selectCashbackPayment, setSelectCashbackPayment] = useState({
    isOpen: false,
    cashbacks: '',
  });

  const [changeTowModal, setChangeTowModal] = useState({
    isOpen: false,
    selected: '',
    uploaded: '',
  });

  const [showSMSTowModal, setShowSMSTowModal] = useState({
    isOpen: false,
    provider: '',
    _case: '',
  });

  const [showSMSModal, setShowSMSModal] = useState({
    isOpen: false,
    provider: '',
    _case: '',
  });
  const [showCancelCase, setShowCancelCase] = useState({
    show: false,
    caseSelected: '',
    cancelOptions: [],
  });
  const [showRollbackStage, setShowRollbackStage] = useState({
    show: false,
    caseSelected: '',
    cancelOptions: [],
  });
  const [showFailedCase, setFailedCancelCase] = useState({
    show: false,
    caseSelected: '',
    cancelOptions: [],
  });
  const [showCancelService, setShowCancelService] = useState({
    show: false,
    caseSelected: '',
    cancelOptions: [],
  });
  const [showCancelSingleService, setShowCancelSingleService] = useState({
    show: false,
    selected: '',
    cancelOptions: [],
  });
  const [cases, setCases] = useState({
    INITIATED: [],
    AV_ASSIGNED: [],
    AV_ON_ROAD: [],
    AV_IN_ORIGIN: [],
    SEARCHING_TOW: [],
    TOW_ASSIGNED: [],
    TOW_IN_ORIGIN: [],
    TOW_ON_ROAD: [],
    CUSTOMER_ON_ROAD: [],
    SCHEDULED: [],
    FINISHED: [],
    FAILED: [],
    CANCELED: [],
  });
  const selectButton = (value) => {
    setCaseTypeFilter(value);
  };

  const handleCancelCase = (incidence) => {
    if (incidence) {
      membershipService
        .getCancelReason(incidence?.caseType)
        .then((response) => {
          if (response.status === 200) {
            setShowCancelCase({
              cancelOptions: response?.data?.newCancellationReasons,
              show: !showCancelCase.show,
              selected: incidence ? incidence : '',
            });
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(() => { });
    } else {
      setShowCancelCase({
        cancelOptions: [],
        show: !showCancelCase.show,
        selected: incidence ? incidence : '',
      });
    }
  };

  const handleRollbackCase = (incidence) => {
    setShowRollbackStage({
      show: !showRollbackStage.show,
      selected: incidence ? incidence : '',
    });
  };

  const handleFailedCase = (incidence) => {

    const servicesLength = incidence?.services?.length
    if (incidence?.services[servicesLength - 1]?.type === "Grua" && incidence?.services[servicesLength - 1]?.paymentStatus === PaymentStatus?.N_A && incidence?.services[servicesLength - 1]?.payments?.length === 0) {

      // Pasar un flag para hacer que sea requerido cambiar el estatus de pago
      handlePaymentModal(incidence?.services[servicesLength - 1], true, incidence);
    } else {
      setFailedCancelCase({
        show: !showFailedCase.show,
        selected: incidence ? incidence : '',
      });
    }
  };

  const handleCancelSingleService = (service) => {

    setShowCancelSingleService({
      cancelOptions: showCancelService?.cancelOptions ? showCancelService?.cancelOptions : [],
      show: !showCancelSingleService.show,
      service: service ? service?._id : '',
    });

    setShowCancelService({
      cancelOptions: [],
      show: false,
      services: '',
    });
  };


  const handleCancelService = (incidence) => {
    if (incidence) {
      membershipService
        .getCancelReason(incidence?.caseType)
        .then((response) => {
          if (response.status === 200) {
            setShowCancelService({
              cancelOptions: response?.data?.newCancellationReasons,
              show: !showCancelCase.show,
              services: incidence ? incidence?.services : '',
            });
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(() => { });
    } else {
      setShowCancelService({
        cancelOptions: [],
        show: !showCancelService.show,
        services: incidence ? incidence?.services : '',
      });
    }
  };

  const getFaults = () => {

    sessionStorage.removeItem('status');
    sessionStorage.removeItem('sinceDate');
    sessionStorage.removeItem('untilDate');

    faultsService
      .getFaults()
      .then((response) => {
        if (response.status === 200) {
          setFaultOptions(response?.data?.newFaults);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };

  const showCase = (incidence) => {
    history.push(`/incidence/detail/${incidence?._id}`);
  };
  const editCase = (incidence) => {
    history.push(`/incidence/edit/${incidence?._id}`);
  };

  const handleLoadImageModal = (incidence) => {
    setShowImageModal((prevState) => {
      return {
        ...prevState,
        isOpen: !showImageModal?.isOpen,
        selected: incidence ? incidence?.lastService?._id : '',
      };
    });
  };

  const handleFinishCaseModal = (incidence, thirdParty) => {
    setShowFinishCaseModal((prevState) => {
      return {
        ...prevState,
        isOpen: !showFinishCaseModal?.isOpen,
        selected: incidence ? incidence : '',
        thirdParty: thirdParty ? thirdParty : '',
      };
    });
  };

  const handleSolvedByAVModal = (incidence) => {
    setShowSolvedByAVModal((prevState) => {
      return {
        ...prevState,
        isOpen: !showSolvedByAVModal?.isOpen,
        selected: incidence ? incidence : '',
      };
    });
  };

  const handleFinishTowCaseModal = (incidence) => {

    if (incidence) {
      let [services] = incidence?.services?.filter(s => s?.type === 'Grua' && s?.status !== "Cancelado")
      if (!services?.provider?.isInternal && (!services?.towImages?.towAtOrigin || !services?.towImages?.vehicleOnRoad || !services?.towImages?.vehicleOnTow)) {
        notify('Falta subir imagenes de la grua', 'error');
        return null
      }
    }

    if (incidence && incidence.services[0]?.type === "Asistencia vial") {
      let dataToPost = {
        solvedByAV: false,
        stage: incidence?.lastStage?._id,
      }

      caseService
        .finishCase(dataToPost, incidence?._id)
        .then((response) => {
          if (response.status === 200) {
            notify(`Caso Finalizado con éxito`, 'success');
            getCasesByOperator();
          } else {
            notify(`${response.message}`, 'error');
          }
        })
        .catch((error) => {
        });

      return null
    }

    setFinishTowCaseModal((prevState) => {
      return {
        ...prevState,
        isOpen: !showFinishTowCaseModal?.isOpen,
        selected: incidence ? incidence : '',
      };
    });
  };

  const handleFailureAssignModal = (incidence) => {
    setShowFailureAssignModal((prevState) => {
      return {
        ...prevState,
        isOpen: !showFailureAssignModal?.isOpen,
        selected: incidence ? incidence : '',
      };
    });
  };

  const handleChangeOperatorModal = (incidence) => {
    setShowChangeOperatorModal((prevState) => {
      return {
        ...prevState,
        isOpen: !showChangeOperatorModal?.isOpen,
        selected: incidence ? incidence : '',
      };
    });
  };

  const handleSolveCase = (incidence) => {

    if (incidence) {
      setIsLoading(true);
      caseService
        .changeSolved(incidence?._id, !incidence?.solvedByAV)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Caso actualizado`, 'success');
            getCasesByOperator();
          } else {
            notify(`${response.message}`, 'error');
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };


  const handleSelectedProviderModal = (incidence) => {
    setSelectedProvider(incidence?._id);
  };


  const handleChangeProviderModal = (incidence) => {
    setSelectedProvider('');
    setChangeProviderModal((prevState) => {
      return {
        ...prevState,
        isOpen: !changeProviderModal?.isOpen,
        selected: incidence ? incidence : '',
      };
    });
  };

  const handleExpressCheck = (incidence) => {
    history?.push(`/incidence/express-check/${incidence?._id}`)
  };

  const handleSelectService = (incidence) => {
    if (incidence && incidence?.services?.length === 1) {
      handlePaymentModal(incidence?.services[0]);
    } else {
      setSelectServicePayment((prevState) => {
        return {
          ...prevState,
          isOpen: !selectServicePayment?.isOpen,
          services: incidence?.services ? incidence?.services : [],
        };
      });
    }
  };

  const handleUpdateCashback = (incidence) => {

    let cashbacks = []
    for (let i = 0; i < incidence?.services?.length; i++) {

      if (incidence?.services[i]?.paymentChanges?.length > 0) {

        for (let j = 0; j < incidence?.services[i]?.paymentChanges?.length; j++) {
          cashbacks?.push(incidence?.services[i]?.paymentChanges[j]);
        }
      }
    }

    setSelectCashbackPayment((prevState) => {
      return {
        ...prevState,
        isOpen: !selectCashbackPayment?.isOpen,
        cashbacks: cashbacks?.length > 0 ? cashbacks : [],
      };
    });
  };

  const handleCashbackModal = (payment, denyN_A, incidence) => {
    setCashbackModal((prevState) => {
      return {
        ...prevState,
        isOpen: !cashbackModal?.isOpen,
        selected: payment ? payment : '',
        denyN_A: denyN_A,
        incidence: incidence
      };
    });

    if (payment) {
      setValue3('method', payment?.method?._id);
      setValue3('amount', payment?.amount);
      setValue3('amountBs', payment?.amountBs);
      setValue3('date', new Date(payment?.date));
      setValue3('ref', payment?.ref);
    } else {
      reset3();
    }
  };


  const editCashback = handleSubmit3(async (data) => {

    let dataToPost = { ...data };

    const formData = new FormData();
    formData.append('method', dataToPost?.method);
    formData.append('amount', dataToPost?.amount);
    formData.append('amountBs', dataToPost?.amountBs);
    formData.append('date', dataToPost?.date);
    formData.append('ref', dataToPost?.ref);
    if (cashBackImage?.image0) {
      formData.append('paymentEvidence', cashBackImage?.image0);
    }

    setIsLoading(true);
    paymentService
      .editCashback(formData, cashbackModal?.selected?._id)
      .then((response) => {
        if ([200, 201].includes(response.status)) {
          notify(`Vuelto actualizado`, 'success');
          handleCashbackModal()
          handleUpdateCashback()
          getCasesByOperator()
          removeFile()
          setTimeout(() => {
            window?.location?.reload();
          }, 1500);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => {
      });
  });



  const handlePaymentModal = (service, denyN_A, incidence) => {
    setPaymentModal((prevState) => {
      return {
        ...prevState,
        isOpen: !paymentModal?.isOpen,
        selected: service ? service : '',
        denyN_A: denyN_A,
        incidence: incidence
      };
    });

    if (service) {
      setValue2('serviceName', capitalize(service?.product?.name));
      setValue2('paymentMethods', 0);
      setValue2('price', service?.price);
      setValue2('pvp', service?.pvp);
      setValue2('paymentStatus', service?.paymentStatus);
      setValue2('amountExcess', service?.amountExcess || 0);
      setValue2('amountHandling', service?.amountHandling || 0);
      setValue2('paymentDifference', 0);
      if (service?.pendingPayment?.datePaid) {
        setValue2('paid', true);
      }

      setValue2(
        'accumulatedAmount',
        calulateAcumulatedAmount(service?.payments)
      );
    } else {
      reset2();
    }
  };

  const calulateAcumulatedAmount = (payments) => {
    let monto = 0;

    for (let i = 0; i < payments.length; i++) {
      monto = monto + payments[i]?.totalAmount;
    }
    return monto;
  };

  const changePaymentStatus = () => {
    switch (watch2('paymentStatus')) {
      case PaymentStatus?.DE_CONTADO:
        let paymentDifference =
          parseFloat(watch2('pvp')) - watch2('accumulatedAmount');
        setValue2(
          'accumulatedAmount',
          calulateAcumulatedAmount(paymentModal?.selected?.payments)
        );
        setValue2('pvp', paymentModal?.selected?.pvp);
        setValue2('paymentDifference', paymentDifference);
        setValue2('amount0', paymentDifference);
        setValue2('amountBsS0', paymentDifference * dollarRate);
        break;
      case PaymentStatus?.CUENTA_POR_COBRAR:
        setValue2('pvp', paymentModal?.selected?.pvp);
        setValue2('paymentDifference', 0);
        break;
      case PaymentStatus?.N_A:
        setValue2('pvp', 0);
        setValue2('paymentDifference', 0);
        break;

      default:
        break;
    }
  };

  const restorePaymentMethods = () => {
    let paymentDifference =
      parseFloat(watch2('pvp')) + parseFloat(watch2('amountHandling')) + parseFloat(watch2('amountExcess')) - watch2('accumulatedAmount');

    if (paymentDifference >= 0) {
      setValue2('paymentDifference', paymentDifference);
      setValue2('amount0', paymentDifference);
      setValue2('amountBsS0', (paymentDifference * dollarRate)?.toFixed(2));
    }
  };
  const handleTowType = (e) => {
    const { value } = e?.target;
    setTowType(value)
    setSelectedTow('');
  };


  const handleTow = (e) => {
    const { value, } = e;
    setSelectedTow(value);
  };


  const handleChangeTowModal = (incidence) => {
    setSelectedTow('');
    setChangeTowModal((prevState) => {
      return {
        ...prevState,
        isOpen: !changeTowModal?.isOpen,
        selected: incidence ? incidence : '',
      };
    });
  };

  const handleFollowingCaseModal = (incidence) => {
    setShowFollowingModal((prevState) => {
      return {
        ...prevState,
        isOpen: !showFollowingModal?.isOpen,
        selected: incidence ? incidence : '',
      };
    });
  };

  const handleChangeStatusModal = (incidence, nextStatus) => {
    setShowChangeStatusModal((prevState) => {
      return {
        ...prevState,
        isOpen: !showChangeStatusModal?.isOpen,
        nextStatus: incidence ? nextStatus : '',
        selected: incidence ? incidence : '',
      };
    });
  };

  let loadPaymentForms = (params) => {
    let payments = [];
    // lOOP through every payment method

    if ((watch2('paymentMethods') && watch2('paymentDifference') > 0) || [PaymentStatus?.CUENTA_POR_COBRAR]?.includes(watch2("paymentStatus"))) {
      for (let i = 0; i < parseInt(watch2('paymentMethods')); i++) {
        payments.push({
          method: params[`method` + i.toString()],
          reference: params[`reference` + i.toString()],
          amount: params[`amount` + i.toString()],
          amountBsS: params[`amountBsS` + i.toString()],
        });
      }

      // "REmove unwanted params from JSON"
      let words = [
        'method',
        'reference',
        'amount',
        'amountBsS',
        'paymentMethod',
      ];
      for (const key in params) {
        for (var i = 0, ln = words.length; i < ln; i++) {
          if (key.indexOf(words[i]) !== -1) {
            delete params[key];
          }
        }
      }
      params.payments = payments;
    }

    return params;
  };

  const appendFile = (data) => {
    const formData = new FormData();
    formData.append('service', data?.service);
    formData.append('paymentStatus', data?.paymentStatus);

    if (watch2('paymentStatus') === PaymentStatus?.CUENTA_POR_COBRAR && data?.paymentDate) {
      formData.append('paymentDate', data?.paymentDate);
    }
    formData.append('price', data?.price);
    formData.append('pvp', data?.pvp);
    if (watch2("amountHandling")) {
      formData.append('amountHandling', watch2("amountHandling"));
    }
    if (watch2("authorizedBy")) {
      formData.append('authorizedBy', watch2("authorizedBy"));
    }
    if (watch2("amountExcess")) {
      formData.append('amountExcess', watch2("amountExcess"));
    }


    let totalPayment = 0;
    if (watch2("paymentMethods") == 0) {
      return formData;
    }

    // Load payment methods and amounts if payment status is not N_A  or CUENTA_POR_COBRAR
    if (
      ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
        watch2('paymentStatus')
      ) || ([PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
        watch2('paymentStatus')
      ) && data?.payments[0].amount)
    ) {
      for (var i = 0; i < data?.payments?.length; i++) {
        formData.append(`payments[${i}][amount]`, data?.payments[i].amount);
        formData.append(
          `payments[${i}][amountBsS]`,
          data?.payments[i].amountBsS
        );
        formData.append(`payments[${i}][method]`, data?.payments[i].method);
        formData.append(
          `payments[${i}][reference]`,
          data?.payments[i].reference
        );
        totalPayment = totalPayment + parseFloat(data?.payments[i].amount);
      }
      for (let i = 0; i < parseInt(watch2('paymentMethods')); i++) {
        if (fileUploaded[i]) {
          formData.append(`paymentEvidence`, fileUploaded[i]);
        }
      }
    }

    // Load as many files as payment methods we have
    if (
      fileUploaded.length !== parseFloat(watch2('paymentMethods')) &&
      parseFloat(watch2('paymentMethods')) > 0 &&
      ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR,]?.includes(
        watch2('paymentStatus')
      )
    ) {
      notify('Ingrese comprobantes de pago', 'info');
      return null;
    }



    let totalSum = parseFloat(watch2("pvp")) + parseFloat(watch2("amountHandling")) + parseFloat(watch2("amountExcess"))


    for (let j = 0; j < parseFloat(watch2("cashbackMethodsQuantity")); j++) {
      formData.append(`paymentChanges[${j}][paid]`, data[`paid`]);
      formData.append(`paymentChanges[${j}][method]`, data[`returnedMethod${j + 1}`]);
      formData.append(`paymentChanges[${j}][amount]`, data[`returnedAmount${j + 1}`]);
      formData.append(`paymentChanges[${j}][amountBsS]`, data[`returnedAmountBsS${j + 1}`]);
      if (data[`returnedReference${j + 1}`]) {
        formData.append(`paymentChanges[${j}][ref]`, data[`returnedReference${j + 1}`]);
      }
      formData.append(`paymentChanges[${j}][hasEvidence]`, data[`returnedReference${j + 1}`] ? true : false);
      if (cashBackImage[`image${j + 1}`]) {
        formData.append(`paymentChangeEvidence`, cashBackImage[`image${j + 1}`]);
      }
    }


    // Para realizar pagos de Cuentas por cobrar 
    if (watch2('paymentStatus') === PaymentStatus?.CUENTA_POR_COBRAR && watch2('paymentMethods') >= 1 && !watch2("paid")) {
      if (
        totalSum &&
        totalSum !== totalPayment
      ) {
        notify(
          sumErrorMessage,
          'info'
        );
        return null;
      }
      return formData;
    }


    // Para realizar ajustes en cuentas por cobrar 
    if (watch2('paymentStatus') === PaymentStatus?.CUENTA_POR_COBRAR && watch2('paymentMethods') >= 1 && watch2("paid")) {
      if (
        watch2('paymentDifference') &&
        watch2('paymentDifference') !== totalPayment
      ) {
        notify(
          sumErrorMessage,
          'info'
        );
        return null;
      }
      return formData;
    }

    // Para realizar pagos 
    if (
      watch2('paymentDifference') &&
      watch2('paymentDifference') !== totalPayment && watch2('paymentStatus') !== PaymentStatus?.CUENTA_POR_COBRAR
    ) {
      notify(
        sumErrorMessage,
        'info'
      );
      return false;
    }

    return formData;
  };

  const activateProvider = () => {

    let user = authService?.getUser()

    providersService
      .acrtivateProvider({ isActive: !user?.provider?.isActive }, user?.provider._id)
      .then((response) => {
        if (response.status === 200) {
          notify(`Usuario ${!user?.provider?.isActive ? 'activado' : "desactivado"}`, "success")
          user.provider.isActive = !user.provider.isActive
          setTimeout(() => {
            sessionStorage.setItem(`user`, JSON.stringify(user));
            window?.location?.reload();
          }, 2000);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
  }




  const createPayment = handleSubmit2(async (data) => {

    let isCash = watch2("method0") === filterOption(paymentMethods, metodosDePago?.CASH_USD)?._id

    if (!validateTotalPayment(data)) {
      return null
    }

    if (isCash) {
      calculateCashBack()

      if (parseFloat(watch2("cashbackMethodsQuantity")) > 0) {
        // Validate the total of the cashback
        // Validate that all images are up
        if (!validateCashBackTotal() || !validateCashBackImages()) {
          return null
        }
      }
    }

    let dataToPost = { ...data };

    dataToPost = loadPaymentForms(dataToPost);
    dataToPost.service = paymentModal?.selected?._id;

    if (watch2("paymentStatus") === PaymentStatus?.COURTESY && !watch2("authorizedBy")) {
      setError2('authorizedBy', { type: 'custom', message: 'Obligatorio' });
      return null
    }

    let fileToPost = appendFile(dataToPost);

    if (data?.paymentStatus === PaymentStatus?.N_A && paymentModal?.denyN_A) {
      notify("Debe modificar el estatus de pago", "info")
      return null
    }

    if (!isLoading && fileToPost) {
      setIsLoading(true);
      paymentService
        .createPayment(fileToPost)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Pago actualizado`, 'success');
            if (paymentModal?.incidence) {
              getCasesByOperator();
              setFailedCancelCase({
                show: true,
                selected: paymentModal?.incidence,
              });
              setPaymentModal((prevState) => {
                return {
                  ...prevState,
                  isOpen: false,
                  selected: '',
                  denyN_A: false,
                  incidence: ""
                };
              })
            } else {
              setTimeout(() => {
                window?.location.reload();
                setIsLoading(false);
              }, 2000);
            }
          } else {
            notify(`${response.message}`, 'error');
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  });

  const updateProvider = () => {
    let data = {
      provider: selectedProvider,
      caseId: changeProviderModal?.selected?._id,
    };

    if (!isLoading) {
      setIsLoading(true);
      servicesService
        .updateProvider(data, changeProviderModal?.selected?.lastService?._id)
        .then((response) => {
          if (response.status === 200) {
            notify(`Motorizado actualizado con exito`, 'success');
            handleChangeProviderModal();
            getCasesByOperator();
            setIsLoading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsLoading(false);
          }
        })
        .catch(() => { });
    }
  };

  const createTowService = (_case) => {
    if (_case?.status === enumStatus?.AV_IN_ORIGIN) {

      let dataToPost = {
        case: _case?._id,
        useExtendedTow: _case?.membership?.hasExtendedTow
      }

      if (!isLoading) {
        setIsLoading(true);
        servicesService
          .createTowService(dataToPost)
          .then((response) => {
            if ([200, 201].includes(response.status)) {
              setIsLoading(false);
              history.push(
                `/incidence/assign-provider/${_case?._id}/service/${response?.data?.serviceId}`
              );
            } else {
              notify(`${response.message}`, 'error');
              setIsLoading(false);
            }
          })
          .catch(() => { });
      }
    } else {
      history.push(
        `/incidence/assign-provider/${_case?._id}/service/${_case?.lastService?._id}`
      );
    }
  };

  const updateTow = () => {
    let data = {
      provider: selectedTow,
      caseId: changeTowModal?.selected?._id,
    };

    if (!isLoading) {
      setIsLoading(true);
      servicesService
        .updateProvider(data, changeTowModal?.selected?.lastService?._id)
        .then((response) => {
          if (response.status === 200) {
            notify(`Gruero actualizado con exito`, 'success');
            handleChangeTowModal();
            getCasesByOperator();
            setIsLoading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsLoading(false);
          }
        })
        .catch(() => { });
    }
  };

  const selectStatusFilter = (status) => {
    let today = new Date();
    let weekFromNow = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
    switch (status) {
      case enumStatus?.FINISHED:
        // let weekAgo = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        // setSinceDate(weekAgo);
        setSinceDate(today);
        setUntilDate(today);
        sessionStorage.setItem(`sinceDate`, today);
        sessionStorage.setItem(`untilDate`, today);
        break;
      case enumStatus?.SCHEDULED:
        setSinceDate(today);
        setUntilDate(weekFromNow);
        sessionStorage.setItem(`sinceDate`, today);
        sessionStorage.setItem(`untilDate`, weekFromNow);
        break;
      case enumStatus?.PENDING_PAYMENT:
        setSinceDate(today);
        setUntilDate(weekFromNow);
        sessionStorage.setItem(`sinceDate`, today);
        sessionStorage.setItem(`untilDate`, weekFromNow);
        break;
      default:
        setSinceDate('');
        setUntilDate('');
        sessionStorage.removeItem('sinceDate');
        sessionStorage.removeItem('untilDate');
        break;
    }

    sessionStorage.setItem(`status`, status);

    setCaseStatus(status);
  };

  const updateCaseStage = () => {
    if (!isLoading) {
      setIsLoading(true);
      caseService
        .updateCaseStage(
          { stage: showChangeStatusModal?.selected?.lastStage?._id },
          showChangeStatusModal?.selected?._id
        )
        .then((response) => {
          if (response.status === 200) {
            notify(
              `Tarjeta desplazada a ${response?.data?.description}`,
              'success'
            );
            handleChangeStatusModal();
            getCasesByOperator();
            setIsLoading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsLoading(false);
          }
        })
        .catch(() => { });
    }
  };

  const getAVs = () => {
    providersService
      .getAVs()
      .then((response) => {
        if (response.status === 200) {


          let data = [...response?.data?.providers]

          for (let i = 0; i < data?.length; i++) {

            data[i].label = capitalize(data[i]?.contactName) + ' (' + translateFullStatus(data[i]) + ") "
            data[i].value = data[i]?._id
          }

          // translateStatus

          setProvidersOptions(data);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };
  const getInternalTows = () => {
    providersService
      .getInternalTows()
      .then((response) => {
        if (response.status === 200) {
          let data = response?.data?.providers

          for (let i = 0; i < data?.length; i++) {
            data[i].label = capitalize(data[i]?.contactName) + ' (' + translateStatus(data[i]?.status) + ") "
            data[i].value = data[i]?._id

          }

          setTowOptions([{ label: "", value: "" }, ...data]);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };

  const validateMaxPaymentMethods = () => {
    if (parseFloat(watch2('paymentMethods')) > 2) {
      notify('Solo se permiten 2 formas de pago', 'info');
      setValue2('paymentMethods', 2);
    }
  }

  const getExternalTows = () => {

    let params = {
      type: getProvidersEnum?.CRANE,
      offset: 0,
      limit: 100000,
      isInternal: false,
      isActive: true
    };


    providersService
      .getProvidersByType(filterParams(params))
      .then((response) => {
        if (response.status === 200) {

          let data = []
          for (let i = 0; i < response?.data?.providers?.length; i++) {
            data.push({
              contactName: response?.data?.providers[i]?.contactName,
              status: "Available",
              _id: response?.data?.providers[i]?._id,
              isActive: response?.data?.providers[i]?.isActive === "Activo" ? true : false,
              label: capitalize(response?.data?.providers[i]?.contactName) + " (Disponible) ",
              value: response?.data?.providers[i]?._id
            });
          }

          setExternalTowOptions([{ label: "", value: "" }, ...data]);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };


  const getInternalTowServices = () => {
    let params = {
      type: 'all',
    };

    providersService
      .getInternalTowServices(params)
      .then((response) => {
        if (response.status === 200) {
          setShowCraneCounterModal((prevState) => {
            return {
              ...prevState,
              data: response?.data,
            };
          });
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };

  const openDocumentModal = (src, service) => {
    setShowCraneCounterModal((prevState) => {
      return {
        ...prevState,
        show: !showCraneCounterModal?.show,
        src: !showCraneCounterModal?.src ? src : '',
        service: service,
      };
    });
  };

  const zoomDocumentModal = (zoom) => {
    setShowCraneCounterModal((prevState) => {
      return {
        ...prevState,
        zoom: zoom,
      };
    });
  };

  const getUsers = () => {
    let params = {
      role: operationsRoles?.operationsOperator,
      limit: 1000000,
      offset: 0,
      module: 'Operaciones',
    };
    usersService
      .getUsers(params)
      .then((response) => {
        if (response.status === 200) {
          let data = [];
          for (let i = 0; i < response?.data?.users?.length; i++) {
            data.push({
              name:
                response?.data?.users[i]?.firstName +
                ' ' +
                response?.data?.users[i]?.lastName,
              _id: response?.data?.users[i]?._id,
            });
          }

          setOperatorsOptions(data);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };

  const openSMSTowModal = (_case) => {
    setShowSMSTowModal((prevState) => {
      return {
        ...prevState,
        isOpen: !showSMSTowModal?.isOpen,
        provider: _case?.lastService?.provider,
        _case: _case ? _case : '',
      };
    });
  };

  const openSMSModal = (_case) => {
    setShowSMSModal((prevState) => {
      return {
        ...prevState,
        isOpen: !showSMSModal?.isOpen,
        provider: _case?.lastService?.provider,
        _case: _case ? _case : '',
      };
    });
  };

  const handleSMSModal = (_case) => {
    if (
      [afiliatedServices.crane, particularServices.crane, undefined].includes(
        _case?.lastService?.product?.code
      )
    ) {
      openSMSTowModal(_case);
    } else {
      openSMSModal(_case);
    }
  };

  const getUploadedTowImages = (serviceId) => {
    servicesService
      .getUploadedImages(serviceId)
      .then((response) => {
        if (response.status === 200) {
          setShowServieImageModal((prevState) => {
            return {
              ...prevState,
              uploaded: response?.data?.towImages,
            };
          });
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };

  const getDollarRate = () => {
    rateService
      .getDollarRate()
      .then((response) => {
        if (response.status === 200) {
          setDollarRate(response?.data?.rate);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const getPaymentMethods = () => {
    productService
      .getPaymentMethods('operations')
      .then((response) => {
        if (response.status === 200) {
          setPaymentMethods(response?.data);
          setFilteredMethods(response?.data?.filter((d) => d?.name !== metodosDePago?.CASH_USD));
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };

  const handleServiceImageModal = (incidence) => {
    // servicesService
    if (incidence?.lastService?._id) {
      getUploadedTowImages(incidence?.lastService?._id);
    }

    setShowServieImageModal((prevState) => {
      return {
        ...prevState,
        isOpen: !showServiceImageModal?.isOpen,
        selected: incidence ? incidence?.lastService?._id : '',
      };
    });
  };


  // Create particular customer
  const createParticularCustomer = (incidence) => {
    let data = {
      firstName: incidence?.thirdPartyParticularCustomerName,
      lastName: incidence?.thirdPartyParticularCustomerLastName,
      phonePrefix: incidence?.thirdPartyServicePhonePrefix,
      phoneNumber: incidence?.thirdPartyServicePhoneNumber,
      vehicleBrand: incidence?.thirdPartyVehicleBrand,
      vehicleModel: incidence?.thirdPartyVehicleModel,
      vehicleYear: incidence?.thirdPartyVehicleYear?.toString(),
      vehicleColor: incidence?.thirdPartyVehicleColor,
      vehiclePlate: incidence?.thirdPartyVehiclePlate,
    }

    if (!isLoading) {
      setIsLoading(true);
      particularCustomerService
        .createParticularCustomer(data)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            createParticularCase(response?.data?.particularCustomer, incidence)
          } else {
            notify(`${response.message}`, 'error');
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  }

  const createParticularCase = (customer, incidence) => {

    let data = {
      particularCustomer: customer?._id,
      originLocationStatus: incidence?.originLocationStatus,
      originState: incidence?.originState?._id,
      originCity: incidence?.originCity?._id,
      originAddress: incidence?.originAddress,

      contactNumberPrefix: customer?.phonePrefix,
      contactNumber: customer?.phoneNumber,
      destinyLocationStatus: incidence?.destinyLocationStatus,
      destinyState: incidence?.destinyState?._id,
      destinyCity: incidence?.destinyCity?._id,
      destinyAddress: incidence?.destinyAddress,
      caseKm: incidence?.caseKm,
      estimatedTime: incidence?.estimatedTime,
      customerFailureReason: incidence?.fault?._id
    }


    caseService
      .createCase(filterParams(data))
      .then((response) => {
        if ([200, 201].includes(response.status)) {
          createThirdPartyTowService(response?.data?.case, incidence)
        } else {
          notify(`${response.message}`, 'error');
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });


  }

  const createThirdPartyTowService = (_case, incidence) => {
    stagesService
      .createInitialTowStage({ case: _case?._id })
      .then((response) => {
        if ([200, 201].includes(response.status)) {
          notify(`Servicio a terceros creado`, 'success');
          handleFinishCaseModal(incidence, true)
          setIsLoading(false);
        } else {
          notify(`${response.message}`, 'error');
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const getMembership = (incidence) => {

    setIsLoading(true);
    particularCustomerService
      .getCasesByOperator(incidence?.thirdPartyVehiclePlate)
      .then((response) => {
        if (response.status === 200) {
          let [customer] = response?.data?.particularCustomers
          // Find the particular customer or create one
          if (customer) {
            createParticularCase(customer, incidence)
          } else {
            createParticularCustomer(incidence)
          }
        } else {
          notify(`${response.message}`, 'error');
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };


  const handleThirdPartyTow = (incidence) => {
    // 1 Find particular customer
    // 2 Create particular customer
    // 3 Create particular case
    // 4 Assgin tow service
    // 5 Finish previous case
    getMembership(incidence)
  }


  const asignCrane = (incidence) => {
    // Third party service
    if (incidence?.thirdPartyService || incidence?.thirdPartyParticularCustomerName) {
      handleThirdPartyTow(incidence)
      return null
    }

    if (incidence?.services[0]?.type === lastServiceEnum?.ROAD_ATTENDANT) {
      handleFailureAssignModal(incidence)
      return null
    }


    if (
      incidence?.destinyState?.name &&
      incidence?.originCity?.name &&
      incidence?.destinyAddress
    ) {
      createTowService(incidence);
    } else {
      notify(
        'Por favor agrege una dirección de destino al caso para poder asignarle una grua',
        'info'
      );
      history.push(`/incidence/edit/${incidence?._id}`);
    }
  };
  let options = [
    {
      name: statusEnum?.VER_CASO,
      action: showCase,
      status: Object.values(enumStatus),
    },
    {
      name: statusEnum?.ACTUALIZAR_PAGO,
      action: handleSelectService,
      status: Object.values(enumStatus),
    },
    {
      name: statusEnum?.ACTUALIZAR_VUELTOS,
      action: handleUpdateCashback,
      status: Object.values(enumStatus),
    },
    {
      name: statusEnum?.CAMBIAR_OPERADOR,
      action: handleChangeOperatorModal,
      status: [
        enumStatus.INITIATED,
        enumStatus.AV_ASSIGNED,
        enumStatus.AV_ON_ROAD,
        enumStatus.AV_IN_ORIGIN,
        enumStatus.SEARCHING_TOW,
        enumStatus.TOW_ASSIGNED,
        enumStatus.TOW_ON_ROAD,
        enumStatus.TOW_IN_ORIGIN,
        enumStatus.CUSTOMER_ON_ROAD,
        enumStatus.SCHEDULED,
      ],
    },
    {
      name: statusEnum?.CHANGE_TO_SOLVED,
      action: handleSolveCase,
      status: [
        enumStatus.FINISHED,
      ],
    },
    {
      name: statusEnum?.CHANGE_TO_CHECKING,
      action: handleSolveCase,
      status: [
        enumStatus.FINISHED,
      ],
    },
    {
      name: statusEnum?.ASIGNAR_GRUA,
      action: asignCrane,
      status: [enumStatus.AV_IN_ORIGIN],
    },
    {
      name: statusEnum?.CAMBIAR_MOTORIZADO,
      action: handleChangeProviderModal,
      status: [
        enumStatus.AV_ASSIGNED,
        enumStatus.AV_ON_ROAD,
        enumStatus.AV_IN_ORIGIN,
        enumStatus.SCHEDULED,
      ],
    },
    {
      name: statusEnum?.REVISION_EXPRESS,
      action: handleExpressCheck,
      status: [
        enumStatus.AV_ASSIGNED,
        enumStatus.AV_ON_ROAD,
      ],
    },
    {
      name: statusEnum?.CAMBIAR_GRUERO,
      action: handleChangeTowModal,
      status: [
        enumStatus.TOW_ASSIGNED,
        enumStatus.TOW_ON_ROAD,
        enumStatus.TOW_IN_ORIGIN,
        enumStatus.CUSTOMER_ON_ROAD,
        enumStatus.SCHEDULED,
      ],
    },
    {
      name: statusEnum?.REGISTRAR_SEGUIMIENTO,
      action: handleFollowingCaseModal,
      status: [
        enumStatus.INITIATED,
        enumStatus.AV_ASSIGNED,
        enumStatus.AV_ON_ROAD,
        enumStatus.AV_IN_ORIGIN,
        enumStatus.SEARCHING_TOW,
        enumStatus.TOW_ASSIGNED,
        enumStatus.TOW_ON_ROAD,
        enumStatus.TOW_IN_ORIGIN,
        enumStatus.CUSTOMER_ON_ROAD,
        enumStatus.SCHEDULED,
      ],
    },
    {
      name: statusEnum?.CARGAR_IMAGEN,
      action: handleLoadImageModal,
      status: [enumStatus.AV_IN_ORIGIN],
    },
    {
      name: statusEnum?.CARGAR_IMAGEN_SERVICIO_GRUA,
      action: handleServiceImageModal,
      status: [
        enumStatus.TOW_ON_ROAD,
        enumStatus.TOW_IN_ORIGIN,
        enumStatus.CUSTOMER_ON_ROAD,
      ],
    },
    {
      name: statusEnum?.EDITAR,
      action: editCase,
      status: [
        enumStatus.AV_ASSIGNED,
        enumStatus.AV_ON_ROAD,
        enumStatus.AV_IN_ORIGIN,
        enumStatus.SEARCHING_TOW,
        enumStatus.TOW_ASSIGNED,
        enumStatus.TOW_ON_ROAD,
        enumStatus.TOW_IN_ORIGIN,
        enumStatus.CUSTOMER_ON_ROAD,
        enumStatus.SCHEDULED,
      ],
    },
    {
      name: statusEnum?.CANCELAR_SERVICIO,
      action: handleCancelService,
      status: [
        enumStatus.INITIATED,
        enumStatus.AV_ASSIGNED,
        enumStatus.AV_ON_ROAD,
        enumStatus.AV_IN_ORIGIN,
        enumStatus.SEARCHING_TOW,
        enumStatus.TOW_ASSIGNED,
        enumStatus.TOW_ON_ROAD,
        enumStatus.TOW_IN_ORIGIN,
        enumStatus.CUSTOMER_ON_ROAD,
        enumStatus.SCHEDULED,
      ],
    },
    {
      name: statusEnum?.DEVOLVER_CASO,
      action: handleRollbackCase,
      status: [
        // enumStatus.AV_ASSIGNED,
        enumStatus.FINISHED,
      ],
    },

    {
      name: statusEnum?.CANCELAR_CASO,
      action: handleCancelCase,
      status: [
        enumStatus.INITIATED,
        enumStatus.AV_ASSIGNED,
        enumStatus.AV_ON_ROAD,
        enumStatus.AV_IN_ORIGIN,
        enumStatus.SEARCHING_TOW,
        enumStatus.TOW_ASSIGNED,
        enumStatus.TOW_ON_ROAD,
        enumStatus.TOW_IN_ORIGIN,
        enumStatus.CUSTOMER_ON_ROAD,
        enumStatus.SCHEDULED,
      ],
    },
    {
      name: statusEnum?.FAILED,
      action: handleFailedCase,
      status: [
        enumStatus.TOW_ASSIGNED,
        enumStatus.TOW_ON_ROAD,
        enumStatus.TOW_IN_ORIGIN,
        enumStatus.CUSTOMER_ON_ROAD,
        enumStatus.SCHEDULED,
      ],
    },
  ];

  let actionButtons = [
    {
      name: 'Cancelar Caso',
      action: handleCancelCase,
      status: [enumStatus.INITIATED, enumStatus.SCHEDULED],
    },
    {
      name: 'Registrar Seguimiento',
      action: handleFollowingCaseModal,
      status: [
        enumStatus.AV_ASSIGNED,
        enumStatus.AV_ON_ROAD,
        enumStatus.SEARCHING_TOW,
        enumStatus.TOW_ASSIGNED,
        enumStatus.TOW_ON_ROAD,
        enumStatus.TOW_IN_ORIGIN,
        enumStatus.CUSTOMER_ON_ROAD,
      ],
    },
    {
      name: 'Finalizar Caso',
      action: handleFinishCaseModal,
      status: [enumStatus.AV_IN_ORIGIN],
    },
  ];
  let statusButtons = [
    { name: 'Editar', action: editCase, status: [enumStatus.INITIATED] },
    {
      name: 'Cambiar a AV en vía',
      action: handleChangeStatusModal,
      status: [enumStatus.AV_ASSIGNED],
    },
    {
      name: 'Cambiar a AV en origen',
      action: handleChangeStatusModal,
      status: [enumStatus.AV_ON_ROAD],
    },
    {
      name: 'Falla extra detect/resuelta',
      action: handleSolvedByAVModal,
      status: [enumStatus.AV_IN_ORIGIN],
    },
    {
      name: 'Asignar grúa',
      action: asignCrane,
      status: [enumStatus.SEARCHING_TOW],
    },
    {
      name: 'Cambiar a grúa en vía',
      action: handleChangeStatusModal,
      status: [enumStatus.TOW_ASSIGNED],
    },
    {
      name: 'Cambiar a grúa en origen',
      action: handleChangeStatusModal,
      status: [enumStatus.TOW_ON_ROAD],
    },
    {
      name: 'Cliente vía destino',
      action: handleChangeStatusModal,
      status: [enumStatus.TOW_IN_ORIGIN],
    },
    {
      name: 'Finalizar caso',
      action: handleFinishTowCaseModal,
      status: [enumStatus.CUSTOMER_ON_ROAD],
    },
    {
      name: 'Asignar grúa',
      action: asignCrane,
      status: [enumStatus.SCHEDULED],
    },
    {
      name: 'Iniciar Servicio',
      action: handleChangeStatusModal,
      status: [enumStatus.SCHEDULED],
    },
  ];

  const incidenceColumn = [
    {
      cases: cases?.INITIATED,
      title: incidenceColumnsEnum?.ORDEN_NO_CREADA,
      status: enumStatus.INITIATED,
    },
    {
      cases: cases?.AV_ASSIGNED,
      title: incidenceColumnsEnum?.AV_ASIGNADO,
      status: enumStatus.AV_ASSIGNED,
    },
    {
      cases: cases?.AV_ON_ROAD,
      title: incidenceColumnsEnum?.AV_EN_VIA,
      status: enumStatus.AV_ON_ROAD,
    },
    {
      cases: cases?.AV_IN_ORIGIN,
      title: incidenceColumnsEnum?.AV_EN_ORIGEN,
      status: enumStatus.AV_IN_ORIGIN,
    },
    {
      cases: cases?.SEARCHING_TOW,
      title: incidenceColumnsEnum?.EN_BÚSQUEDA_DE_GRÚA,
      status: enumStatus.SEARCHING_TOW,
    },
    {
      cases: cases?.TOW_ASSIGNED,
      title: incidenceColumnsEnum?.GRÚA_ASIGNADA,
      status: enumStatus.TOW_ASSIGNED,
    },
    {
      cases: cases?.TOW_ON_ROAD,
      title: incidenceColumnsEnum?.GRÚA_EN_VÍA,
      status: enumStatus.TOW_ON_ROAD,
    },
    {
      cases: cases?.TOW_IN_ORIGIN,
      title: incidenceColumnsEnum?.GRÚA_EN_ORIGEN,
      status: enumStatus.TOW_IN_ORIGIN,
    },
    {
      cases: cases?.CUSTOMER_ON_ROAD,
      title: incidenceColumnsEnum?.CLIENTE_VÍA_DESTINO,
      status: enumStatus.CUSTOMER_ON_ROAD,
    },
    {
      cases: cases?.SCHEDULED,
      title: incidenceColumnsEnum?.SERVICIOS_AGENDADOS,
      status: enumStatus.SCHEDULED,
    },
    {
      cases: cases?.FINISHED,
      title: incidenceColumnsEnum?.SERVICIOS_FINALIZADOS,
      status: enumStatus.FINISHED,
    },
    {
      cases: cases?.FAILED,
      title: incidenceColumnsEnum?.SERVICIOS_FALLIDOS,
      status: enumStatus.FAILED,
    },
  ];
  const getCasesByOperator = () => {
    let user = sessionStorage.getItem('user');
    let status = sessionStorage.getItem('status');
    let since = sessionStorage.getItem('sinceDate');
    let until = sessionStorage.getItem('untilDate');
    user = JSON.parse(user);
    since = since ? since : sinceDate;
    until = until ? until : untilDate;


    let params = {
      sinceDate: since ? moment(since).format('YYYY-MM-DD') : '',
      untilDate: until ? moment(until).format('YYYY-MM-DD') : '',
      status: status ? status : caseStatus,
      caseType: caseTypeFilter,
    };

    if (params?.status === enumStatus?.PENDING_PAYMENT) {
      delete params?.status;
      delete params?.caseTypeFilter;
      params.onlyPendingPayment = true
    }

    // if (operationsRoles?.operationsOperator === user?.roles[0]?.code) {
    // delete params?.sinceDate;
    // delete params?.untilDate;
    // }

    setIsLoading(true);

    caseService
      .getCasesByOperator(user?._id, params)
      .then((response) => {
        if (response.status === 200) {
          let filterData = {
            INITIATED: checkFilterAndWarn(
              response?.data?.cases,
              enumStatus.INITIATED
            ),
            AV_ASSIGNED: checkFilterAndWarn(
              response?.data?.cases,
              enumStatus.AV_ASSIGNED
            ),
            AV_ON_ROAD: checkFilterAndWarn(
              response?.data?.cases,
              enumStatus.AV_ON_ROAD
            ),
            AV_IN_ORIGIN: checkFilterAndWarn(
              response?.data?.cases,
              enumStatus.AV_IN_ORIGIN
            ),
            SEARCHING_TOW: checkFilterAndWarn(
              response?.data?.cases,
              enumStatus.SEARCHING_TOW
            ),
            TOW_ASSIGNED: checkFilterAndWarn(
              response?.data?.cases,
              enumStatus.TOW_ASSIGNED
            ),
            TOW_ON_ROAD: checkFilterAndWarn(
              response?.data?.cases,
              enumStatus.TOW_ON_ROAD
            ),
            TOW_IN_ORIGIN: checkFilterAndWarn(
              response?.data?.cases,
              enumStatus.TOW_IN_ORIGIN
            ),
            CUSTOMER_ON_ROAD: checkFilterAndWarn(
              response?.data?.cases,
              enumStatus.CUSTOMER_ON_ROAD
            ),
            SCHEDULED: checkFilterAndWarn(
              response?.data?.cases,
              enumStatus.SCHEDULED
            ),
            FINISHED: checkFilterAndWarn(
              response?.data?.cases,
              enumStatus.FINISHED
            ),
            FAILED: checkFilterAndWarn(
              response?.data?.cases,
              enumStatus.FAILED
            ),
            CANCELED: checkFilterAndWarn(
              response?.data?.cases,
              enumStatus.CANCELED
            ),
          };

          setCases(filterData);
          // Update Providers list and status
          getAVs();
          getInternalTows();
          getExternalTows()
          setIsLoading(false);
          getInternalTowServices();
        } else {
          notify(`${response.message}`, 'error');
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleSinceDateChange = (e) => {
    if (e > untilDate && untilDate) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setSinceDate(e);
      sessionStorage.setItem(`sinceDate`, e);
    }
  };
  const handleUntilDateChange = (e) => {
    if (e < sinceDate && sinceDate) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setUntilDate(e);
      sessionStorage.setItem(`untilDate`, e);
    }
  };

  const getAllDevices = (show) => {

    setDevicesLocation((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });

    gpsService
      .getAllDevices()
      .then((response) => {


        let data = []
        if (response.status === 200) {
          for (let i = 0; i < response?.data?.length; i++) {
            data?.push({
              ...response?.data[i], show: show ? show : false
            })
          }
          setDevicesLocation((prevState) => {
            return {
              ...prevState,
              isLoading: false,
              data: data,
              middlePoint: devicesLocation?.middlePoint ? devicesLocation?.middlePoint : calculateMiddlePoint(data)
            };
          });
        }
        else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  }

  const showInfoWindow = (index) => {
    let data = [...devicesLocation?.data]
    data[index].show = !data[index].show
    setDevicesLocation((prevState) => {
      return {
        ...prevState,
        data: data,
      };
    });
  }

  const showLegend = () => {
    let data = [...devicesLocation?.data]
    let isOpen = data?.filter(d => d?.show)
    for (let i = 0; i < data?.length; i++) {

      if (isOpen?.length > 0) {
        data[i].show = false
      } else {
        data[i].show = true
      }
    }
    setDevicesLocation((prevState) => {
      return {
        ...prevState,
        data: data,
      };
    });
  }



  const handleLocationModal = (value) => {
    // if (!value) {
    setShowLocationModal(!showLocationModal)

    setTimeout(() => {

      let data = [...devicesLocation?.data]
      for (let i = 0; i < data?.length; i++) {
        data[i].show = value
        // }
        setDevicesLocation((prevState) => {
          return {
            ...prevState,
            data: data,
          };
        });
      }
    }, value ? 800 : 0);
  }

  const handleCashbackMethodsQuantity = () => {
    for (let i = 1; i <= 3; i++) {
      setValue2(`returnedMethod${i}`, "")
      setValue2(`returnedAmount${i}`, "")
      setValue2(`returnedAmountBsS${i}`, "")
      setCashBackImage((prevState) => {
        return {
          ...prevState,
          [`image${i}`]: ""
        };
      });
    }

    if (watch2("cashbackMethodsQuantity") === "1") {
      setValue2(`returnedAmount1`, watch2("amountReturned"))
      setValue2(`returnedAmountBsS1`, (parseFloat(watch2("amountReturned")) * dollarRate)?.toFixed(2))
    }
  }

  const handleCashbackBs = (index) => {

    let cashbackMethodsQuantity = parseFloat(watch2("cashbackMethodsQuantity"))
    let amountReturned = parseFloat(watch2(`amountReturned`))

    setValue2(`returnedAmountBsS${index}`, (parseFloat(watch2(`returnedAmount${index}`)) * dollarRate)?.toFixed(2))

    // Automatically set the amount for the last method
    if (index === cashbackMethodsQuantity - 1) {
      let paid = 0
      for (let i = 1; i < cashbackMethodsQuantity; i++) {
        paid = paid + parseFloat(watch2(`returnedAmount${i}`))
      }

      if (amountReturned - paid <= 0) {
        notify('El la suma de los montos no puede superar el vuelto a entregar', 'error');
        for (let j = 1; j <= cashbackMethodsQuantity; j++) {
          setValue2(`returnedAmount${j}`, 0)
          setValue2(`returnedAmountBsS${j}`, 0)
        }
        return null
      }

      setValue2(`returnedAmount${cashbackMethodsQuantity}`, (amountReturned - paid)?.toFixed(2))
      setValue2(`returnedAmountBsS${cashbackMethodsQuantity}`, (parseFloat(watch2(`returnedAmount${cashbackMethodsQuantity}`)) * dollarRate)?.toFixed(2))
    }
  }

  const handleDrop = (event, index) => {
    let file = ""
    // File added on drop
    if (event?.dataTransfer?.files) {
      file = Array?.from(event?.dataTransfer?.files)[0];
    }

    let maxWeight = 5 //Peso en MB

    // File added on Click
    if (event?.target?.files?.length > 0) {
      file = event?.target?.files[0];
    }

    if (!['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(file.type)
    ) {
      notify('Agregar un formato valido de imagen', 'error');
      return null;
    }
    if (file.size * 0.000001 > maxWeight) {
      notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
    }
    setCashBackImage((prevState) => {
      return {
        ...prevState,
        [`image${index}`]: file
      };
    });
  };

  const removeFile = (index) => {

    setCashBackImage((prevState) => {
      return {
        ...prevState,
        [`image${index}`]: ""
      };
    });

  }

  const validateTotalPayment = (data) => {
    let totalPayment = 0;
    let totalSum = parseFloat(watch2("pvp")) + parseFloat(watch2("amountHandling")) + parseFloat(watch2("amountExcess"))


    // Load payment methods and amounts if payment status is not N_A  or CUENTA_POR_COBRAR
    if (
      ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
        watch2('paymentStatus')
      ) || ([PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
        watch2('paymentStatus')
      ) && watch2("amount0"))
    ) {
      for (var i = 0; i < parseFloat(watch2('paymentMethods')); i++) {
        totalPayment = totalPayment + parseFloat(watch2(`amount${i}`));
      }
    }

    watch2("paymentDifference")

    if (watch2('paymentStatus') === PaymentStatus?.CUENTA_POR_COBRAR && watch2('paymentMethods') >= 1 && !watch2("paid")) {
      if (
        totalSum &&
        totalSum !== totalPayment
      ) {
        notify(
          sumErrorMessage,
          'info'
        );
        return false;
      }
      return true;
    }


    // Para realizar ajustes en cuentas por cobrar 
    if (watch2('paymentStatus') === PaymentStatus?.CUENTA_POR_COBRAR && watch2('paymentMethods') >= 1 && watch2("paid")) {
      if (
        watch2('paymentDifference') &&
        watch2('paymentDifference') !== totalPayment
      ) {
        notify(
          sumErrorMessage,
          'info'
        );
        return false;
      }
      return true;
    }

    // Para realizar pagos 
    if (
      watch2('paymentDifference') &&
      watch2('paymentDifference') !== totalPayment && watch2('paymentStatus') !== PaymentStatus?.CUENTA_POR_COBRAR
    ) {
      notify(
        sumErrorMessage,
        'info'
      );
      return false;
    } else return true


  }

  const calculateCashBack = () => {
    if (parseFloat(watch2("paid")) > parseFloat(watch2("amount0"))) {
      setValue2("paidBsS", (parseFloat(watch2("paid")) * dollarRate)?.toFixed(2))

      if (!watch2("cashbackMethodsQuantity")) {
        setValue2("cashbackMethodsQuantity", 1)
        let amountReturned = parseFloat(watch2(`amount0`)) - parseFloat(watch2("paid"))
        if (parseFloat(watch2(`amount1IGTF`))) {
          amountReturned = amountReturned + parseFloat(watch2(`amount1IGTF`))
        }
        amountReturned = amountReturned?.toFixed(2)
        setValue2("amountReturned", Math.abs(amountReturned))
        setValue2("amountReturnedBsS", (parseFloat(watch2("amountReturned")) * dollarRate)?.toFixed(2))
        setValue2(`returnedAmount1`, Math.abs(amountReturned))
        setValue2(`returnedAmountBsS1`, (parseFloat(watch2("amountReturned")) * dollarRate)?.toFixed(2))
      }
    }
  }

  const validateCashBackTotal = () => {
    let cashbackMethodsQuantity = parseFloat(watch2("cashbackMethodsQuantity"))
    let amountReturned = parseFloat(watch2(`amountReturned`))
    let total = 0
    for (let i = 1; i <= cashbackMethodsQuantity; i++) {
      total = total + parseFloat(watch2(`returnedAmount${i}`))
    }

    if (total !== amountReturned) {
      notify('El la suma de los montos debe ser igual al monto a entregar', 'error');
      return false
    }
    if (!watch2("returnedMethod1")) {
      // setError2(`returnedMethod1`, { type: 'custom', message: 'Obligatorio' });
      return false
    }
    else {
      return true
    }
  }

  const validateCashBackImages = () => {
    let cashbackMethodsQuantity = parseFloat(watch2("cashbackMethodsQuantity"))
    let canPost = true


    setErrorsDropzone((prevState) => {
      return {
        ...prevState,
        error1: false,
        error2: false,
        error3: false,
      };
    });

    for (let i = 1; i <= cashbackMethodsQuantity; i++) {

      const requiredMetods = paymentMethods?.filter((method) => [metodosDePago?.CASH_BS, metodosDePago?.CASH_EUR, metodosDePago?.CASH_USD, metodosDePago?.PUNTO_DE_VENTA]?.includes(method?.name))
      const method = filterOptionById(requiredMetods, watch2("returnedMethod" + i))

      if (method && !watch2(`returnedReference${i}`)) {
        setError2(`returnedReference${i}`, { type: 'custom', message: 'Obligatorio' });
        canPost = false

      }

      if (!cashBackImage[`image${i}`] && method) {
        notify(`Adjunte todos los comprobantes de pago de ${method?.name}`, 'error');
        canPost = false
        setErrorsDropzone((prevState) => {
          return {
            ...prevState,
            [`error${i}`]: true,
          };
        });
      }
    }
    return canPost
  }




  useEffect(() => {
    getAllDevices()
    getFaults();
    getCasesByOperator();
    getUsers();
    getDollarRate();
    getPaymentMethods();
  }, []);


  useEffect(() => {


    if ([operationsRoles?.operationsAdmin]?.includes(authService.getUserType())) {
      intervalRef.current = setInterval(getCasesByOperator, 0.5 * 60 * 1000); // 5 minutes in milliseconds
      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(intervalRef.current);
    }
  }, []);

  useEffect(() => {
    getCasesByOperator();
  }, [caseStatus, caseTypeFilter, sinceDate, untilDate]);
  return (
    <>
      <AllDevicesMapModal modal={{ show: showLocationModal, devicesLocation: devicesLocation }} handleModal={handleLocationModal} refresh={getAllDevices} showInfoWindow={showInfoWindow} showLegend={showLegend} />
      {hideForOperators() &&
        <CraneCounter
          showCraneCounterModal={showCraneCounterModal}
          openDocumentModal={openDocumentModal}
          zoomDocumentModal={zoomDocumentModal}
        />
      }
      <Header />
      <SelectServicePaymentModal
        modal={selectServicePayment}
        handleClose={handleSelectService}
        handlePaymentModal={handlePaymentModal}
      />

      <SelectCashbackPaymentModal
        modal={selectCashbackPayment}
        handleClose={handleUpdateCashback}
        handleCashbackModal={handleCashbackModal}
      />
      <CreatePaymentModal
        modal={paymentModal}
        register={register2}
        errors={errors2}
        watch={watch2}
        control={control2}
        fileUploaded={fileUploaded}
        setFileUploaded={setFileUploaded}
        action={createPayment}
        cancel={handlePaymentModal}
        options={{
          paymentMethods: paymentMethods,
          PaymentStatusOptions: PaymentStatusOptions,
          authorizedByOptions: authorizedByOptions,
          filteredMethods: filteredMethods,
        }}
        onChange={{
          setBsRate: setBsRate,
          restorePaymentMethods: restorePaymentMethods,
          changePaymentStatus: changePaymentStatus,
          validateMaxPaymentMethods: validateMaxPaymentMethods,
          handleCashbackMethodsQuantity: handleCashbackMethodsQuantity,
          handleCashbackBs: handleCashbackBs
        }}
        handleDrop={handleDrop}
        cashBackImage={cashBackImage}
        removeFile={removeFile}
        errorsDropzone={errorsDropzone}
      />

      <EditCashbackModal
        modal={cashbackModal}
        register={register3}
        errors={errors3}
        watch={watch3}
        control={control3}
        fileUploaded={cashBackImage}
        setFileUploaded={setFileUploaded}
        action={editCashback}
        cancel={handleCashbackModal}
        options={{
          paymentMethods: paymentMethods,
        }}
        onChange={{
          setBsRate: setBsRate,
          restorePaymentMethods: restorePaymentMethods,
          changePaymentStatus: changePaymentStatus,
          validateMaxPaymentMethods: validateMaxPaymentMethods,
          handleCashbackMethodsQuantity: handleCashbackMethodsQuantity,
          handleCashbackBs: handleCashbackBs
        }}
        handleDrop={handleDrop}
        cashBackImage={cashBackImage}
        removeFile={removeFile}
        errorsDropzone={errorsDropzone}
      />

      <MessageAVModal
        showSMSModal={showSMSModal}
        handleClose={openSMSModal}
        caseInfo={showSMSModal?._case}
      // service={showSMSModal?._case?.lastService?.}
      />
      <MessageTowModal
        showSMSModal={showSMSTowModal}
        handleClose={openSMSTowModal}
        caseInfo={showSMSTowModal?._case}
      // service={openSMSTowModal?._case?.lastService?.}
      />
      <ChangeCaseStageModal
        showChangeStatusModal={showChangeStatusModal}
        handleClose={handleChangeStatusModal}
        updateCaseStage={updateCaseStage}
      />

      <CancelServiceModal
        modal={showCancelService}
        handleClose={() => handleCancelService()}
        refresh={getCasesByOperator}
        cancelService={handleCancelSingleService}
      />
      <CancelSingleServiceModal
        modal={showCancelSingleService}
        handleClose={() => handleCancelSingleService()}
        refresh={getCasesByOperator}
      />
      <CancelCaseModal
        showCancelCase={showCancelCase}
        handleClose={() => handleCancelCase()}
        refresh={getCasesByOperator}
      />
      <RollbackStageModal
        modal={showRollbackStage}
        handleClose={() => handleRollbackCase()}
        refresh={getCasesByOperator}
      />
      <FailedCaseModal
        showCancelCase={showFailedCase}
        handleClose={() => handleFailedCase()}
        refresh={getCasesByOperator}
      />
      <UploadImageModal
        imageModal={showImageModal}
        handleClose={handleLoadImageModal}
      />
      <ServiceImageModal
        imageModal={showServiceImageModal}
        handleClose={handleServiceImageModal}
      />
      <FinishCaseModal
        finishCaseModal={showFinishCaseModal}
        handleClose={handleFinishCaseModal}
        options={faultOptions}
        refresh={getCasesByOperator}
      />
      <SolvedByAVModal
        solvedByAVModal={showSolvedByAVModal}
        handleClose={handleSolvedByAVModal}
        options={faultOptions}
        refresh={getCasesByOperator}
      />
      <FinishTowCaseModal
        solvedByAVModal={showFinishTowCaseModal}
        handleClose={handleFinishTowCaseModal}
        options={faultOptions}
        refresh={getCasesByOperator}
      />
      <FailureAssignTowModal
        solvedByAVModal={showFailureAssignModal}
        handleClose={handleFailureAssignModal}
        options={faultOptions}
        refresh={getCasesByOperator}
        createTowService={createTowService}
      />
      <ChangeOperatorModal
        showChangeOperatorModal={showChangeOperatorModal}
        handleClose={handleChangeOperatorModal}
        options={operatorsOptions}
        refresh={getCasesByOperator}
      />
      <ChangeProviderModal
        changeProviderModal={changeProviderModal}
        handleClose={handleChangeProviderModal}
        options={providersOptions}
        refresh={getCasesByOperator}
        value={selectedProvider}
        onChange={handleSelectedProviderModal}
        action={updateProvider}
      />
      <ChangeTowModal
        changeProviderModal={changeTowModal}
        handleClose={handleChangeTowModal}
        options={{ userTypeOptions: userTypeOptions, towOptions: towOptions, externalTowOptions: externalTowOptions }}
        refresh={getCasesByOperator}
        value={selectedTow}
        handleTowType={handleTowType}
        towType={towType}
        onChange={{ handleTowType: handleTowType, handleTow: handleTow }}
        action={updateTow}
      />
      <NewIncidenceModal
        show={showIncidenceModal}
        handleClose={setShowIncidenceModal}
        particular={() => history.push('/incidence/create/particular')}
        afiliated={() => history.push('/incidence/create/affiliated')}
      />
      <FollowingModal
        showFollowingModal={showFollowingModal}
        handleClose={handleFollowingCaseModal}
        refresh={getCasesByOperator}
      />
      {!hideForOperators() &&
        <div>
          <h5 style={{ textAlign: "center" }}>
            Proveedor {authService?.getUser()?.provider?.isActive ? "Activo" : "Inactivo"}
          </h5>
          <div className="buttons-center-container">
            <ActivateButtons action={activateProvider} />
          </div>
        </div>
      }
      <div className="service-with-container container">
        <h1 style={{ marginBottom: '0px' }} className="title-component">
          Servicios
        </h1>
        <div className="service-filter-button-container">
          {hideForOperators() && ![operationsRoles.operationsOperator].includes(
            authService.getUserType()
          ) ? (
            <div className="filter-button-area">
              {filterButtons.map((f) => (
                <FilterButton
                  key={f.name}
                  button={f}
                  selected={caseTypeFilter}
                  selectButton={selectButton}
                />
              ))}
            </div>
          ) : <span></span>
          }
          <div className="row">
            <>
              <div className="col-3"></div>
              <FilterDatePicker
                bootstrap={'col-4'}
                label={'Desde'}
                type={'date'}
                name={'untilDate'}
                placeholder={''}
                onChange={handleSinceDateChange}
                value={sinceDate}
              />{' '}
              <FilterDatePicker
                bootstrap={'col-4'}
                label={'Hasta'}
                type={'date'}
                name={'untilDate'}
                placeholder={''}
                onChange={handleUntilDateChange}
                value={untilDate}
              />
            </>
            {/* )} */}
          </div>
        </div>
        <div className="gray-line"></div>
        <br />
        <div className="buttons-container">
          {hideForOperators() &&
            <ActionButton
              action={() => setShowIncidenceModal(true)}
              label={'+ Nueva Incidencia'}
            />
          }
          <div className="service-filter-buttons-container">
            <AltButton label="Ubicacion de Flota" action={() => handleLocationModal(true)} />
            {hideForOperators() &&
              <FilterCaseButton
                label="Cuentas por Cobrar"
                value={enumStatus.PENDING_PAYMENT}
                caseStatus={caseStatus}
                action={selectStatusFilter}
              />
            }
            {/* {hideForOperators() &&
              <FilterCaseButton
                label="Servicios agendados"
                value={enumStatus.SCHEDULED}
                caseStatus={caseStatus}
                action={selectStatusFilter}
              />
            } */}
            <FilterCaseButton
              label="Finalizados"
              value={enumStatus.FINISHED}
              caseStatus={caseStatus}
              action={selectStatusFilter}
            />
            {hideForOperators() &&
              <SearchInput placeholder={'Nombre o Apellido, Cédula o Placa'} />
            }
          </div>
        </div>

        {/* {isTableLoading ? (
          <ZoneLoadSpinner show={isTableLoading} />
        ) : ( */}
        <div className="service-incidence-overflow-container">
          <div className="service-incidence-break-container">
            {incidenceColumn?.map((i) => (
              <IncidenceColumn
                key={i?.title}
                cases={i?.cases}
                options={options}
                actionButtons={actionButtons}
                statusButtons={statusButtons}
                handleSMSModal={handleSMSModal}
                title={i?.title}
                status={i?.status}
                caseStatus={caseStatus}
                handleSelectService={handleSelectService}
                actions={{ asignCrane: asignCrane }}
              />
            ))}
          </div>
        </div>
        {/* )} */}
      </div>
    </>
  );
};
