import { useState, useEffect, useRef } from 'react';
import { ActionButton, AltButton } from '../../../common/Buttons/Buttons';
import { Header } from '../../../common/Header/Header.jsx';
import { SelectPerPage } from '../../../common/Forms/Input/Input';
import {
  ExportablesDBTable,
  ExportablesPaymentTable,
  ExportablesPendingPaymentTable,
  ExportableAdministrationTable,
} from '../../../common/Tables/Tables';
import {
  exportanblesTableheader,
  AVPaymentHeader,
  PendingPaymentHeader,
  AdminExportableHeader,
  internalTowPaymentHeader,
  externalTowPaymentHeader,
  imageHeader,
  stageTimeHeader,
} from '../../../../assets/helpers/tables';
import { notify } from '../../../../assets/helpers/toast';
import { caseService } from '../../../../services/caseService';
import { servicesService } from '../../../../services/servicesService';
import {
  exportablesTypes,
  statusHeadervalues,
  caseTypeOptions,
} from '../../../../assets/helpers/options';
import {
  FilterDatePicker,
  Select,
  SearchInput,
} from '../../../common/Forms/Input/Input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as schemas from '../../../../assets/schemas/schemas';
import {
  exportDataToExcel,
  capitalize,
  exists,
  rename,
  printTime,
  serviceName,
  filterParams,
} from '../../../../assets/helpers/funciones';
import { enumStatus } from '../../../../assets/helpers/options';
import moment from 'moment';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner';

import './Exportables.scss';

const itemsPerpage = [10, 25, 50, 100, 500, 1000, 2500, 5000];

export const FilterButton = props => {
  const { label, type, action } = props;

  if (label === 'BD' && !type) {
    return <ActionButton label={label} action={() => action(label)} />;
  }
  if (label === type) {
    return <ActionButton label={label} action={() => action(label)} />;
  } else {
    return <AltButton label={label} action={() => action(label)} />;
  }
};

export const assignHeader = type => {
  if (type === exportablesTypes?.PAYMENT_AV) {
    return AVPaymentHeader;
  }
  if (type === exportablesTypes?.PAYMETN_INTERNAL_TOW) {
    return internalTowPaymentHeader;
  }
  if (type === exportablesTypes?.PAYMENT_EXTERNAL_TOW) {
    return externalTowPaymentHeader;
  } else {
    return exportanblesTableheader;
  }
};

export const Exportables = () => {
  const { t } = useTranslation();
  const tableRef = useRef(null);

  const { watch } = useForm({
    resolver: yupResolver(schemas.filterExportableSchema(t)),
  });

  const [BDHeader, setBDHeader] = useState(exportanblesTableheader);
  const [sinceDate, setSinceDate] = useState(new Date());
  const [untilDate, setUntilDate] = useState(new Date());
  const [exportablesDataTable, setExportablesDataTable] = useState({
    limit: itemsPerpage[2],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    sinceDate: '',
    untilDate: '',
    isInternal: true,
    isLoading: true,
    type: '',
    caseType: '',
    search: '',
  });

  const selectType = type => {
    setExportablesDataTable(prevState => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        type: type === 'BD' ? '' : type,
        data: [],
        caseType: '',
        search: '',
      };
    });
  };

  const handleCaseType = e => {
    const { value } = e?.target;

    setExportablesDataTable(prevState => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        type: '',
        data: [],
        caseType: value,
      };
    });
  };

  const handleSinceDateChange = e => {
    if (e > untilDate) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setSinceDate(e);
    }
  };
  const handleUntilDateChange = e => {
    if (e < sinceDate) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setUntilDate(e);
    }
  };

  const selectitemsPerpage = event => {
    setExportablesDataTable(prevState => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const handlePageChange = (event, value) => {
    setExportablesDataTable(prevState => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * exportablesDataTable?.limit,
      };
    });
  };

  const getPendingPaymentExportables = byMail => {
    if (
      [exportablesDataTable?.type]?.includes(exportablesTypes?.ADMINISTRATION)
    ) {
      return null;
    }

    let params = {
      from: moment(sinceDate).format('YYYY-MM-DD'),
      to: moment(untilDate).format('YYYY-MM-DD'),
      limit: exportablesDataTable?.limit,
      page: exportablesDataTable?.page,
    };

    if (byMail) {
      params.byMail = true;
    }

    console.log('params', params);
    servicesService
      .getExportables(params)
      .then(response => {
        if (response.status === 200) {
          if (byMail) {
            notify('El exportable se ha enviado por correo', 'success');
            setExportablesDataTable(prevState => {
              return {
                ...prevState,
                isLoading: false,
                byMail: false,
              };
            });
          } else {
            setExportablesDataTable(prevState => {
              return {
                ...prevState,
                data: response?.data?.results,
                count: response?.data?.total,
                isLoading: false,
              };
            });
          }
        } else {
          notify(`${response.message}` || 'error', 'error');
          setExportablesDataTable(prevState => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch(error => {
        setExportablesDataTable(prevState => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  };

  const getExportablesAdmin = byMail => {
    if (
      ![exportablesDataTable?.type]?.includes(exportablesTypes?.ADMINISTRATION)
    ) {
      return null;
    }

    let params = {
      from: moment(sinceDate).format('YYYY-MM-DD'),
      to: moment(untilDate).format('YYYY-MM-DD'),
      limit: exportablesDataTable?.limit,
      page: exportablesDataTable?.page,
    };

    if (byMail) {
      params.byMail = true;
    }

    servicesService
      .getExportableAdmin(params)
      .then(response => {
        if (response.status === 200) {
          if (byMail) {
            notify('El exportable se ha enviado por correo', 'success');
            setExportablesDataTable(prevState => {
              return {
                ...prevState,
                isLoading: false,
                byMail: false,
              };
            });
          } else {
            setExportablesDataTable(prevState => {
              return {
                ...prevState,
                data: response?.data?.services,
                count: response?.data?.total,
                isLoading: false,
              };
            });
          }
        } else {
          notify(`${response.message}` || 'error', 'error');
          setExportablesDataTable(prevState => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch(error => {
        setExportablesDataTable(prevState => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  };

  const handleSearchChange = e => {
    const { value } = e?.target;
    setExportablesDataTable(prevState => {
      return {
        ...prevState,
        search: value,
        page: 1,
        offset: 0,
      };
    });
  };

  const getExportables = (type, byMail) => {
    if ([exportablesTypes?.PAYMENT_PENDING]?.includes(type)) {
      getPendingPaymentExportables();
      return null;
    }
    if ([exportablesTypes?.ADMINISTRATION]?.includes(type)) {
      return null;
    }

    setExportablesDataTable(prevState => {
      return {
        ...prevState,
        isLoading: true,
      };
    });

    let body = {
      sinceDate: moment(sinceDate).format('YYYY-MM-DD'),
      untilDate: moment(untilDate).format('YYYY-MM-DD'),
      limit: exportablesDataTable?.limit,
      page: exportablesDataTable?.page,
      type: type ? type : exportablesDataTable?.type,
      providers: '',
    };

    let params = {
      sinceDate: moment(sinceDate).format('YYYY-MM-DD'),
      untilDate: moment(untilDate).format('YYYY-MM-DD'),
      limit: exportablesDataTable?.limit,
      offset: exportablesDataTable?.offset,
      type: type ? type : exportablesDataTable?.type,
      caseType: exportablesDataTable?.caseType,
      search: exportablesDataTable?.search,
    };

    if (byMail) {
      params.byMail = true;
      body.byMail = true;
    }

    if (
      [
        exportablesTypes?.PAYMETN_INTERNAL_TOW,
        exportablesTypes?.PAYMENT_EXTERNAL_TOW,
        exportablesTypes?.PAYMENT_AV,
      ].includes(type)
    ) {
      if ([exportablesTypes?.PAYMENT_AV].includes(type)) {
        if (body.providers === '') {
          delete body.providers;
        } else {
          let provider = [];
          provider.push(body.providers);
          body.providers = provider;
        }
        if (body.paymentStatus === '') {
          delete body.paymentStatus;
        }
        delete body.type;

        caseService
          .getAVPayments(body)
          .then(response => {
            if (response.status === 200) {
              if (byMail) {
                notify('El exportable se ha enviado por correo', 'success');
                setExportablesDataTable(prevState => {
                  return {
                    ...prevState,
                    isLoading: false,
                    byMail: false,
                  };
                });
              } else {
                setExportablesDataTable(prevState => {
                  return {
                    ...prevState,
                    data: response?.data?.services,
                    count: response?.data?.count,
                    isLoading: false,
                  };
                });
              }
            } else {
              notify(`${response.message}` || 'error', 'error');
              setExportablesDataTable(prevState => {
                return {
                  ...prevState,
                  isLoading: false,
                };
              });
            }
          })
          .catch(() => {
            setExportablesDataTable(prevState => {
              return {
                ...prevState,
                isLoading: false,
              };
            });
          });
      }

      if ([exportablesTypes?.PAYMETN_INTERNAL_TOW].includes(type)) {
        if (body.paymentStatus === '') {
          delete body.paymentStatus;
        }
        if (body.providers === '') {
          delete body.providers;
        } else {
          let provider = [];
          provider.push(body.providers);
          body.providers = provider;
        }
        delete body.type;
        caseService
          .getInternalTowsPayments(body)
          .then(response => {
            if (response.status === 200) {
              if (byMail) {
                notify('El exportable se ha enviado por correo', 'success');
                setExportablesDataTable(prevState => {
                  return {
                    ...prevState,
                    isLoading: false,
                    byMail: false,
                  };
                });
              } else {
                setExportablesDataTable(prevState => {
                  return {
                    ...prevState,
                    data: response?.data?.services,
                    count: response?.data?.count,
                    isLoading: false,
                  };
                });
              }
            } else {
              notify(`${response.message}` || 'error', 'error');
              setExportablesDataTable(prevState => {
                return {
                  ...prevState,
                  isLoading: false,
                };
              });
            }
          })
          .catch(() => {
            setExportablesDataTable(prevState => {
              return {
                ...prevState,
                isLoading: false,
              };
            });
          });
      }

      if ([exportablesTypes?.PAYMENT_EXTERNAL_TOW].includes(type)) {
        if (body.providers === '') {
          body.providers = [];
        }
        delete body.type;
        caseService
          .getExternalTowsPayments(body)
          .then(response => {
            if (response.status === 200) {
              if (byMail) {
                notify('El exportable se ha enviado por correo', 'success');
                setExportablesDataTable(prevState => {
                  return {
                    ...prevState,
                    isLoading: false,
                    byMail: false,
                  };
                });
              } else {
                setExportablesDataTable(prevState => {
                  return {
                    ...prevState,
                    data: response?.data?.services,
                    count: response?.data?.count,
                    isLoading: false,
                  };
                });
              }
            } else {
              notify(`${response.message}` || 'error', 'error');
              setExportablesDataTable(prevState => {
                return {
                  ...prevState,
                  isLoading: false,
                };
              });
            }
          })
          .catch(() => {
            setExportablesDataTable(prevState => {
              return {
                ...prevState,
                isLoading: false,
              };
            });
          });
      }
    } else {
      caseService
        .getExportables(filterParams(params))
        .then(response => {
          if (response.status === 200) {
            if (byMail) {
              notify('El exportable se ha enviado por correo', 'success');
              setExportablesDataTable(prevState => {
                return {
                  ...prevState,
                  isLoading: false,
                  byMail: false,
                };
              });
            } else {
              setExportablesDataTable(prevState => {
                let data = [...exportablesDataTable?.data];
                if (
                  [
                    exportablesTypes?.PAYMETN_INTERNAL_TOW,
                    exportablesTypes?.PAYMENT_AV,
                    exportablesTypes?.PAYMENT_EXTERNAL_TOW,
                  ].includes(params?.type)
                ) {
                  data = response?.data?.services;
                }
                if (!params?.type) {
                  updateHeaderValues(response?.data?.cases);
                  let columns = getHeaderColumns(response?.data?.cases);
                  data = polutaleEmptyData(
                    response?.data?.cases,
                    columns?.services,
                    columns?.payments
                  );
                }

                return {
                  ...prevState,
                  data: data,
                  count: response?.data?.count,
                  isLoading: false,
                };
              });
            }
          } else {
            notify(`${response.message}` || 'error', 'error');
            setExportablesDataTable(prevState => {
              return {
                ...prevState,
                isLoading: false,
              };
            });
          }
        })
        .catch(error => {
          setExportablesDataTable(prevState => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        });
    }

    return true;
  };

  const exportTable = type => {
    if ([exportablesTypes?.PAYMENT_PENDING]?.includes(type)) {
      getPendingPaymentExportables(true);
    }
    if ([exportablesTypes?.ADMINISTRATION]?.includes(type)) {
      getExportablesAdmin(true);
    }
    getExportables(exportablesDataTable?.type, true);
    // let dataToPrint = [];

    // const sumAmount = (data, param) => {
    //   let total = 0;
    //   for (let i = 0; i < data?.length; i++) {
    //     total = total + data[i][param];
    //   }
    //   return total;
    // };

    // const validateFleet = isInternal => {
    //   if (isInternal) {
    //     return 'Propia';
    //   }
    //   if (isInternal === false) {
    //     return 'Externa';
    //   } else return '';
    // };

    // if (!exportablesDataTable?.type) {
    //   for (let i = 0; i < exportablesDataTable?.data?.length; i++) {
    //     let data = {};
    //     let data3 = {};

    //     const pendingStatus = service => {
    //       if (service?.paymentStatus === 'Cuenta por cobrar') {
    //         return service?.pendingPayment?.pending
    //           ? 'Pendiente'
    //           : 'Pago Realizado';
    //       } else {
    //         return null;
    //       }
    //     };

    //     for (let j = 0; j < 2; j++) {
    //       data[`Servicio Prestado ${j + 1}`] = serviceName(
    //         exportablesDataTable?.data[i]?.services[j]?.productCode
    //       );
    //       data[`Estatus de Servicio ${j + 1}`] = serviceName(
    //         exportablesDataTable?.data[i]?.services[j]?.status
    //       );
    //       if (j === 0) {
    //         data[`Motivo 1° servicio grúa`] = serviceName(
    //           exportablesDataTable?.data[i]?.services[j]?.towReason
    //         );
    //       }
    //       data[`Motivo grua externa ${j + 1}`] =
    //         exportablesDataTable?.data[i]?.services[j]?.externalTowReason;
    //       data[`Código de servicio ${j + 1}`] =
    //         exportablesDataTable?.data[i]?.services[j]?.code;

    //       data[`Flota ${j + 1}`] = validateFleet(
    //         exportablesDataTable?.data[i]?.services[j]?.provider?.isInternal
    //       );
    //       data[`Chofer ${j + 1}`] =
    //         exportablesDataTable?.data[i]?.services[j]?.provider?.contactName;
    //       data[`Turno Servicio ${j + 1}`] =
    //         exportablesDataTable?.data[i]?.services[j]?.shift;
    //       data[`Costo ${j + 1}`] = exportablesDataTable?.data[i]?.services[j]
    //         ?.price
    //         ? exportablesDataTable?.data[i]?.services[j]?.price
    //         : '';
    //       data[`PVP ${j + 1}`] = exportablesDataTable?.data[i]?.services[j]?.pvp
    //         ? exportablesDataTable?.data[i]?.services[j]?.pvp
    //         : '';
    //       data[`Excedente ${j + 1}`] = exportablesDataTable?.data[i]?.services[
    //         j
    //       ]?.amountExcess
    //         ? exportablesDataTable?.data[i]?.services[j]?.amountExcess
    //         : '';
    //       data[`Maniobra ${j + 1}`] = exportablesDataTable?.data[i]?.services[j]
    //         ?.amountHandling
    //         ? exportablesDataTable?.data[i]?.services[j]?.amountHandling
    //         : '';
    //       data[`Autorizado por ${j + 1}`] = exportablesDataTable?.data[i]
    //         ?.services[j]?.authorizedBy
    //         ? exportablesDataTable?.data[i]?.services[j]?.authorizedBy
    //         : '';
    //       data[`Estatus de Pago ${j + 1}`] = exportablesDataTable?.data[i]
    //         ?.services[j]?.paymentStatus
    //         ? exportablesDataTable?.data[i]?.services[j]?.paymentStatus
    //         : '';
    //       for (let k = 0; k < 2; k++) {
    //         data[`Estatus cuenta por cobrar ${j + 1}`] = pendingStatus(
    //           exportablesDataTable?.data[i]?.services[j]
    //         );
    //         data[`Cuenta por cobrar ${j + 1}`] =
    //           exportablesDataTable?.data[i]?.services[
    //             j
    //           ]?.pendingPayment?.amount;
    //         data[`Fecha de pago cuenta por Cobrar ${j + 1}`] =
    //           exportablesDataTable?.data[i]?.services[j]?.pendingPayment
    //             ?.datePaid
    //             ? moment(
    //                 exportablesDataTable?.data[i]?.services[j]?.pendingPayment
    //                   ?.datePaid
    //               ).format('DD/MM/YYYY')
    //             : '';
    //         data[`Servicio ${j + 1} Método de Pago ${k + 1}`] =
    //           exportablesDataTable?.data[i]?.services[j]?.payments[k]?.method
    //             ? capitalize(
    //                 exportablesDataTable?.data[i]?.services[j]?.payments[k]
    //                   ?.method
    //               )
    //             : '';
    //         data[`Servicio ${j + 1} Monto Pago ${k + 1}`] = exportablesDataTable
    //           ?.data[i]?.services[j]?.payments[k]?.totalAmount
    //           ? exportablesDataTable?.data[i]?.services[j]?.payments[k]
    //               ?.totalAmount
    //           : '';
    //         data[` Servicio ${j + 1} Referencia de Pago ${k + 1}`] = exists(
    //           exportablesDataTable?.data[i]?.services[j]?.payments[k]?.reference
    //         );

    //         if (k === 1) {
    //           let hasServices =
    //             exportablesDataTable?.data[i]?.services[j]?.paymentChanges
    //               ?.length > 0;

    //           data[`Monto pagado ${j + 1}`] = hasServices
    //             ? exportablesDataTable?.data[i]?.services[j]?.paymentChanges[0]
    //                 ?.paid
    //             : '';
    //           data[`Fecha Vuelto Servicio ${j + 1}`] = hasServices
    //             ? moment(
    //                 exportablesDataTable?.data[i]?.services[j]
    //                   ?.paymentChanges[0]?.date
    //               )?.format('DD/MM/YYYY')
    //             : '';

    //           for (let l = 0; l < 3; l++) {
    //             data[`Vuelto ${l + 1} Servicio ${j + 1}`] = hasServices
    //               ? exportablesDataTable?.data[i]?.services[j]?.paymentChanges[
    //                   l
    //                 ]?.amount
    //               : '';
    //             data[`Vuelto ${l + 1} Bs Servicio ${j + 1}`] = hasServices
    //               ? exportablesDataTable?.data[i]?.services[j]?.paymentChanges[
    //                   l
    //                 ]?.amountBs
    //               : '';
    //             data[`Método  Vuelto ${l + 1} Servicio ${j + 1}`] = hasServices
    //               ? capitalize(
    //                   exportablesDataTable?.data[i]?.services[j]
    //                     ?.paymentChanges[l]?.methodDesc
    //                 )
    //               : '';
    //             data[`Referencia  Vuelto ${l + 1} Servicio ${j + 1}`] =
    //               hasServices
    //                 ? exportablesDataTable?.data[i]?.services[j]
    //                     ?.paymentChanges[l]?.ref
    //                 : '';
    //           }
    //         }
    //       }
    //       for (
    //         let h = 0;
    //         h < exportablesDataTable?.data[i]?.newStages?.length;
    //         h++
    //       ) {
    //         data3[`Tiempo AV Asignado (min)`] = printTime(
    //           exportablesDataTable?.data[i],
    //           h,
    //           enumStatus?.AV_ASSIGNED
    //         );
    //         data3[`Tiempo AV en vía (min)`] = printTime(
    //           exportablesDataTable?.data[i],
    //           h,
    //           enumStatus?.AV_ON_ROAD
    //         );
    //         data3[`Tiempo AV en origen`] = printTime(
    //           exportablesDataTable?.data[i],
    //           h,
    //           enumStatus?.AV_IN_ORIGIN
    //         );
    //         data3[`Tiempo en búsqueda grúa (min)`] = printTime(
    //           exportablesDataTable?.data[i],
    //           h,
    //           enumStatus?.SEARCHING_TOW
    //         );
    //         data3[`Tiempo grúa asginada (min)`] = printTime(
    //           exportablesDataTable?.data[i],
    //           h,
    //           enumStatus?.TOW_ASSIGNED
    //         );
    //         data3[`Tiempo grúa en vía (min)`] = printTime(
    //           exportablesDataTable?.data[i],
    //           h,
    //           enumStatus?.TOW_ON_ROAD
    //         );
    //         data3[`Tiempo en grúa en origen (min)`] = printTime(
    //           exportablesDataTable?.data[i],
    //           h,
    //           enumStatus?.TOW_IN_ORIGIN
    //         );
    //         data3[`Tiempo cliente vía destino (min)`] = printTime(
    //           exportablesDataTable?.data[i],
    //           h,
    //           enumStatus?.CUSTOMER_ON_ROAD
    //         );
    //         data3[`Tiempo total gestión (min)`] = printTime(
    //           exportablesDataTable?.data[i],
    //           h,
    //           ''
    //         );
    //       }
    //     }

    //     dataToPrint?.push({
    //       'Operador Inicial':
    //         capitalize(exportablesDataTable?.data[i]?.createdBy[0]?.firstName) +
    //         ' ' +
    //         capitalize(exportablesDataTable?.data[i]?.createdBy[0]?.lastName),
    //       'Operador Final':
    //         capitalize(exportablesDataTable?.data[i]?.user?.firstName) +
    //         ' ' +
    //         capitalize(exportablesDataTable?.data[i]?.user?.lastName),
    //       'Nro. de caso': exists(exportablesDataTable?.data[i]?.caseId),
    //       'Fecha de creación': exportablesDataTable?.data[i]?.createdAt
    //         ? moment(exportablesDataTable?.data[i]?.createdAt).format(
    //             'DD/MM/YYYY'
    //           )
    //         : '',
    //       'Hora de creación': exportablesDataTable?.data[i]?.createdAt
    //         ? moment(exportablesDataTable?.data[i]?.createdAt).format('LTS')
    //         : '',
    //       Estatus: capitalize(exportablesDataTable?.data[i]?.status),
    //       Nombre:
    //         capitalize(
    //           exportablesDataTable?.data[i]?.particularCustomer?.firstName
    //         ) ||
    //         capitalize(
    //           exportablesDataTable?.data[i]?.membership?.customer?.firstName
    //         ),
    //       Apellido:
    //         capitalize(
    //           exportablesDataTable?.data[i]?.particularCustomer?.lastName
    //         ) ||
    //         capitalize(
    //           exportablesDataTable?.data[i]?.membership?.customer?.lastName
    //         ),
    //       Numero: exportablesDataTable?.data[i]?.phone,
    //       Placa:
    //         exists(
    //           exportablesDataTable?.data[i]?.particularCustomer?.vehiclePlate
    //         ) ||
    //         exists(exportablesDataTable?.data[i]?.membership?.vehicle?.plate),
    //       Marca:
    //         capitalize(
    //           exportablesDataTable?.data[i]?.particularCustomer?.vehicleBrand
    //             ?.brand
    //         ) ||
    //         capitalize(
    //           exportablesDataTable?.data[i]?.membership?.vehicle?.brand
    //         ),
    //       Modelo:
    //         capitalize(
    //           exportablesDataTable?.data[i]?.particularCustomer?.vehicleModel
    //             ?.model
    //         ) ||
    //         capitalize(
    //           exportablesDataTable?.data[i]?.membership?.vehicle?.model
    //         ),
    //       Año:
    //         exists(
    //           exportablesDataTable?.data[i]?.particularCustomer?.vehicleYear
    //         ) ||
    //         exists(exportablesDataTable?.data[i]?.membership?.vehicle?.year),
    //       Plan: capitalize(exportablesDataTable?.data[i]?.membership?.code),
    //       'Estatus de Afiliación': capitalize(
    //         exportablesDataTable?.data[i]?.membership?.status
    //       ),
    //       'Tipo de gestión': capitalize(
    //         exportablesDataTable?.data[i]?.caseType
    //       ),
    //       Avería: capitalize(exportablesDataTable?.data[i]?.fault),
    //       'Avería Real': capitalize(exportablesDataTable?.data[i]?.realFault),
    //       KM: exportablesDataTable?.data[i]?.caseKm
    //         ? `${exportablesDataTable?.data[i]?.caseKm} KM`
    //         : '',
    //       'Estado origen': capitalize(
    //         exportablesDataTable?.data[i]?.originState
    //       ),
    //       'Ciudad origen': capitalize(
    //         exportablesDataTable?.data[i]?.originCity
    //       ),
    //       'Estado destino': capitalize(
    //         exportablesDataTable?.data[i]?.destinyState
    //       ),
    //       'Ciudad destino': capitalize(
    //         exportablesDataTable?.data[i]?.destinyCity
    //       ),
    //       ...data,
    //       // ...data2,
    //       ...data3,
    //     });
    //   }
    // }

    // if (
    //   [
    //     exportablesTypes?.PAYMENT_AV,
    //     exportablesTypes?.PAYMETN_INTERNAL_TOW,
    //     exportablesTypes?.PAYMENT_EXTERNAL_TOW,
    //   ].includes(exportablesDataTable?.type)
    // ) {
    //   for (let i = 0; i < exportablesDataTable?.data?.length; i++) {
    //     dataToPrint?.push({
    //       'Empresa/Chofer': capitalize(
    //         exportablesDataTable?.data[i]?.providerName
    //       ),
    //       'Fecha de servicio': exportablesDataTable?.data[i]?.createdAt
    //         ? moment(exportablesDataTable?.data[i]?.createdAt).format(
    //             'DD/MM/YYYY'
    //           )
    //         : '',
    //       'Servicio Prestado': capitalize(
    //         capitalize(exportablesDataTable?.data[i]?.product)
    //       ),
    //       'Tipo de gestión': capitalize(
    //         exportablesDataTable?.data[i]?.caseType
    //       ),
    //       'Autorizado por': capitalize(
    //         exportablesDataTable?.data[i]?.authorizedBy
    //       ),
    //       'Código de servicio': capitalize(exportablesDataTable?.data[i]?.code),
    //       Placa: exists(exportablesDataTable?.data[i]?.plate),
    //       Marca: exists(exportablesDataTable?.data[i]?.vehicleBrand),
    //       'Estado origen': capitalize(
    //         exportablesDataTable?.data[i]?.originState
    //       ),
    //       'Ciudad origen': capitalize(
    //         exportablesDataTable?.data[i]?.originCity
    //       ),
    //       'Monto a pagar proveedor $':
    //         exportablesDataTable?.data[i]?.providerPay,
    //     });
    //   }
    // }

    // if (
    //   [exportablesTypes?.PAYMENT_PENDING].includes(exportablesDataTable?.type)
    // ) {
    //   for (let i = 0; i < exportablesDataTable?.data?.length; i++) {
    //     dataToPrint?.push({
    //       'Código de servicio': capitalize(exportablesDataTable?.data[i]?.code),
    //       Cliente:
    //         capitalize(
    //           exportablesDataTable?.data[i]?.case?.particularCustomer?.firstName
    //         ) +
    //         ' ' +
    //         capitalize(
    //           exportablesDataTable?.data[i]?.case?.particularCustomer?.lastName
    //         ) +
    //         ' ' +
    //         capitalize(
    //           exportablesDataTable?.data[i]?.case?.membership?.customer
    //             ?.firstName
    //         ) +
    //         ' ' +
    //         capitalize(
    //           exportablesDataTable?.data[i]?.case?.membership?.customer
    //             ?.lastName
    //         ),
    //       Placa:
    //         exists(
    //           exportablesDataTable?.data[i]?.case?.particularCustomer
    //             ?.vehiclePlate
    //         ) +
    //         exists(
    //           exportablesDataTable?.data[i]?.case?.membership?.vehicle?.plate
    //         ),
    //       'Tipo de gestión': capitalize(
    //         exportablesDataTable?.data[i]?.caseType
    //       ),
    //       'Monto pagado USD': sumAmount(
    //         exportablesDataTable?.data[i]?.payments,
    //         'totalAmount'
    //       ),
    //       'Monto pagado Bs': sumAmount(
    //         exportablesDataTable?.data[i]?.payments,
    //         'totalAmountInBsS'
    //       ),
    //       'Fecha de pago': exportablesDataTable?.data[i]?.pendingPayment
    //         ?.datePaid
    //         ? moment(
    //             exportablesDataTable?.data[i]?.pendingPayment?.datePaid
    //           ).format('DD/MM/YYYY')
    //         : '',
    //     });
    //   }
    // }

    // let adminDataToPrint = [];
    // let data4 = {};

    // const validatePending = data => {
    //   if (data?.paymentStatus !== 'Cuenta por cobrar') {
    //     return null;
    //   }

    //   if (data?.pendingPayment?.datePaid) {
    //     return 'Pago realizado';
    //   } else {
    //     return 'Pendiente';
    //   }
    // };

    // for (let i = 0; i < exportablesDataTable?.data?.length; i++) {
    //   for (let j = 0; j < 4; j++) {
    //     data4[`Método de Pago' ${j + 1}`] =
    //       exportablesDataTable?.data[i]?.payments?.length > 0
    //         ? capitalize(
    //             exportablesDataTable?.data[i]?.payments[j]?.method?.method
    //           )
    //         : '';
    //     data4[`Monto Pago ' ${j + 1} $`] =
    //       exportablesDataTable?.data[i]?.payments?.length > 0
    //         ? capitalize(
    //             exportablesDataTable?.data[i]?.payments[j]?.totalAmount
    //           )
    //         : '';
    //     data4[`Monto Pago' ${j + 1} BsS`] =
    //       exportablesDataTable?.data[i]?.payments?.length > 0
    //         ? capitalize(
    //             exportablesDataTable?.data[i]?.payments[j]?.totalAmountInBsS
    //           )
    //         : '';
    //     data4[`Referencia de Pago' ${j + 1}`] =
    //       exportablesDataTable?.data[i]?.payments?.length > 0
    //         ? capitalize(exportablesDataTable?.data[i]?.payments[j]?.reference)
    //         : '';
    //   }

    //   adminDataToPrint?.push({
    //     'Tipo de gestión': exportablesDataTable?.data[i]?.caseType,
    //     Avería: capitalize(
    //       exportablesDataTable?.data[i]?.customerFailureReason?.description
    //     ),
    //     'Avería Real': capitalize(
    //       exportablesDataTable?.data[i]?.providerFailureReason?.description
    //     ),
    //     'Servicio Prestado': capitalize(
    //       exportablesDataTable?.data[i]?.product?.product
    //     ),
    //     'Código de Servicio': capitalize(exportablesDataTable?.data[i]?.code),
    //     'Tipo de ingreso':
    //       exportablesDataTable?.data[i]?.pvp > 0
    //         ? 'Servicio (PVP) '
    //         : '' + exportablesDataTable?.data[i]?.amountExcess > 0
    //         ? 'Excedente '
    //         : '' + exportablesDataTable?.data[i]?.amountHandling > 0
    //         ? 'Maniobra '
    //         : '',
    //     'Monto ingresado $': sumAmount(
    //       exportablesDataTable?.data[i]?.payments,
    //       'totalAmount'
    //     ),
    //     'Monto ingresado BsS': sumAmount(
    //       exportablesDataTable?.data[i]?.payments,
    //       'totalAmountInBsS'
    //     ),
    //     'Estatus de Pago': exportablesDataTable?.data[i]?.paymentStatus,
    //     ...data4,
    //     'Cuenta por cobrar $':
    //       exportablesDataTable?.data[i]?.pendingPayment?.amount,
    //     'Estatus cuenta por cobrar': validatePending(
    //       exportablesDataTable?.data[i]
    //     ),
    //   });
    // }

    // if (
    //   ![exportablesTypes?.ADMINISTRATION].includes(exportablesDataTable?.type)
    // ) {
    //   let name = `${rename(exportablesDataTable?.type || 'DB')}_pag_${
    //     exportablesDataTable?.page
    //   }_desde_${moment(watch('sinceDate')).format('DD-MM-YYYY')}_hasta_${moment(
    //     watch('untilDate')
    //   ).format('DD-MM-YYYY')}`;

    //   exportDataToExcel(dataToPrint, `${name}.xlsx`);
    // } else {
    //   let name = `${rename(exportablesDataTable?.type || 'DB')}_pag_${
    //     exportablesDataTable?.page
    //   }_desde_${moment(watch('sinceDate')).format('DD-MM-YYYY')}_hasta_${moment(
    //     watch('untilDate')
    //   ).format('DD-MM-YYYY')}`;

    //   exportDataToExcel(adminDataToPrint, `${name}.xlsx`);
    // }
  };

  useEffect(() => {
    getExportables(exportablesDataTable?.type);
    getExportablesAdmin();
  }, [
    exportablesDataTable?.page,
    exportablesDataTable?.offset,
    exportablesDataTable?.limit,
    exportablesDataTable?.type,
    exportablesDataTable?.sinceDate,
    exportablesDataTable?.untilDate,
    exportablesDataTable?.caseType,
    sinceDate,
    untilDate,
  ]);

  const getHeaderColumns = data => {
    let services = 0;
    let payments = 0;

    for (let i = 0; i < data?.length; i++) {
      if (data[i]?.services?.length >= services) {
        services = data[i]?.services?.length;
      }

      for (let j = 0; j < data[i]?.services?.length; j++) {
        if (data[i]?.services[j]?.payments?.length > payments) {
          payments = data[i]?.services[j]?.payments?.length;
        }
      }
    }
    return {
      services: services,
      payments: payments,
    };
  };

  // Create dynamic
  const updateHeaderValues = data => {
    let columns = getHeaderColumns(data);

    let newHeader = [...exportanblesTableheader];
    let hide = false;

    for (let i = 0; i < columns?.services; i++) {
      newHeader.push({
        name: `Servicio Prestado ${i + 1}`,
        value: '',
        sortable: false,
        hide: hide,
      });
      newHeader.push({
        name: `Estatus Servicio ${i + 1}`,
        value: '',
        sortable: false,
        hide: hide,
      });
      if (i === 0) {
        newHeader.push({
          name: `Motivo 1° servicio grúa`,
          value: '',
          sortable: false,
          hide: hide,
        });
      }
      newHeader.push({
        name: `Motivo grua externa ${i + 1}`,
        value: '',
        sortable: false,
        hide: hide,
      });
      newHeader.push({
        name: `Código de servicio ${i + 1}`,
        value: '',
        sortable: false,
        hide: hide,
      });
      newHeader.push({
        name: `Flota ${i + 1}`,
        value: '',
        sortable: false,
        hide: hide,
      });
      newHeader.push({
        name: `Chofer ${i + 1}`,
        value: '',
        sortable: false,
        hide: hide,
      });
      newHeader.push({
        name: `Turno Servicio ${i + 1}`,
        value: '',
        sortable: false,
        hide: hide,
      });
      newHeader.push({
        name: `Costo ${i + 1}`,
        value: '',
        sortable: false,
        hide: hide,
      });
      newHeader.push({
        name: `PVP ${i + 1}`,
        value: '',
        sortable: false,
        hide: hide,
      });
      newHeader.push({
        name: `Excedente ${i + 1}`,
        value: '',
        sortable: false,
        hide: hide,
      });
      newHeader.push({
        name: `Maniobra ${i + 1}`,
        value: '',
        sortable: false,
        hide: hide,
      });
      newHeader.push({
        name: `Autorizado por ${i + 1}`,
        value: '',
        sortable: false,
        hide: hide,
      });

      newHeader.push({
        name: `Estatus de Pago ${i + 1}`,
        value: '',
        sortable: false,
        hide: hide,
      });
      newHeader.push({
        name: `Estatus cuenta por cobrar ${i + 1}`,
        value: '',
        sortable: false,
        hide: hide,
      });
      newHeader.push({
        name: `Cuenta por cobrar ${i + 1}`,
        value: '',
        sortable: false,
        hide: hide,
      });
      newHeader.push({
        name: `Fecha de pago cuenta por cobrar ${i + 1}`,
        value: '',
        sortable: false,
        hide: hide,
      });
      for (let j = 0; j < columns?.payments; j++) {
        newHeader.push({
          name: `Servicio ${i + 1} Método de Pago  ${j + 1}`,
          value: '',
          sortable: false,
          hide: hide,
        });
        newHeader.push({
          name: `Servicio ${i + 1} Monto Pago  ${j + 1}`,
          value: '',
          sortable: false,
          hide: hide,
        });
        newHeader.push({
          name: `Servicio ${i + 1} Referencia de Pago  ${j + 1}`,
          value: '',
          sortable: false,
          hide: hide,
        });
        newHeader.push({
          name: `Servicio ${i + 1} Comprobante de Pago  ${j + 1}`,
          value: '',
          sortable: false,
          hide: hide,
          hidePrint: true,
        });
        if (j === columns?.payments - 1) {
          newHeader.push({
            name: `Monto Pagado ${i + 1}`,
            value: '',
            sortable: false,
            hide: hide,
          });
          newHeader.push({
            name: `Fecha Vuelto Servicio ${i + 1}`,
            value: '',
            sortable: false,
            hide: hide,
          });
          for (let k = 0; k < 3; k++) {
            newHeader.push({
              name: `Vuelto ${k + 1} $ Servicio ${i + 1}`,
              value: '',
              sortable: false,
              hide: hide,
            });
            newHeader.push({
              name: `Vuelto ${k + 1} Bs Servicio ${i + 1}`,
              value: '',
              sortable: false,
              hide: hide,
            });
            newHeader.push({
              name: `Método Vuelto ${k + 1} Servicio ${i + 1}`,
              value: '',
              sortable: false,
              hide: hide,
            });
            newHeader.push({
              name: `Referencia Vuelto ${k + 1} Servicio ${i + 1}`,
              value: '',
              sortable: false,
              hide: hide,
            });
            newHeader.push({
              name: `Comprobante de Vuelto ${k + 1} Servicio ${i + 1}`,
              value: '',
              sortable: false,
              hide: hide,
            });
          }
        }
      }
    }

    newHeader = [...newHeader, ...stageTimeHeader, ...imageHeader];
    setBDHeader(newHeader);
  };

  const polutaleEmptyData = (data, services, payments) => {
    let value = '';
    let newData = [...data];
    let arreglo = [];
    for (let y = 0; y < statusHeadervalues.length; y++) {
      arreglo.push({
        name: statusHeadervalues[y],
        totalTime: '',
      });
    }

    for (let i = 0; i < newData?.length; i++) {
      if (newData[i]?.services?.length < services) {
        for (let z = newData[i]?.services?.length; z < services; z++) {
          newData[i]?.services?.push({
            productCode: value,
            code: value,
            payments: [],
            price: value,
            pvp: value,
            paymentStatus: value,
            provider: { contactName: value },
            shift: '',
            // provider: { isInternal: value },
          });
        }
      }

      for (let j = 0; j < newData[i]?.services?.length; j++) {
        // If not enough payments create empy payments
        if (newData[i]?.services[j].payments?.length < payments) {
          for (
            let k = newData[i]?.services[j].payments?.length;
            k < payments;
            k++
          ) {
            newData[i]?.services[j].payments?.push({
              method: value,
              totalAmount: value,
              reference: value,
              referenceImg: '',
            });
          }
        }

        for (let z = 0; z < newData[i]?.services[j]?.payments?.length; z++) {
          if (
            !newData[i]?.services[j]?.payments[z]?.hasOwnProperty(
              'referenceImg'
            )
          ) {
            newData[i].services[j].payments[z].referenceImg = '';
          }
        }
      }

      newData[i].newStages = arreglo;
    }
    return newData;
  };

  return (
    <>
      <LoadSpinner show={exportablesDataTable.isLoading} />
      <Header />
      <div className="service-with-container container">
        <div className="buttons-container">
          <h1 className="title-component">
            TU/GRUERO: {exportablesDataTable?.count}
          </h1>
          <ActionButton
            label={'Exportar'}
            action={() => exportTable(exportablesDataTable.type)}
          />
        </div>
        <div className="breadcrumb-container">
          <div className="exportables-small-buttons-container exportables-buttons-container">
            <FilterButton
              label={'BD'}
              type={exportablesDataTable?.type}
              action={selectType}
            />
            <FilterButton
              label={exportablesTypes?.PAYMENT_AV}
              type={exportablesDataTable?.type}
              action={selectType}
            />
            <FilterButton
              label={exportablesTypes?.PAYMETN_INTERNAL_TOW}
              type={exportablesDataTable?.type}
              action={selectType}
            />
            <FilterButton
              label={exportablesTypes?.PAYMENT_EXTERNAL_TOW}
              type={exportablesDataTable?.type}
              action={selectType}
            />
            <FilterButton
              label={exportablesTypes?.PAYMENT_PENDING}
              type={exportablesDataTable?.type}
              action={selectType}
            />
            <FilterButton
              label={exportablesTypes?.ADMINISTRATION}
              type={exportablesDataTable?.type}
              action={selectType}
            />
          </div>
          <div
            style={{ width: 'auto' }}
            className="exportables-buttons-container"
          >
            {!exportablesDataTable?.type && (
              <div style={{ marginTop: '12px' }} className="col-3">
                <Select
                  options={caseTypeOptions}
                  label={'Tipo de Gestion'}
                  value={exportablesDataTable?.caseType}
                  onChange={handleCaseType}
                />
              </div>
            )}
            <FilterDatePicker
              bootstrap={'col-4'}
              label={'Desde'}
              type={'date'}
              name={'untilDate'}
              placeholder={''}
              onChange={handleSinceDateChange}
              value={sinceDate}
            />{' '}
            <FilterDatePicker
              bootstrap={'col-4'}
              label={'Hasta'}
              type={'date'}
              name={'untilDate'}
              placeholder={''}
              onChange={handleUntilDateChange}
              value={untilDate}
            />
            <div style={{ marginTop: '12px' }}>
              <SelectPerPage
                label={'Registros'}
                options={itemsPerpage}
                value={exportablesDataTable?.limit}
                onChange={selectitemsPerpage}
              />
            </div>
          </div>
          <span></span>
        </div>
        {!exportablesDataTable?.type && (
          <div style={{ marginRight: '12px' }}>
            <SearchInput
              placeholder={'Cédula, Placa o Código de Servicio'}
              width="260px"
              value={exportablesDataTable?.search}
              onChange={handleSearchChange}
              searchAction={getExportables}
            />
          </div>
        )}
        {!exportablesDataTable?.type && !exportablesDataTable.isLoading && (
          <ExportablesDBTable
            header={BDHeader}
            handlePageChange={handlePageChange}
            data={exportablesDataTable}
            type={exportablesDataTable?.type}
            tableRef={tableRef}
          />
        )}

        {[
          exportablesTypes?.PAYMENT_AV,
          exportablesTypes?.PAYMETN_INTERNAL_TOW,
          exportablesTypes?.PAYMENT_EXTERNAL_TOW,
        ].includes(exportablesDataTable?.type) &&
          !exportablesDataTable.isLoading && (
            <ExportablesPaymentTable
              header={AVPaymentHeader}
              handlePageChange={handlePageChange}
              data={exportablesDataTable}
              type={exportablesDataTable?.type}
              tableRef={tableRef}
            />
          )}
        {[exportablesTypes?.PAYMENT_PENDING].includes(
          exportablesDataTable?.type
        ) &&
          !exportablesDataTable.isLoading && (
            <ExportablesPendingPaymentTable
              header={PendingPaymentHeader}
              handlePageChange={handlePageChange}
              data={exportablesDataTable}
              type={exportablesDataTable?.type}
              tableRef={tableRef}
            />
          )}
        {[exportablesTypes?.ADMINISTRATION].includes(
          exportablesDataTable?.type
        ) &&
          !exportablesDataTable.isLoading && (
            <ExportableAdministrationTable
              header={AdminExportableHeader}
              handlePageChange={handlePageChange}
              data={exportablesDataTable}
              type={exportablesDataTable?.type}
              tableRef={tableRef}
            />
          )}
      </div>
    </>
  );
};
