import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header } from '../../common/Header/Header';
import {
  ScheduleServicesModal,
  MapModal,
  CancelServiceModal,
  CreateAVModal,
  MessageAVModal,
  MessageTowModal,
} from '../../common/Modals/Modals';
import {
  ClientDetail,
  AvailableServices,
  SelectedServices,
  HandleCaseButtons,
} from './IncidenceSections';
import { caseService } from '../../../services/caseService';
import { vehicleService } from '../../../services/vehicleService';
import { stagesService } from '../../../services/stagesService';
import { notify } from '../../../assets/helpers/toast';
import {
  afiliatedServices,
  particularServices,
  enumStatus,
  authorizedByOptions,
  paymentMethods as metodosDePago,
  addressEnum,
  caseType
} from '../../../assets/helpers/options';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as schemas from '../../../assets/schemas/schemas';
import { IncidenceDetailForm } from '../Incidence/IncidenceSections';
import { membershipService } from '../../../services/membershipService';
import { rateService } from '../../../services/rateService';
import { providersService } from '../../../services/providersService';
import { garageService } from '../../../services/garageService';
import { faultsService } from '../../../services/faultsService';
import { membershipProductsService } from '../../../services/membershipProductsService';
import { productService } from '../../../services/productService';
import { servicesService } from '../../../services/servicesService';
import { statesService } from '../../../services/statesService';
import { CraneCounter } from '../../miscelaneos/CraneCounter/CraneCounter.jsx';
import {
  capitalize,
  filterParams,
  exists,
  getOption,
  filterOption,
  filterOptionById,
  parseNull,
  isValidMongoId
} from '../../../assets/helpers/funciones';
import { useHistory } from 'react-router-dom';
import {
  PaymentStatusOptions,
  PaymentStatus,
  referenceLocation
} from '../../../assets/helpers/options';
import moment from 'moment';

import './IncidenceDetail.scss';

export const EditCase = () => {
  const [statesOptions, setStatesOptions] = useState([]);
  const [mapModalOpen, setMapModalOpen] = useState(false);
  const [originCitiesOptions, setOriginCitiesOptions] = useState([]);
  const [destinycitiesOptions, setDestinyCitiesOptions] = useState([]);
  const [faultsOptions, setFaultsOptions] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [filteredMethods, setFilteredMethods] = useState([]);
  const [errorsDropzone, setErrorsDropzone] = useState({});
  const [cashBackImage, setCashBackImage] = useState([]);
  const [showSchedule, setShowSchedule] = useState(false);
  const [fileUploaded, setFileUploaded] = useState([]);
  const [openPayModal, setOpenPayModal] = useState(false);
  const [providersOptions, setProvidersOptions] = useState([]);
  const [selectedBike, setSelectedBike] = useState('');
  const [caseInfo, setCaseInfo] = useState();
  const [dollarRate, setDollarRate] = useState('');
  const [isLoading, setIsloading] = useState(false);
  const [brandOptions, setBrandOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [bsDisabled, setbsDisabled] = useState(true);
  const [craneCounter, setCraneCounter] = useState([]);
  const [addressOption, setAddressOption] = useState([]);
  const [reason, setReason] = useState('');
  const [showCraneCounterModal, setShowCraneCounterModal] = useState({
    show: false,
    src: '',
    zoom: 1,
  });
  const [availableServices, setAvailableServices] = useState({
    selected: '',
    available: [],
  });
  const [showSMSTowModal, setShowSMSTowModal] = useState({
    isOpen: false,
    provider: '',
  });
  const [showSMSModal, setShowSMSModal] = useState({
    isOpen: false,
    provider: '',
  });
  const [showCancelService, setShowCancelService] = useState({
    show: false,
    caseSelected: '',
    cancelOptions: [],
  });

  const openSMSModal = () => {
    getDollarRate();
    let [provider] = providersOptions.filter((p) => p?._id === selectedBike);
    setShowSMSModal((prevState) => {
      return {
        ...prevState,
        isOpen: !showSMSModal?.isOpen,
        provider: provider,
      };
    });
  };

  const getDollarRate = () => {
    rateService
      .getDollarRate()
      .then((response) => {
        if (response.status === 200) {
          setDollarRate(response?.data?.rate);
          setbsDisabled(true);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const [clientSearch, setClientSearch] = useState({
    searchParam: '',
    options: [],
    isOpen: false,
    selected: '',
    exists: true,
  });
  const { t } = useTranslation();

  const history = useHistory();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    setValue: setValue2,
    getValues: getValues2,
    resetField: resetField2,
    watch: watch2,
  } = useForm({
    resolver: yupResolver(schemas.CreateIncidenceSchema(t)),
  });

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
    setValue: setValue3,
    getValues: getValues3,
    resetField: resetField3,
    watch: watch3,
    setError: setError3
  } = useForm({
    resolver: yupResolver(schemas.CreateAVFormSchema(t)),
  });

  const {
    register: register4,
    handleSubmit: handleSubmit4,
    formState: { errors: errors4 },
    control: control4,
  } = useForm({
    resolver: yupResolver(schemas.ScheduleServiceSchema(t)),
  });

  const [_case, setCase] = useState('');
  const [_caseForm, setCaseForm] = useState('');
  let { caseId } = useParams();
  const [origin, setOrigin] = useState({
    lat: '',
    lng: '',
    address: '',
  });
  const [destiny, setDestiny] = useState({
    lat: '',
    lng: '',
    address: '',
  });

  const [route, setRoute] = useState({
    distance: '',
    time: '',
  });

  const editParticularCase = handleSubmit2(async (data) => {
    let dataToPost = { ...data };
    dataToPost.originLatitude = origin?.lat.toString();
    dataToPost.originLongitude = origin?.lng.toString();
    dataToPost.destinyLatitude = destiny?.lat.toString();
    dataToPost.destinyLongitude = destiny?.lng.toString();


    if (isValidMongoId(dataToPost?.originLocationStatus)) {
      dataToPost.originLocationId = watch2('originLocationStatus')
      dataToPost.originLocationStatus = addressEnum?.GARAGE
    }

    if (isValidMongoId(dataToPost?.destinyLocationStatus)) {
      dataToPost.destinyLocationId = watch2('destinyLocationStatus')
      dataToPost.destinyLocationStatus = addressEnum?.GARAGE
    }

    // Datos utilizados unicamente para manejar logica del form
    delete dataToPost?.isOriginBasement;
    delete dataToPost?.withOutDestinyAddress;
    // Unicamente para casos particulares
    dataToPost.particularCustomer = _case?.particularCustomer?._id;
    if (!isLoading) {
      setIsloading(true);
      caseService
        .editCase(filterParams(dataToPost), _case?._id)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Caso editado con exito`, 'success');
            setCase((prevState) => {
              return {
                ...prevState,
                selected: response?.data?.case,
              };
            });
            setIsloading(false);
            getServices(response?.data?.case);
            // history.push('/services');
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const getAvailableProducts = (_case) => {
    const params = {
      membershipId: _case?.membership?._id,
      originCityId: _case?.originCity?._id,
      distance: parseFloat(_case?.caseKm),
      caseId: watch2("thirdPartyService") ? _case?._id : "",
    };

    membershipProductsService
      .getAvailableProducts(filterParams(params))
      .then((response) => {
        if (response.status === 200) {
          let availableServices = response?.data?.availableServices;
          if (watch2('withOutDestinyAddress')) {
            availableServices = availableServices.filter(
              (a) =>
                ![particularServices.crane, afiliatedServices.crane].includes(
                  a?.code
                )
            );
          }
          setAvailableServices((prevState) => {
            return {
              ...prevState,
              selected: '',
              available: [...availableServices],
            };
          });
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };

  const getServices = (_case) => {
    productService
      .getAvailableProducts(_case?.originCity)
      .then((response) => {
        if (response.status === 200) {
          let availableServices = response?.data?.availableServices;
          if (watch2('withOutDestinyAddress')) {
            availableServices = availableServices.filter(
              (a) =>
                ![particularServices.crane, afiliatedServices.crane].includes(
                  a?.code
                )
            );
          }
          setAvailableServices((prevState) => {
            return {
              ...prevState,
              selected: '',
              available: [...availableServices],
            };
          });
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };

  const getCaseDetail = (caseId) => {
    caseService
      .getCaseById(_case?._id)
      .then((response) => {
        if (response.status === 200) {
          setCaseInfo(response?.data?.case);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };

  const selectService = (service) => {
    getCaseDetail();
    let services = availableServices?.available;
    setSelectedBike('');
    services.forEach((e) => {
      e.selected = false;
      if (e._id === service._id) {
        e.selected = true;
      }

      setAvailableServices((prevState) => {
        return {
          ...prevState,
          selected: service,
          available: [...services],
        };
      });
    });
  };


  const clearThirdPartyData = () => {
    resetField2('thirdPartyParticularCustomerName');
    resetField2('thirdPartyParticularCustomerLastName');
    resetField2('thirdPartyServicePhonePrefix');
    resetField2('thirdPartyServicePhoneNumber');
    resetField2('thirdPartyVehicleBrand');
    resetField2('thirdPartyVehicleModel');
    resetField2('thirdPartyVehicleColor');
    resetField2('thirdPartyVehicleYear');
    resetField2('thirdPartyVehiclePlate');
  };

  const getVehicleBrands = () => {
    vehicleService
      .getVehicleBrands()
      .then(response => {
        if (response.status === 200) {
          setBrandOptions(response?.data?.vehicleBrands);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(error => { });
  };

  const handleModelOptsions = (brand) => {
    vehicleService
      .getVehicleModels(brand)
      .then(response => {
        if (response.status === 200) {
          setModelOptions(response?.data?.models);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(error => { });
  };



  const editAffiliatedCase = handleSubmit2(async (data) => {
    let dataToPost = { ...data };
    dataToPost.originLatitude = origin?.lat.toString();
    dataToPost.originLongitude = origin?.lng.toString();


    if (isValidMongoId(dataToPost?.originLocationStatus)) {
      dataToPost.originLocationId = watch2('originLocationStatus')
      dataToPost.originLocationStatus = addressEnum?.GARAGE
    }

    if (isValidMongoId(dataToPost?.destinyLocationStatus)) {
      dataToPost.destinyLocationId = watch2('destinyLocationStatus')
      dataToPost.destinyLocationStatus = addressEnum?.GARAGE
    }

    dataToPost.destinyLatitude = destiny?.lat.toString();
    dataToPost.destinyLongitude = destiny?.lng.toString();

    // Datos utilizados unicamente para manejar logica del form
    delete dataToPost?.isOriginBasement;
    delete dataToPost?.withOutDestinyAddress;

    // Unicamente para casos particulares
    dataToPost.membership = clientSearch?.selected?._id;

    if (!isLoading) {
      setIsloading(true);
      caseService
        .editCase(filterParams(dataToPost), _case?._id)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Caso editado con exito`, 'success');
            setCase((prevState) => {
              return {
                ...prevState,
                selected: response?.data?.case,
              };
            });
            setIsloading(false);
            getServices(response?.data?.case); // history.push('/services');
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const changePaymentStatus = () => {
    setValue3('authorizedBy', "");
    if (watch3('paymentStatus') === PaymentStatus?.COURTESY) {
      setValue3('pvp', 0);
      return null
    }
    if (watch3('paymentStatus') === PaymentStatus?.N_A) {
      setValue3('pvp', 0);
    } else {
      setValue3('pvp', '');
    }
  };

  const restorePaymentMethods = () => {
    if (parseFloat(watch3('pvp')) === 0) {
      for (let i = 0; i < parseInt(watch3('paymentMethods')); i++) {
        resetField3(`method${i}`);
        resetField3(`reference${i}`);
        resetField3(`paymentMethod${i}`);
        resetField3(`amount${i}`);
        resetField3(`amountBsS${i}`);
      }
      resetField3('paymentMethods');
    }
    // setValue3()
  };

  const validateMaxPaymentMethods = () => {
    if (watch3('paymentMethods') > 5) {
      setValue3('paymentMethods', 5);
      notify('El limite permitido es de 5 métodos de pago', 'info');
    }
  };

  const refreshAfterCancel = () => {
    history.push('/services');
  };

  const appendFile = (data) => {
    const formData = new FormData();
    formData.append('case', data?.case);
    formData.append('product', data?.product);
    formData.append('provider', data?.provider);
    formData.append('price', data?.price);
    formData.append('pvp', data?.pvp);
    formData.append('paymentStatus', data?.paymentStatus);
    if (data?.authorizedBy) {
      formData.append('authorizedBy', data?.authorizedBy);
    }
    // SI es cuentas por cobrar o NA no anexar datos ni comprobantes de pago

    if (
      ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
        watch3('paymentStatus')
      )
    ) {
      for (var i = 0; i < data?.payments.length; i++) {
        formData.append(`payments[${i}][amount]`, data?.payments[i].amount);
        formData.append(
          `payments[${i}][amountBsS]`,
          data?.payments[i].amountBsS
        );
        formData.append(`payments[${i}][method]`, data?.payments[i].method);
        formData.append(
          `payments[${i}][reference]`,
          data?.payments[i].reference
        );
      }

      for (let i = 0; i < parseInt(watch3('paymentMethods')); i++) {
        formData.append(`paymentEvidence`, fileUploaded[i]);
      }
    }

    // Load as many files as payment methods we have
    if (
      fileUploaded.length !== parseFloat(watch3('paymentMethods')) &&
      parseFloat(watch3('paymentMethods')) > 0 &&
      ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
        watch3('paymentStatus')
      )
    ) {
      notify('Ingrese comprobantes de pago', 'info');
      return null;
    }
    if (
      parseFloat(watch3('pvp')) &&
      ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
        watch3('paymentStatus')
      )
    ) {
      let totalSum = 0;
      for (let i = 0; i < data?.payments.length; i++) {
        totalSum = totalSum + parseFloat(data?.payments[i].amount);
      }
      if (totalSum !== parseFloat(watch3('pvp'))) {
        notify(
          'Monto a pagar por el cliente y suma de los pagos no coinciden',
          'info'
        );
        return null;
      }
    }

    for (let j = 0; j < parseFloat(watch3("cashbackMethodsQuantity")); j++) {
      formData.append(`paymentChanges[${j}][paid]`, data[`paid`]);
      formData.append(`paymentChanges[${j}][method]`, data[`returnedMethod${j + 1}`]);
      formData.append(`paymentChanges[${j}][amount]`, data[`returnedAmount${j + 1}`]);
      formData.append(`paymentChanges[${j}][amountBsS]`, data[`returnedAmountBsS${j + 1}`]);
      if (data[`returnedReference${j + 1}`]) {
        formData.append(`paymentChanges[${j}][ref]`, data[`returnedReference${j + 1}`]);
      }
      formData.append(`paymentChanges[${j}][hasEvidence]`, data[`returnedReference${j + 1}`] ? true : false);
      if (cashBackImage[`image${j + 1}`]) {
        formData.append(`paymentChangeEvidence`, cashBackImage[`image${j + 1}`]);
      }
    }

    return formData;
  };

  const validateTotalPayment = (data) => {
    if (
      (parseFloat(watch3("pvp")) >= 0 || parseFloat(watch3("amountHandling")) >= 0 || parseFloat(watch3("amountExcess")) >= 0) && ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(watch3('paymentStatus'))
    ) {
      let totalSum = 0;
      for (let i = 0; i < watch3("paymentMethods"); i++) {
        totalSum = totalSum + parseFloat(watch3(`amount${i}`));
      }
      if (totalSum !== (parseNull(watch3('pvp')) + parseNull(watch3('amountHandling')) + parseNull(watch3('amountExcess')))) {
        notify(
          'Monto a pagar por el cliente y la suma de los pagos no coinciden',
          'info'
        );
        return false;
      } else return true
    }
    else return true
  }

  const createAVService = handleSubmit3(async (data) => {

    let isCash = watch3("method0") === filterOption(paymentMethods, metodosDePago?.CASH_USD)?._id

    // Valida que la suma de los pagos sea igual al total a pagar
    if (!validateTotalPayment(data)) {
      return null
    }


    if (isCash) {
      calculateCashBack()

      if (parseFloat(watch3("cashbackMethodsQuantity")) > 0) {
        // Validate the total of the cashback
        // Validate that all images are up
        if (!validateCashBackTotal() || !validateCashBackImages()) {
          return null
        }
      }
    }

    let dataToPost = { ...data };
    dataToPost = loadPaymentForms(dataToPost);
    delete dataToPost?.contact;
    delete dataToPost?.vehicleBrand;
    delete dataToPost?.vehicleColor;
    delete dataToPost?.vehicleYear;
    delete dataToPost?.vehicleModel;
    delete dataToPost?.estimatedTime;
    delete dataToPost?.vehiclePlate;

    dataToPost.product = availableServices?.selected?._id;
    dataToPost.case = _case?._id;
    dataToPost.provider = selectedBike; // // Unicamente para casos particulares

    let fileToPost = appendFile(dataToPost);
    if (!isLoading && fileToPost) {
      setIsloading(true);
      servicesService
        .createAVService(fileToPost)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`AV creado con exito`, 'success');
            history.push('/services');
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const selectBikeProvider = (e) => {
    setSelectedBike(e?.target?.value);
  };

  const handleCancelService = (incidence) => {
    if (incidence) {
      membershipService
        .getCancelReason(incidence?.caseType)
        .then((response) => {
          if (response.status === 200) {
            setShowCancelService({
              cancelOptions: response?.data?.newCancellationReasons,
              show: !showCancelService.show,
              selected: incidence ? incidence : '',
            });
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(() => { });
    } else {
      setShowCancelService({
        cancelOptions: [],
        show: !showCancelService.show,
        selected: incidence ? incidence : '',
      });
    }
  };

  const openSMSTowModal = () => {
    setShowSMSTowModal((prevState) => {
      return {
        ...prevState,
        isOpen: !showSMSTowModal?.isOpen,
      };
    });
  };

  const handleEditService = (_case) => {
    if (_case) {
      servicesService
        .updateAV(_case?._id, { product: availableServices?.selected?._id })
        .then((response) => {
          if (response.status === 200) {
            notify(`Servicio Editado`, "success");
            history?.push('/services');
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(() => { });
    }
  };

  const registerPayment = () => {
    if (_case?.particularCustomer) {
      setValue3('estimatedTime', exists(_case?.estimatedTime));
      setValue3(
        'contact',
        _case?.particularCustomer?.phonePrefix +
        '-' +
        _case?.particularCustomer?.phoneNumber
      );
      setValue3('vehicleYear', _case?.particularCustomer?.vehicleYear);
      setValue3(
        'vehicleColor',
        exists(_case?.particularCustomer?.vehicleColor)
      );
      setValue3(
        'vehiclePlate',
        exists(_case?.particularCustomer?.vehiclePlate)
      );
      setValue3(
        'vehicleBrand',
        capitalize(_case?.particularCustomer?.vehicleBrand?.brand)
      );
      setValue3(
        'vehicleModel',
        capitalize(_case?.particularCustomer?.vehicleModel?.model)
      );
    } else {
      setValue3(
        'contact',
        _case?.membership?.customer?.phonePrefix +
        '-' +
        _case?.membership?.customer?.phoneNumber
      );

      setValue3('vehicleYear', _case?.membership?.vehicle?.year);
      setValue3('vehicleModel', capitalize(_case?.membership?.vehicle?.model));
      setValue3('vehicleBrand', capitalize(_case?.membership?.vehicle?.brand));
      setValue3('vehiclePlate', exists(_case?.membership?.vehicle?.plate));
      setValue3('vehicleColor', capitalize(_case?.membership?.vehicle?.color));
      setValue3('estimatedTime', exists(_case?.estimatedTime));
    }

    setOpenPayModal(true);
  };

  const scheduleService = handleSubmit4(async (data) => {
    let fecha = moment(data?.date).format('YYYY-MM-DD');
    let date = new Date(fecha + 'T' + data?.time);
    date = moment(date).format();

    if (!isLoading) {
      setIsloading(true);
      caseService
        .scheduleCase(
          { date: date, productId: availableServices?.selected?._id },
          _case?._id
        )
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Servicio agendado con exito`, 'success');

            if (
              [afiliatedServices?.crane, particularServices?.crane].includes(
                availableServices?.selected?.code
              )
            ) {
              history.push('/services');
              setIsloading(false);
            } else {
              openSMSModal();
              setShowSchedule(false);
              setIsloading(false);
            }
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const setBsRate = (e) => {
    const { name, value } = e?.target;

    if (!dollarRate) {
      setbsDisabled(false);
    }
    if (name.includes('amount')) {
      setValue3(
        name.replace('amount', 'amountBsS'),
        (value * dollarRate).toFixed(2)
      );
    }
  };

  const unSelectService = () => {
    let services = availableServices?.available;
    setSelectedBike('');
    services.forEach((e) => {
      e.selected = false;
      setAvailableServices((prevState) => {
        return {
          ...prevState,
          selected: '',
          available: [...services],
        };
      });
    });
  };

  let loadPaymentForms = (params) => {
    let payments = [];
    // lOOP through every payment method
    for (let i = 0; i < parseInt(getValues3('paymentMethods')); i++) {
      payments.push({
        method: params[`method` + i.toString()],
        reference: params[`reference` + i.toString()],
        amount: params[`amount` + i.toString()],
        amountBsS: params[`amountBsS` + i.toString()],
      });
    }

    // "REmove unwanted params from JSON"
    let words = ['method', 'reference', 'amount', 'amountBsS', 'paymentMethod'];
    for (const key in params) {
      for (var i = 0, ln = words.length; i < ln; i++) {
        if (key.indexOf(words[i]) !== -1) {
          delete params[key];
        }
      }
    }
    params.payments = payments;
    return params;
  };

  const startTowService = () => {

    if (_case?.membership && !reason) {
      notify('Ingresar campos para realizar la busqueda', 'info');
      return null;
    }

    const data = {
      case: _case?._id,
      reason: reason
    }

    if (!isLoading) {
      setIsloading(true);
      stagesService
        .createInitialTowStage(filterParams(data))
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            history.push('/services');
            notify(`Servicio creado en búsqueda de grúa`, 'success');
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  };

  const getPaymentMethods = () => {
    productService
      .getPaymentMethods('operations')
      .then((response) => {
        if (response.status === 200) {
          setPaymentMethods(response?.data);
          setFilteredMethods(response?.data?.filter((d) => d?.name !== metodosDePago?.CASH_USD));
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };

  const preloadForm = (_case) => {
    if (_case?.caseType === 'Afiliado') {
      getMembershipById(_case?.membership?._id);
      preloadCaseAfiliatedForm(_case);
    } else {
      preloadCaseParticularForm(_case);
    }
    setValue2('originState', _case?.originState?._id);
    getCities(_case?.originState?._id, referenceLocation?.ORIGIN, _case?.originCity?._id);
    setValue2('originAddress', _case?.originAddress);
    setValue2('originReferencePoint', _case?.originReferencePoint);
    setValue2('destinyReferencePoint', _case?.destinyReferencePoint);
    if (_case?.fault?.description === "REPORTADO POR BOTMAKER") {
      setValue2("customerFailureReason", "")
    } else {
      setValue2('customerFailureReason', _case?.fault?._id);
    }

    if (_case?.destinyState) {
      setValue2('destinyState', _case?.destinyState?._id);
      getCities(
        _case?.destinyState?._id,
        referenceLocation?.DESTINY,
        _case?.destinyCity?._id
      );
      setValue2('destinyAddress', _case?.destinyAddress);
    } else {
      setValue2('withOutDestinyAddress', true);
    }

    setCase(_case);
    setCaseForm(_case);
    setValue2('caseKm', _case?.caseKm);

    if (_case?.thirdPartyService) {
      setValue2('thirdPartyService', _case?.thirdPartyService);
      setValue2('thirdPartyParticularCustomerName', _case?.thirdPartyParticularCustomerName);
      setValue2('thirdPartyParticularCustomerLastName', _case?.thirdPartyParticularCustomerLastName);
      setValue2('thirdPartyVehicleYear', _case?.thirdPartyVehicleYear);
      setValue2('thirdPartyVehicleColor', _case?.thirdPartyVehicleColor);
      setValue2('thirdPartyVehiclePlate', _case?.thirdPartyVehiclePlate);
      setValue2('thirdPartyServicePhonePrefix', _case?.thirdPartyServicePhonePrefix);
      setValue2('thirdPartyServicePhoneNumber', _case?.thirdPartyServicePhoneNumber);
      setValue2('thirdPartyVehicleBrand', _case?.thirdPartyVehicleBrand?._id);
      handleModelOptsions(_case?.thirdPartyVehicleBrand?._id)
      setTimeout(() => {
        setValue2('thirdPartyVehicleModel', _case?.thirdPartyVehicleModel?._id);
      }, 1500);

    }

  };

  const openMapModal = () => {
    if (
      !getValues2('originState') ||
      !getValues2('originCity') ||
      !getValues2('originAddress')
    ) {
      notify('Ingrese datos de origen', 'error');
      return null;
    }
    if (
      !getValues2('destinyState') ||
      !getValues2('destinyCity') ||
      !getValues2('destinyAddress')
    ) {
      notify('Ingrese datos de destino', 'error');
      return null;
    }
    let [originState] = statesOptions.filter(
      (s) => s._id === getValues2('originState')
    );
    let [originCity] = originCitiesOptions.filter(
      (c) => c._id === getValues2('originCity')
    );
    let [destinyState] = statesOptions.filter(
      (s) => s._id === getValues2('destinyState')
    );
    let [destinyCity] = originCitiesOptions.filter(
      (c) => c._id === getValues2('destinyCity')
    );
    setOrigin({
      lat: '',
      lng: '',
      address:
        'Venezuela ' +
        capitalize(originState?.name) +
        ' ' +
        capitalize(originCity?.name) +
        ' ' +
        getValues2('originAddress'),
    });
    setDestiny({
      lat: '',
      lng: '',
      address:
        'Venezuela ' +
        capitalize(destinyState?.name) +
        ' ' +
        capitalize(destinyCity?.name) +
        ' ' +
        getValues2('destinyAddress'),
    });
    setMapModalOpen(true);
  };

  const validateCases = (_case) => {
    if (_case?.fault?.description === "REPORTADO POR BOTMAKER" && !watch2("customerFailureReason")) {
      return false
    }

    // Aqi ponet una condicion
    if (_case?.services?.length == 0) {
      return true
    }
    return true
  }

  const handleCloseModal = () => {
    setValue2('caseKm', route?.distance);
    setValue2('estimatedTime', route?.time);
    setMapModalOpen(false);
    setRoute({
      time: '',
      distance: '',
    });
  };

  const preloadCaseAfiliatedForm = (_case) => {
    setValue2('contactNumberPrefix', _case?.contactNumberPrefix);
    setValue2('contactNumber', _case?.contactNumber);
    setValue2('customerFailureDescription', _case?.customerFailureDescription);
    setValue2('originLocationStatus', addressEnum?.OTHER);
    setValue2('isOriginBasement', false);
    setValue2('destinyLocationStatus', addressEnum?.OTHER);
  };

  const preloadCaseParticularForm = (_case) => {
    setValue2('contactNumberPrefix', _case?.contactNumberPrefix);
    setValue2('contactNumber', _case?.contactNumber);
    setValue2('customerFailureDescription', _case?.customerFailureDescription);
    setValue2('originLocationStatus', addressEnum?.OTHER);
    setValue2('isOriginBasement', false);
    setValue2('destinyLocationStatus', addressEnum?.OTHER);
  };

  const getMembershipById = (membershipId) => {
    membershipService
      .getMembershipById(membershipId, 'operations')
      .then((response) => {
        if (response.status === 200) {
          setClientSearch((prevState) => {
            return {
              ...prevState,
              selected: response?.data?.membership,
              isOpen: false,
            };
          });
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };

  const getCaseById = (caseId) => {
    caseService
      .getCaseById(caseId)
      .then((response) => {
        if (response.status === 200) {
          Promise.allSettled([
            getPaymentMethods(),
            getStates(),
            getFaults(),
            getAVs(),
            getGarageLocation(response?.data?.case)
          ]).then((r) => {
            setTimeout(() => {
              preloadForm(response?.data?.case);
            }, 1500);
          });

          // if (response?.data?.case?.services?.length === 0) {
          if (!response?.data?.case?.membership) {
            getServices({
              originCity: response?.data?.case?.originCity?._id,
            });
          } else {
            getAvailableProducts(response?.data?.case);
          }
          // }
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };

  const forceRecalulateDistance = () => {
    setValue2('caseKm', '');
  };

  const getCities = async (e, reference, cityId) => {
    forceRecalulateDistance();
    statesService
      .getCities(e?.target?.value ? e?.target?.value : e)
      .then((response) => {
        if (response.status === 200) {
          if (reference === referenceLocation?.ORIGIN) {
            setOriginCitiesOptions(response?.data?.cities);
            if (cityId) {
              setValue2('originCity', cityId);
            }
          }
          if (reference === referenceLocation?.DESTINY) {
            setDestinyCitiesOptions(response?.data?.cities);
            if (cityId) {
              setValue2('destinyCity', cityId);
            }
          }
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
    return true;
  };

  const getStates = () => {
    statesService
      .getStates()
      .then((response) => {
        if (response.status === 200) {
          setStatesOptions(response?.data?.states);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const getFaults = () => {
    faultsService
      .getFaults()
      .then((response) => {
        if (response.status === 200) {
          setFaultsOptions(response?.data?.newFaults);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const handleLocation = (location, reference) => {
    if (location && ![addressEnum?.OWN, addressEnum?.OTHER]?.includes(location)) {
      let [filteredOption] = addressOption.filter((a) => a?._id === location);
      setValue2(`${reference}State`, filteredOption?.stateId);
      statesService
        .getCities(filteredOption?.stateId)
        .then((response) => {
          if (response.status === 200) {
            if (reference === referenceLocation?.ORIGIN) {
              setOriginCitiesOptions(response?.data?.cities);
            }
            if (reference === referenceLocation?.DESTINY) {
              setDestinyCitiesOptions(response?.data?.cities);
            }
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(() => { });

      setTimeout(() => {
        setValue2(`${reference}City`, filteredOption?.cityId);
        setValue2(`${reference}Address`, filteredOption?.address);
      }, 1200);
    }

  }

  const selectGarage = (e, reference) => {
    forceRecalulateDistance();
    const { value } = e?.target;
    // Preload GARAGE data
    if (value && ![addressEnum?.OWN, addressEnum?.OTHER]?.includes(value)) {
      handleLocation(value, reference)
    }
    // Own Value
    if (value === addressEnum?.OWN) {
      Promise.allSettled([
        getCities(clientSearch?.selected?.vehicle?.stateId, reference),
      ]).then((r) => {
        setTimeout(() => {
          setValue2(`${reference}State`, clientSearch?.selected?.vehicle?.stateId);
          setValue2(`${reference}City`, clientSearch?.selected?.vehicle?.cityId);
          setValue2(
            `${reference}Address`,
            capitalize(clientSearch?.selected?.vehicle?.address)
          );
        }, 1500);
      });
    }
  };
  const getAVs = () => {
    providersService
      .getAVs()
      .then((response) => {
        if (response.status === 200) {
          setProvidersOptions(response?.data?.providers);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };

  const validateCashBackImages = () => {
    let cashbackMethodsQuantity = parseFloat(watch3("cashbackMethodsQuantity"))
    let canPost = true


    setErrorsDropzone((prevState) => {
      return {
        ...prevState,
        error1: false,
        error2: false,
        error3: false,
      };
    });

    for (let i = 1; i <= cashbackMethodsQuantity; i++) {

      const requiredMetods = paymentMethods?.filter((method) => [metodosDePago?.CASH_BS, metodosDePago?.CASH_EUR, metodosDePago?.CASH_USD, metodosDePago?.PUNTO_DE_VENTA]?.includes(method?.name))
      const method = filterOptionById(requiredMetods, watch3("returnedMethod" + i))

      if (method && !watch3(`returnedReference${i}`)) {
        setError3(`returnedReference${i}`, { type: 'custom', message: 'Obligatorio' });
        canPost = false

      }

      if (!cashBackImage[`image${i}`] && method) {
        notify(`Adjunte todos los comprobantes de pago de ${method?.name}`, 'error');
        canPost = false
        setErrorsDropzone((prevState) => {
          return {
            ...prevState,
            [`error${i}`]: true,
          };
        });
      }
    }
    return canPost
  }



  const calculateCashBack = () => {

    if (parseFloat(watch3("paid")) > parseFloat(watch3("amount0"))) {
      setValue3("paidBsS", (parseFloat(watch3("paid")) * dollarRate)?.toFixed(2))

      if (!watch3("cashbackMethodsQuantity")) {
        setValue3("cashbackMethodsQuantity", 1)
        let amountReturned = parseFloat(watch3(`amount0`)) - parseFloat(watch3("paid"))
        if (parseFloat(watch3(`amount1IGTF`))) {
          amountReturned = amountReturned + parseFloat(watch3(`amount1IGTF`))
        }
        amountReturned = amountReturned?.toFixed(2)
        setValue3("amountReturned", Math.abs(amountReturned))
        setValue3("amountReturnedBsS", (parseFloat(watch3("amountReturned")) * dollarRate)?.toFixed(2))
        setValue3(`returnedAmount1`, Math.abs(amountReturned))
        setValue3(`returnedAmountBsS1`, (parseFloat(watch3("amountReturned")) * dollarRate)?.toFixed(2))
      }
    }
  }

  const validateCashBackTotal = () => {
    let cashbackMethodsQuantity = parseFloat(watch3("cashbackMethodsQuantity"))
    let amountReturned = parseFloat(watch3(`amountReturned`))
    let total = 0
    for (let i = 1; i <= cashbackMethodsQuantity; i++) {
      total = total + parseFloat(watch3(`returnedAmount${i}`))
    }

    if (total !== amountReturned) {
      notify('El la suma de los montos debe ser igual al monto a entregar', 'error');
      return false
    }
    if (!watch3("returnedMethod1")) {
      // setError3(`returnedMethod1`, { type: 'custom', message: 'Obligatorio' });
      return false
    }
    else {
      return true
    }
  }

  const handleCashbackMethodsQuantity = () => {
    for (let i = 1; i <= 3; i++) {
      setValue3(`returnedMethod${i}`, "")
      setValue3(`returnedAmount${i}`, "")
      setValue3(`returnedAmountBsS${i}`, "")
      setCashBackImage((prevState) => {
        return {
          ...prevState,
          [`image${i}`]: ""
        };
      });
    }

    if (watch3("cashbackMethodsQuantity") === "1") {
      setValue3(`returnedAmount1`, watch3("amountReturned"))
      setValue3(`returnedAmountBsS1`, (parseFloat(watch3("amountReturned")) * dollarRate)?.toFixed(2))
    }
  }

  const handleCashbackBs = (index) => {

    let cashbackMethodsQuantity = parseFloat(watch3("cashbackMethodsQuantity"))
    let amountReturned = parseFloat(watch3(`amountReturned`))

    setValue3(`returnedAmountBsS${index}`, (parseFloat(watch3(`returnedAmount${index}`)) * dollarRate)?.toFixed(2))

    // Automatically set the amount for the last method
    if (index === cashbackMethodsQuantity - 1) {
      let paid = 0
      for (let i = 1; i < cashbackMethodsQuantity; i++) {
        paid = paid + parseFloat(watch3(`returnedAmount${i}`))
      }

      if (amountReturned - paid <= 0) {
        notify('El la suma de los montos no puede superar el vuelto a entregar', 'error');
        for (let j = 1; j <= cashbackMethodsQuantity; j++) {
          setValue3(`returnedAmount${j}`, 0)
          setValue3(`returnedAmountBsS${j}`, 0)
        }
        return null
      }

      setValue3(`returnedAmount${cashbackMethodsQuantity}`, (amountReturned - paid)?.toFixed(2))
      setValue3(`returnedAmountBsS${cashbackMethodsQuantity}`, (parseFloat(watch3(`returnedAmount${cashbackMethodsQuantity}`)) * dollarRate)?.toFixed(2))
    }
  }


  const handleDrop = (event, index) => {
    let file = ""
    // File added on drop
    if (event?.dataTransfer?.files) {
      file = Array?.from(event?.dataTransfer?.files)[0];
    }

    let maxWeight = 5 //Peso en MB

    // File added on Click
    if (event?.target?.files?.length > 0) {
      file = event?.target?.files[0];
    }

    if (!['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(file.type)
    ) {
      notify('Agregar un formato valido de imagen', 'error');
      return null;
    }
    if (file.size * 0.000001 > maxWeight) {
      notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
    }
    setCashBackImage((prevState) => {
      return {
        ...prevState,
        [`image${index}`]: file
      };
    });
  };


  const removeFile = (index) => {

    setCashBackImage((prevState) => {
      return {
        ...prevState,
        [`image${index}`]: ""
      };
    });

  }



  const getGarageLocation = (caso) => {
    garageService
      .getGarageAddress()
      .then((response) => {
        if (response.status === 200) {
          setAddressOption([caso?.caseType === caseType?.AFFILIATED && { name: addressEnum?.OWN, _id: addressEnum?.OWN },
          ...response?.data?.addresses, { name: addressEnum?.OTHER, _id: addressEnum?.OTHER },
          ]);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  }

  useEffect(() => {
    getVehicleBrands()
    getInternalTowServices();
    getCaseById(caseId);
  }, []);

  const validateEditCase = () => {
    if (_case?.status === enumStatus.INITIATED) {
      return false;
    } else return true;
  };

  validateEditCase();

  const getInternalTowServices = () => {
    let params = {
      type: 'all',
    };

    providersService
      .getInternalTowServices(params)
      .then((response) => {
        if (response.status === 200) {
          setShowCraneCounterModal((prevState) => {
            return {
              ...prevState,
              data: response?.data,
            };
          });
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };

  const openDocumentModal = (src, service) => {
    setShowCraneCounterModal((prevState) => {
      return {
        ...prevState,
        show: !showCraneCounterModal?.show,
        src: !showCraneCounterModal?.src ? src : '',
        service: service,
      };
    });
  };

  const zoomDocumentModal = (zoom) => {
    setShowCraneCounterModal((prevState) => {
      return {
        ...prevState,
        zoom: zoom,
      };
    });
  };

  const handleReason = (e) => {
    setReason(e?.target?.value);
  }


  return (
    <>
      <CraneCounter
        showCraneCounterModal={showCraneCounterModal}
        openDocumentModal={openDocumentModal}
        zoomDocumentModal={zoomDocumentModal}
        craneCounter={craneCounter}
      />
      <MessageAVModal
        showSMSModal={showSMSModal}
        handleClose={openSMSModal}
        caseInfo={caseInfo}
        _case={_case}
        action={registerPayment}
        service={availableServices?.selected}
      />

      <MessageTowModal
        showSMSModal={showSMSTowModal}
        handleClose={openSMSTowModal}
        caseInfo={caseInfo}
        action={startTowService}
      />

      <ScheduleServicesModal
        show={showSchedule}
        handleClose={() => setShowSchedule(false)}
        register={register4}
        control={control4}
        errors={errors4}
        action={scheduleService}
        t={t}
      />

      <CreateAVModal
        show={openPayModal}
        register={register3}
        openPayModal={openPayModal}
        handleClose={() => setOpenPayModal()}
        createAVService={createAVService}
        errors={errors3}
        options={{
          paymentMethods: paymentMethods,
          PaymentStatusOptions: PaymentStatusOptions,
          authorizedByOptions: authorizedByOptions,
          filteredMethods: filteredMethods
        }}
        onChange={{
          changePaymentStatus: changePaymentStatus,
          restorePaymentMethods: restorePaymentMethods,
          setBsRate: setBsRate,
          validateMaxPaymentMethods: validateMaxPaymentMethods,
          handleCashbackMethodsQuantity: handleCashbackMethodsQuantity,
          handleCashbackBs: handleCashbackBs
        }}
        watch={watch3}
        setFileUploaded={setFileUploaded}
        fileUploaded={fileUploaded}
        bsDisabled={bsDisabled}
        handleDrop={handleDrop}
        cashBackImage={cashBackImage}
        removeFile={removeFile}
        errorsDropzone={errorsDropzone}
      />

      <Header />
      <div className="container">
        {_case && (
          <div className="card incidence-card">
            <div className="title-center-component">
              <h4>Caso nro. {_case?.caseId} </h4>
              <p style={{ color: '#6B6B6B', margin: '0px' }}>
                Fecha de creación:{' '}
                <b> {moment(_case?.createdAt).format('DD/MM/YYYY')}</b>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Hora de creación:&nbsp;
                <b>{moment(_case?.createdAt).format('LTS')}</b>
              </p>
            </div>
            <br />
            <div className="light-gray-line"></div>
            <ClientDetail membership={_case?.membership} _case={_case} />
            {_caseForm && (
              <IncidenceDetailForm
                register={register2}
                editCase={
                  _case?.caseType === 'Afiliado'
                    ? editAffiliatedCase
                    : editParticularCase
                }
                errors={errors2}
                setValue={setValue2}
                getValues={getValues2}
                watch={watch2}
                onChange={{
                  getCities: getCities,
                  selectGarage: selectGarage,
                  clearThirdPartyData: clearThirdPartyData,
                  forceRecalulateDistance: forceRecalulateDistance,
                  handleModelOptsions: handleModelOptsions
                }}
                options={{
                  statesOptions: statesOptions,
                  originCitiesOptions: originCitiesOptions,
                  destinycitiesOptions: destinycitiesOptions,
                  faultsOptions: faultsOptions,
                  addressOption: addressOption,
                  brandOptions: brandOptions,
                  modelOptions: modelOptions,
                }}
                openMapModal={openMapModal}
                _case={_case}
                disabled={validateEditCase()}
                clientSearch={clientSearch}
              />
            )}
            <MapModal
              show={mapModalOpen}
              handleClose={handleCloseModal}
              origin={origin}
              setOrigin={setOrigin}
              destiny={destiny}
              setDestiny={setDestiny}
              route={route}
              setRoute={setRoute}
            />

            {validateCases(_case) && (
              <AvailableServices
                availableServices={availableServices}
                selectService={selectService}
                _case={_case}
              />
            )}

            <CancelServiceModal
              showCancelService={showCancelService}
              handleClose={() => handleCancelService()}
              refresh={refreshAfterCancel}
            />

            {availableServices?.selected && (
              <SelectedServices
                providersOptions={providersOptions}
                availableServices={availableServices}
                unSelectService={unSelectService}
                value={selectedBike}
                onChange={selectBikeProvider}
                openSMSModal={openSMSModal}
                openSMSTowModal={openSMSTowModal}
                _case={_case}
                handleReason={handleReason}
                reason={reason}
              />
            )}

            {availableServices?.selected && (
              <HandleCaseButtons
                setShowSchedule={setShowSchedule}
                startTowService={startTowService}
                selectedBike={selectedBike}
                service={availableServices?.selected}
                _case={_case}
                handleCancelService={handleCancelService}
                registerPayment={registerPayment}
                openModals={{
                  openSMSTowModal: openSMSTowModal,
                  openSMSModal: openSMSModal,
                  handleEditService: handleEditService
                }}
                bikerData={getOption(selectedBike, providersOptions)}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export const CaseDetail = (props) => {
  const { title, data } = props;

  return (
    <div className="incidence-table-section-container">
      <div className="filter-button-container"></div>
      <div className="buttons-container">
        <h6>{title}</h6>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6 col-xl-4">
          <p>
            <b>Avería: </b>
            {exists(data?.fault?.description)}
            <br />
            <b>Caso Nro: </b> {exists(data?.caseId)}
            <br />
            <b>Operador: </b>
            {exists(data?.user?.firstName) +
              '   ' +
              exists(data?.user?.lastName)}
          </p>
        </div>
      </div>
    </div>
  );
};
