import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as schemas from '../../../assets/schemas/schemas';
import { ActionButton } from '../../common/Buttons/Buttons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Header } from '../../common/Header/Header';
import { LoadSpinner } from '../../common/LoadSpinner/LoadSpinner';
import {
  ScheduleServicesModal,
  MapModal,
  CancelCaseModal,
  CreateAVModal,
  MessageAVModal,
  MessageTowModal,
} from '../../common/Modals/Modals';
import { vehicleService } from '../../../services/vehicleService';
import { membershipService } from '../../../services/membershipService';
import { servicesService } from '../../../services/servicesService';
import { particularCustomerService } from '../../../services/particularCustomerService';
import { stagesService } from '../../../services/stagesService';
import { caseService } from '../../../services/caseService';
import { providersService } from '../../../services/providersService';
import { faultsService } from '../../../services/faultsService';
import { statesService } from '../../../services/statesService';
import { productService } from '../../../services/productService';
import { rateService } from '../../../services/rateService';
import { garageService } from '../../../services/garageService';
import { notify } from '../../../assets/helpers/toast';
import {
  IncidenceSearch,
  AvailableServices,
  SelectedServices,
  IncidenceDetailForm,
  ClientDetailSectionForm,
  HandleCaseButtons,
} from './IncidenceSections';
import './Incidence.scss';
import {
  clientTypes,
  notFound,
  locationOptions,
  garageLocation,
  particularAddressOptions,
  particularServices,
  afiliatedServices,
  PaymentStatusOptions,
  PaymentStatus,
  authorizedByOptions,
  paymentMethods as metodosDePago,
  addressEnum, referenceLocation
} from '../../../assets/helpers/options';
import {
  capitalize,
  filterParams,
  exists,
  getOption,
  filterOption,
  filterOptionById,
  parseNull,
  isValidMongoId
} from '../../../assets/helpers/funciones';
import { CraneCounter } from '../../miscelaneos/CraneCounter/CraneCounter.jsx';
import moment from 'moment';

export const ParticularIncidence = () => {
  const { t } = useTranslation();

  const [statesOptions, setStatesOptions] = useState([]);
  const [originCitiesOptions, setOriginCitiesOptions] = useState([]);
  const [destinycitiesOptions, setDestinyCitiesOptions] = useState([]);
  const [faultsOptions, setFaultsOptions] = useState([]);
  const [providersOptions, setProvidersOptions] = useState([]);
  const [bsDisabled, setbsDisabled] = useState(true);
  const [showCancelService, setShowCancelService] = useState({
    show: false,
    caseSelected: '',
    cancelOptions: [],
  });

  const [showCraneCounterModal, setShowCraneCounterModal] = useState({
    show: false,
    src: '',
    zoom: 1,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schemas.CreateParticularCustomer(t)),
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    setValue: setValue2,
    getValues: getValues2,
    resetField: resetField2,
    reset: reset2,
    watch: watch2,
  } = useForm({
    resolver: yupResolver(schemas.CreateIncidenceSchema(t)),
  });

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
    setValue: setValue3,
    getValues: getValues3,
    resetField: resetField3,
    watch: watch3,
    setError: setError3
  } = useForm({
    resolver: yupResolver(schemas.CreateAVFormSchema(t)),
  });

  const {
    register: register4,
    handleSubmit: handleSubmit4,
    formState: { errors: errors4 },
    control: control4,
  } = useForm({
    resolver: yupResolver(schemas.ScheduleServiceSchema(t)),
  });

  const [mapModalOpen, setMapModalOpen] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);
  const [vehicleBrandOptions, setVehicleBrandOptions] = useState([]);
  const [vehicleModelOptions, setVehicleModelOptions] = useState([]);
  const [dollarRate, setDollarRate] = useState('');
  const [errorsDropzone, setErrorsDropzone] = useState({});
  const [cashBackImage, setCashBackImage] = useState([]);
  const [addressOption, setAddressOption] = useState([]);
  const [showSMSModal, setShowSMSModal] = useState({
    isOpen: false,
    provider: '',
  });
  const [showSMSTowModal, setShowSMSTowModal] = useState({
    isOpen: false,
    provider: '',
  });
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [filteredMethods, setFilteredMethods] = useState([]);
  const [selectedBike, setSelectedBike] = useState('');
  const [caseInfo, setCaseInfo] = useState();
  const [fileUploaded, setFileUploaded] = useState([]);
  const [availableServices, setAvailableServices] = useState({
    selected: '',
    available: [],
  });
  const [openPayModal, setOpenPayModal] = useState(false);
  const [clientSearch, setClientSearch] = useState({
    searchParam: '',
    options: [],
    isOpen: false,
    selected: '',
    exists: true,
  });

  const [_case, setCase] = useState({
    selected: '',
  });
  const [origin, setOrigin] = useState({
    lat: '',
    lng: '',
    address: '',
  });
  const [destiny, setDestiny] = useState({
    lat: '',
    lng: '',
    address: '',
  });

  const [route, setRoute] = useState({
    distance: '',
    time: '',
  });

  let history = useHistory();
  // Function that handles the logout request

  const scheduleService = handleSubmit4(async (data) => {
    let fecha = moment(data?.date).format('YYYY-MM-DD');
    let date = new Date(fecha + 'T' + data?.time);
    date = moment(date).format();

    if (!isLoading) {
      setIsloading(true);
      caseService
        .scheduleCase(
          { date: date, productId: availableServices?.selected?._id },
          _case?.selected?._id
        )
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Servicio agendado con exito`, 'success');

            if (
              [afiliatedServices?.crane, particularServices?.crane].includes(
                availableServices?.selected?.code
              )
            ) {
              history.push('/services');
              setIsloading(false);
            } else {
              openSMSModal();
              setShowSchedule(false);
              setIsloading(false);
            }
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const createParticularCustomer = handleSubmit(async (data) => {
    data.vehicleYear = data?.vehicleYear.toString();

    if (!isLoading) {
      setIsloading(true);
      particularCustomerService
        .createParticularCustomer(data)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Cliente registrado con exito`, 'success');
            setIsloading(false);
            setClientSearch((prevState) => {
              return {
                ...prevState,
                selected: response?.data?.particularCustomer,
              };
            });
            setValue2(
              'contactNumber',
              response?.data?.particularCustomer?.phoneNumber
            );
            setValue2(
              'contactNumberPrefix',
              response?.data?.particularCustomer?.phonePrefix
            );
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const editParticularCustomer = handleSubmit(async (data) => {
    let dataToUpdate = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      phonePrefix: data?.phonePrefix,
      phoneNumber: data?.phoneNumber,
      vehicleModel: data?.vehicleModel,
      vehicleBrand: data?.vehicleBrand,
      vehicleColor: data?.vehicleColor,
      vehicleYear: data.vehicleYear?.toString(),
      vehiclePlate: data?.vehiclePlate,
    };

    if (!isLoading) {
      setIsloading(true);
      particularCustomerService
        .editParticularCustomer(dataToUpdate, clientSearch?.selected?._id)
        .then((response) => {
          if (response.status === 200) {
            notify(`Cliente editado con exito`, 'success');
            setIsloading(false);
            let selected = { ...dataToUpdate };

            selected._id = clientSearch?.selected?._id;
            selected.vehicleBrand = { _id: selected.vehicleBrand };
            selected.vehicleModel = { _id: selected.vehicleModel };

            setClientSearch((prevState) => {
              return {
                ...prevState,
                selected: selected,
              };
            });
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const createParticularCase = handleSubmit2(async (data) => {
    let dataToPost = { ...data };

    dataToPost.originLatitude = origin?.lat.toString();
    dataToPost.originLongitude = origin?.lng.toString();

    dataToPost.destinyLatitude = destiny?.lat.toString();
    dataToPost.destinyLongitude = destiny?.lng.toString();

    if (isValidMongoId(dataToPost?.originLocationStatus)) {
      dataToPost.originLocationId = watch2('originLocationStatus')
      dataToPost.originLocationStatus = addressEnum?.GARAGE
    }

    if (isValidMongoId(dataToPost?.destinyLocationStatus)) {
      dataToPost.destinyLocationId = watch2('destinyLocationStatus')
      dataToPost.destinyLocationStatus = addressEnum?.GARAGE
    }

    // Datos utilizados unicamente para manejar logica del form
    delete dataToPost?.isOriginBasement;
    delete dataToPost?.withOutDestinyAddress;

    // Unicamente para casos particulares
    dataToPost.particularCustomer = clientSearch?.selected?._id;

    if (!isLoading) {
      setIsloading(true);
      caseService
        .createCase(filterParams(dataToPost))
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Caso creado con exito`, 'success');
            getServices(response?.data?.case);
            setCase((prevState) => {
              return {
                ...prevState,
                selected: response?.data?.case,
              };
            });
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const editParticularCase = handleSubmit2(async (data) => {
    let dataToPost = { ...data };

    dataToPost.originLatitude = origin?.lat.toString();
    dataToPost.originLongitude = origin?.lng.toString();

    dataToPost.destinyLatitude = destiny?.lat.toString();
    dataToPost.destinyLongitude = destiny?.lng.toString();

    if (isValidMongoId(dataToPost?.originLocationStatus)) {
      dataToPost.originLocationId = watch2('originLocationStatus')
      dataToPost.originLocationStatus = addressEnum?.GARAGE
    }

    if (isValidMongoId(dataToPost?.destinyLocationStatus)) {
      dataToPost.destinyLocationId = watch2('destinyLocationStatus')
      dataToPost.destinyLocationStatus = addressEnum?.GARAGE
    }

    // Datos utilizados unicamente para manejar logica del form
    delete dataToPost?.isOriginBasement;
    delete dataToPost?.withOutDestinyAddress;

    // Unicamente para casos particulares
    dataToPost.particularCustomer = clientSearch?.selected?._id;

    if (!isLoading) {
      setIsloading(true);
      caseService
        .editCase(filterParams(dataToPost), _case?.selected?._id)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Caso editado con exito`, 'success');
            getServices(response?.data?.case);
            setCase((prevState) => {
              return {
                ...prevState,
                selected: response?.data?.case,
              };
            });
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const getVehicleBrands = () => {
    vehicleService.getVehicleBrands().then((response) => {
      if (response.status === 200) {
        setVehicleBrandOptions(response?.data?.vehicleBrands);
      } else {
        notify(`${response.message}`, 'error');
      }
    });
  };

  const getVehicleModels = (brandId) => {
    vehicleService
      .getVehicleModels(brandId ? brandId : getValues('vehicleBrand'))
      .then((response) => {
        if (response.status === 200) {
          setVehicleModelOptions(response?.data?.models);
        } else {
          notify(`${response.message}`, 'error');
        }
      });
  };

  // Recalculate distance if origin or destny changes
  const forceRecalulateDistance = () => {
    setValue2('caseKm', '');
  };

  const clearDestinyData = () => {
    resetField2('destinyLocationStatus');
    resetField2('destinyState');
    resetField2('destinyCity');
    resetField2('destinyAddress');
  };

  const preloadForm = (option) => {
    if (option) {
      setValue('firstName', option?.firstName);
      setValue('lastName', option?.lastName);
      setValue('phonePrefix', option?.phonePrefix);
      setValue('phoneNumber', option?.phoneNumber);
      setValue('vehicleBrand', option?.vehicleBrand?._id);
      setValue('vehicleColor', option?.vehicleColor);
      setValue('vehiclePlate', option?.vehiclePlate);
      setValue('vehicleYear', option?.vehicleYear);

      Promise.allSettled([
        getVehicleModels(option?.vehicleBrand?._id),
        getVehicleBrands(),
      ]).then((r) => {
        setTimeout(() => {
          setValue('vehicleBrand', option?.vehicleBrand?._id);
          setValue('vehicleModel', option?.vehicleModel?._id);
        }, 1500);
      });

      // Falta hacer get del listado de marcas de vehiculo

      setClientSearch((prevState) => {
        return {
          ...prevState,
          isOpen: false,
          searchParam: option?.vehiclePlate,
          selected: option,
          exists: true,
        };
      });
      preloadDefaultCaseForm();
    } else {
      getVehicleBrands();
      setValue('vehiclePlate', clientSearch?.searchParam);
      setClientSearch((prevState) => {
        return {
          ...prevState,
          isOpen: false,
          searchParam: option?.vehiclePlate,
          selected: notFound,
          exists: false,
        };
      });
      preloadDefaultCaseForm();
    }
  };

  // Precarga el formulario de casos con la info por defecto
  // independientemente de si ya hay datos o no del cliente
  const preloadDefaultCaseForm = () => {
    setValue2('originLocationStatus', particularAddressOptions[1]?._id);
    setValue2('isOriginBasement', locationOptions[0]?._id);
    setValue2('withOutDestinyAddress', false);
    setValue2('contactNumberPrefix', getValues('phonePrefix'));
    setValue2('contactNumber', getValues('phoneNumber'));
    setValue2('destinyLocationStatus', particularAddressOptions[1]?._id);
  };

  const selectClient = (option) => {
    reset();
    preloadForm(option);
  };

  const handleClientSearchChange = (e) => {
    const { value, name } = e.target;
    reset();
    reset2();
    setCase({ selected: '' });
    setAvailableServices({
      selected: '',
      available: [],
    });

    setClientSearch((prevState) => {
      return {
        ...prevState,
        [name]: value,
        selected: '',
      };
    });
  };

  const getMembership = (searchParam) => {
    if (!searchParam) {
      notify('Ingresar una placa para realizar la busqueda', 'info');
      return null;
    }
    setIsloading(true);
    particularCustomerService
      .getCasesByOperator(searchParam)
      .then((response) => {
        if (response.status === 200) {
          setClientSearch((prevState) => {
            return {
              ...prevState,
              options: response?.data?.particularCustomers,
              isOpen: true,
            };
          });
          setIsloading(false);
        } else {
          notify(`${response.message}`, 'error');
          setIsloading(false);
        }
      })
      .catch((error) => {
        setIsloading(false);
      });
  };

  const getServices = (_case) => {
    productService
      .getAvailableProducts(_case?.originCity)
      .then((response) => {
        if (response.status === 200) {
          let availableServices = response?.data?.availableServices;
          if (watch2('withOutDestinyAddress')) {
            availableServices = availableServices.filter(
              (a) =>
                ![particularServices.crane, afiliatedServices.crane].includes(
                  a?.code
                )
            );
          }
          setAvailableServices((prevState) => {
            return {
              ...prevState,
              selected: '',
              available: [...availableServices],
            };
          });
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };

  const getCaseDetail = (caseId) => {
    caseService
      .getCaseById(_case?.selected?._id)
      .then((response) => {
        if (response.status === 200) {
          setCaseInfo(response?.data?.case);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };

  const openSMSModal = () => {
    getDollarRate();
    let [provider] = providersOptions.filter((p) => p?._id === selectedBike);
    setShowSMSModal((prevState) => {
      return {
        ...prevState,
        isOpen: !showSMSModal?.isOpen,
        provider: provider,
      };
    });
  };

  const openSMSTowModal = () => {
    setShowSMSTowModal((prevState) => {
      return {
        ...prevState,
        isOpen: !showSMSTowModal?.isOpen,
      };
    });
  };

  const setBsRate = (e) => {
    const { name, value } = e?.target;

    if (!dollarRate) {
      setbsDisabled(false);
    }
    if (name.includes('amount')) {
      setValue3(
        name.replace('amount', 'amountBsS'),
        (value * dollarRate).toFixed(2)
      );
    }
  };

  const getDollarRate = () => {
    rateService
      .getDollarRate()
      .then((response) => {
        if (response.status === 200) {
          setDollarRate(response?.data?.rate);
          setbsDisabled(true);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const selectService = (service) => {
    getCaseDetail();
    let services = availableServices?.available;
    setSelectedBike('');
    services.forEach((e) => {
      e.selected = false;
      if (e._id === service._id) {
        e.selected = true;
      }

      setAvailableServices((prevState) => {
        return {
          ...prevState,
          selected: service,
          available: [...services],
        };
      });
    });
  };

  const getStates = () => {
    statesService
      .getStates()
      .then((response) => {
        if (response.status === 200) {
          setStatesOptions(response?.data?.states);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const getCities = (e, reference) => {
    forceRecalulateDistance();
    statesService
      .getCities(e?.target?.value ? e?.target?.value : e)
      .then((response) => {
        if (response.status === 200) {
          if (reference === referenceLocation?.ORIGIN) {
            setOriginCitiesOptions(response?.data?.cities);
          }
          if (reference === referenceLocation?.DESTINY) {
            setDestinyCitiesOptions(response?.data?.cities);
          }
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
    return true;
  };

  const getFaults = () => {
    faultsService
      .getFaults()
      .then((response) => {
        if (response.status === 200) {
          setFaultsOptions(response?.data?.newFaults);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const handleLocation = (location, reference) => {
    if (location && ![addressEnum?.OWN, addressEnum?.OTHER]?.includes(location)) {
      let [filteredOption] = addressOption.filter((a) => a?._id === location);
      setValue2(`${reference}State`, filteredOption?.stateId);
      statesService
        .getCities(filteredOption?.stateId)
        .then((response) => {
          if (response.status === 200) {
            if (reference === referenceLocation?.ORIGIN) {
              setOriginCitiesOptions(response?.data?.cities);
            }
            if (reference === referenceLocation?.DESTINY) {
              setDestinyCitiesOptions(response?.data?.cities);
            }
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(() => { });

      setTimeout(() => {
        setValue2(`${reference}City`, filteredOption?.cityId);
        setValue2(`${reference}Address`, filteredOption?.address);
      }, 1200);
    }

  }


  // Function that preloads garage location as origin or destiny
  const selectGarage = (e, reference) => {
    forceRecalulateDistance();
    const { value } = e?.target;

    if (value && ![addressEnum?.OWN, addressEnum?.OTHER]?.includes(value)) {
      handleLocation(value, reference)
    }
  };

  const unSelectService = () => {
    let services = availableServices?.available;
    setSelectedBike('');
    services.forEach((e) => {
      e.selected = false;
      setAvailableServices((prevState) => {
        return {
          ...prevState,
          selected: '',
          available: [...services],
        };
      });
    });
  };

  const openMapModal = () => {
    if (
      !getValues2('originState') ||
      !getValues2('originCity') ||
      !getValues2('originAddress')
    ) {
      notify('Ingrese datos de origen', 'error');
      return null;
    }
    if (
      !getValues2('destinyState') ||
      !getValues2('destinyCity') ||
      !getValues2('destinyAddress')
    ) {
      notify('Ingrese datos de destino', 'error');
      return null;
    }

    let [originState] = statesOptions.filter(
      (s) => s._id === getValues2('originState')
    );
    let [originCity] = originCitiesOptions.filter(
      (c) => c._id === getValues2('originCity')
    );

    let [destinyState] = statesOptions.filter(
      (s) => s._id === getValues2('destinyState')
    );
    let [destinyCity] = originCitiesOptions.filter(
      (c) => c._id === getValues2('destinyCity')
    );

    setOrigin({
      lat: '',
      lng: '',
      address:
        'Venezuela ' +
        capitalize(originState?.name) +
        ' ' +
        capitalize(originCity?.name) +
        ' ' +
        getValues2('originAddress'),
    });

    setDestiny({
      lat: '',
      lng: '',
      address:
        'Venezuela ' +
        capitalize(destinyState?.name) +
        ' ' +
        capitalize(destinyCity?.name) +
        ' ' +
        getValues2('destinyAddress'),
    });
    setMapModalOpen(true);
  };

  const handleCloseModal = () => {
    setValue2('caseKm', route?.distance);
    setValue2('estimatedTime', route?.time);
    setMapModalOpen(false);
    setRoute({
      time: '',
      distance: '',
    });
  };

  const selectBikeProvider = (e) => {
    setSelectedBike(e?.target?.value);
  };

  const getAVs = () => {
    providersService
      .getAVs()
      .then((response) => {
        if (response.status === 200) {
          setProvidersOptions(response?.data?.providers);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };

  const getGarageLocation = (caso) => {
    garageService
      .getGarageAddress()
      .then((response) => {
        if (response.status === 200) {
          setAddressOption([...response?.data?.addresses, { name: addressEnum?.OTHER, _id: addressEnum?.OTHER },
          ]);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  }


  const startTowService = () => {
    if (!isLoading) {
      setIsloading(true);
      stagesService
        .createInitialTowStage({ case: _case?.selected?._id })
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            history.push('/services');
            notify(`Servicio creado en búsqueda de grúa`, 'success');
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  };

  const refreshAfterCancel = () => {
    history.push('/services');
  };

  const registerPayment = () => {
    setValue3(
      'contact',
      clientSearch?.selected?.phonePrefix +
      '-' +
      clientSearch?.selected?.phoneNumber
    );
    setValue3('vehicleYear', clientSearch?.selected?.vehicleYear);
    setValue3(
      'vehicleModel',
      capitalize(
        clientSearch?.selected?.vehicleModel?.model ||
        vehicleModelOptions.filter(
          (v) => v?._id === clientSearch?.selected?.vehicleModel
        )[0]?.name
      )
    );
    setValue3(
      'vehicleBrand',
      capitalize(
        clientSearch?.selected?.vehicleBrand?.brand ||
        vehicleBrandOptions.filter(
          (v) => v?._id === clientSearch?.selected?.vehicleBrand
        )[0]?.name
      )
    );
    setValue3('vehiclePlate', exists(clientSearch?.selected?.vehiclePlate));
    setValue3('vehicleColor', exists(clientSearch?.selected?.vehicleColor));
    setValue3('estimatedTime', exists(_case?.selected?.estimatedTime));
    setOpenPayModal(true);
  };

  // Load payemnt methods and refactor JSON
  let loadPaymentForms = (params) => {
    let payments = [];
    // lOOP through every payment method
    for (let i = 0; i < parseInt(getValues3('paymentMethods')); i++) {
      payments.push({
        method: params[`method` + i.toString()],
        reference: params[`reference` + i.toString()],
        amount: params[`amount` + i.toString()],
        amountBsS: params[`amountBsS` + i.toString()],
      });
    }

    // "REmove unwanted params from JSON"
    let words = ['method', 'reference', 'amount', 'amountBsS', 'paymentMethod'];
    for (const key in params) {
      for (var i = 0, ln = words.length; i < ln; i++) {
        if (key.indexOf(words[i]) !== -1) {
          delete params[key];
        }
      }
    }
    params.payments = payments;
    return params;
  };

  const validateCashBackImages = () => {
    let cashbackMethodsQuantity = parseFloat(watch3("cashbackMethodsQuantity"))
    let canPost = true


    setErrorsDropzone((prevState) => {
      return {
        ...prevState,
        error1: false,
        error2: false,
        error3: false,
      };
    });

    for (let i = 1; i <= cashbackMethodsQuantity; i++) {

      const requiredMetods = paymentMethods?.filter((method) => [metodosDePago?.CASH_BS, metodosDePago?.CASH_EUR, metodosDePago?.CASH_USD, metodosDePago?.PUNTO_DE_VENTA]?.includes(method?.name))
      const method = filterOptionById(requiredMetods, watch3("returnedMethod" + i))

      if (method && !watch3(`returnedReference${i}`)) {
        setError3(`returnedReference${i}`, { type: 'custom', message: 'Obligatorio' });
        canPost = false

      }

      if (!cashBackImage[`image${i}`] && method) {
        notify(`Adjunte todos los comprobantes de pago de ${method?.name}`, 'error');
        canPost = false
        setErrorsDropzone((prevState) => {
          return {
            ...prevState,
            [`error${i}`]: true,
          };
        });
      }
    }
    return canPost
  }



  const calculateCashBack = () => {

    if (parseFloat(watch3("paid")) > parseFloat(watch3("amount0"))) {
      setValue3("paidBsS", (parseFloat(watch3("paid")) * dollarRate)?.toFixed(2))

      if (!watch3("cashbackMethodsQuantity")) {
        setValue3("cashbackMethodsQuantity", 1)
        let amountReturned = parseFloat(watch3(`amount0`)) - parseFloat(watch3("paid"))
        if (parseFloat(watch3(`amount1IGTF`))) {
          amountReturned = amountReturned + parseFloat(watch3(`amount1IGTF`))
        }
        amountReturned = amountReturned?.toFixed(2)
        setValue3("amountReturned", Math.abs(amountReturned))
        setValue3("amountReturnedBsS", (parseFloat(watch3("amountReturned")) * dollarRate)?.toFixed(2))
        setValue3(`returnedAmount1`, Math.abs(amountReturned))
        setValue3(`returnedAmountBsS1`, (parseFloat(watch3("amountReturned")) * dollarRate)?.toFixed(2))
      }
    }
  }

  const validateCashBackTotal = () => {
    let cashbackMethodsQuantity = parseFloat(watch3("cashbackMethodsQuantity"))
    let amountReturned = parseFloat(watch3(`amountReturned`))
    let total = 0
    for (let i = 1; i <= cashbackMethodsQuantity; i++) {
      total = total + parseFloat(watch3(`returnedAmount${i}`))
    }

    if (total !== amountReturned) {
      notify('El la suma de los montos debe ser igual al monto a entregar', 'error');
      return false
    }
    if (!watch3("returnedMethod1")) {
      // setError3(`returnedMethod1`, { type: 'custom', message: 'Obligatorio' });
      return false
    }
    else {
      return true
    }
  }

  const handleCashbackMethodsQuantity = () => {
    for (let i = 1; i <= 3; i++) {
      setValue3(`returnedMethod${i}`, "")
      setValue3(`returnedAmount${i}`, "")
      setValue3(`returnedAmountBsS${i}`, "")
      setCashBackImage((prevState) => {
        return {
          ...prevState,
          [`image${i}`]: ""
        };
      });
    }

    if (watch3("cashbackMethodsQuantity") === "1") {
      setValue3(`returnedAmount1`, watch3("amountReturned"))
      setValue3(`returnedAmountBsS1`, (parseFloat(watch3("amountReturned")) * dollarRate)?.toFixed(2))
    }
  }

  const handleCashbackBs = (index) => {

    let cashbackMethodsQuantity = parseFloat(watch3("cashbackMethodsQuantity"))
    let amountReturned = parseFloat(watch3(`amountReturned`))

    setValue3(`returnedAmountBsS${index}`, (parseFloat(watch3(`returnedAmount${index}`)) * dollarRate)?.toFixed(2))

    // Automatically set the amount for the last method
    if (index === cashbackMethodsQuantity - 1) {
      let paid = 0
      for (let i = 1; i < cashbackMethodsQuantity; i++) {
        paid = paid + parseFloat(watch3(`returnedAmount${i}`))
      }

      if (amountReturned - paid <= 0) {
        notify('El la suma de los montos no puede superar el vuelto a entregar', 'error');
        for (let j = 1; j <= cashbackMethodsQuantity; j++) {
          setValue3(`returnedAmount${j}`, 0)
          setValue3(`returnedAmountBsS${j}`, 0)
        }
        return null
      }

      setValue3(`returnedAmount${cashbackMethodsQuantity}`, (amountReturned - paid)?.toFixed(2))
      setValue3(`returnedAmountBsS${cashbackMethodsQuantity}`, (parseFloat(watch3(`returnedAmount${cashbackMethodsQuantity}`)) * dollarRate)?.toFixed(2))
    }
  }


  const handleDrop = (event, index) => {
    let file = ""
    // File added on drop
    if (event?.dataTransfer?.files) {
      file = Array?.from(event?.dataTransfer?.files)[0];
    }

    let maxWeight = 5 //Peso en MB

    // File added on Click
    if (event?.target?.files?.length > 0) {
      file = event?.target?.files[0];
    }

    if (!['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(file.type)
    ) {
      notify('Agregar un formato valido de imagen', 'error');
      return null;
    }
    if (file.size * 0.000001 > maxWeight) {
      notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
    }
    setCashBackImage((prevState) => {
      return {
        ...prevState,
        [`image${index}`]: file
      };
    });
  };


  const removeFile = (index) => {

    setCashBackImage((prevState) => {
      return {
        ...prevState,
        [`image${index}`]: ""
      };
    });

  }

  const validateTotalPayment = (data) => {
    if (
      (parseFloat(watch3("pvp")) >= 0 || parseFloat(watch3("amountHandling")) >= 0 || parseFloat(watch3("amountExcess")) >= 0) && ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(watch3('paymentStatus'))
    ) {
      let totalSum = 0;
      for (let i = 0; i < watch3("paymentMethods"); i++) {
        totalSum = totalSum + parseFloat(watch3(`amount${i}`));
      }
      if (totalSum !== (parseNull(watch3('pvp')) + parseNull(watch3('amountHandling')) + parseNull(watch3('amountExcess')))) {
        notify(
          'Monto a pagar por el cliente y la suma de los pagos no coinciden',
          'info'
        );
        return false;
      } else return true
    }
    else return true
  }

  const createAVService = handleSubmit3(async (data) => {

    let isCash = watch3("method0") === filterOption(paymentMethods, metodosDePago?.CASH_USD)?._id

    // Valida que la suma de los pagos sea igual al total a pagar
    if (!validateTotalPayment(data)) {
      return null
    }


    if (isCash) {
      calculateCashBack()

      if (parseFloat(watch3("cashbackMethodsQuantity")) > 0) {
        // Validate the total of the cashback
        // Validate that all images are up
        if (!validateCashBackTotal() || !validateCashBackImages()) {
          return null
        }
      }
    }


    let dataToPost = { ...data };
    dataToPost = loadPaymentForms(dataToPost);
    delete dataToPost?.contact;
    delete dataToPost?.vehicleBrand;
    delete dataToPost?.vehicleColor;
    delete dataToPost?.vehicleYear;
    delete dataToPost?.vehicleModel;
    delete dataToPost?.estimatedTime;
    delete dataToPost?.vehiclePlate;

    dataToPost.product = availableServices?.selected?._id;
    dataToPost.case = _case?.selected?._id;
    dataToPost.provider = selectedBike; // // Unicamente para casos particulares

    let fileToPost = appendFile(dataToPost);
    if (!isLoading && fileToPost) {
      setIsloading(true);
      servicesService
        .createAVService(fileToPost)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`AV creado con exito`, 'success');
            history.push('/services');
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const changePaymentStatus = () => {
    if (watch3('paymentStatus') === PaymentStatus?.N_A) {
      setValue3('pvp', 0);
    } else {
      setValue3('pvp', '');
    }
  };

  // If the payment method is set to 0 clear the form for the N payment methods
  const restorePaymentMethods = () => {
    if (parseFloat(watch3('pvp')) === 0) {
      for (let i = 0; i < parseInt(watch3('paymentMethods')); i++) {
        resetField3(`method${i}`);
        resetField3(`reference${i}`);
        resetField3(`paymentMethod${i}`);
        resetField3(`amount${i}`);
        resetField3(`amountBsS${i}`);
      }
      resetField3('paymentMethods');
    }
    // setValue3()
  };

  const validateMaxPaymentMethods = () => {
    if (watch3('paymentMethods') > 5) {
      setValue3('paymentMethods', 5);
      notify('El limite permitido es de 5 métodos de pago', 'info');
    }
  };

  const appendFile = (data) => {
    const formData = new FormData();
    formData.append('case', data?.case);
    formData.append('product', data?.product);
    formData.append('provider', data?.provider);
    formData.append('price', data?.price);
    formData.append('pvp', data?.pvp);
    formData.append('paymentStatus', data?.paymentStatus);

    // SI es cuentas por cobrar o NA no anexar datos ni comprobantes de pago

    if (
      ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
        watch3('paymentStatus')
      )
    ) {
      for (var i = 0; i < data?.payments.length; i++) {
        formData.append(`payments[${i}][amount]`, data?.payments[i].amount);
        formData.append(
          `payments[${i}][amountBsS]`,
          data?.payments[i].amountBsS
        );
        formData.append(`payments[${i}][method]`, data?.payments[i].method);
        formData.append(
          `payments[${i}][reference]`,
          data?.payments[i].reference
        );
      }

      for (let i = 0; i < parseInt(watch3('paymentMethods')); i++) {
        formData.append(`paymentEvidence`, fileUploaded[i]);
      }
    }

    // Load as many files as payment methods we have
    if (
      fileUploaded.length !== parseFloat(watch3('paymentMethods')) &&
      parseFloat(watch3('paymentMethods')) > 0 &&
      ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
        watch3('paymentStatus')
      )
    ) {
      notify('Ingrese comprobantes de pago', 'info');
      return null;
    }
    if (
      parseFloat(watch3('pvp')) &&
      ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
        watch3('paymentStatus')
      )
    ) {
      let totalSum = 0;
      for (let i = 0; i < data?.payments.length; i++) {
        totalSum = totalSum + parseFloat(data?.payments[i].amount);
      }
      if (totalSum !== parseFloat(watch3('pvp'))) {
        notify(
          'Monto a pagar por el cliente y suma de los pagos no coinciden',
          'info'
        );
        return null;
      }
    }

    for (let j = 0; j < parseFloat(watch3("cashbackMethodsQuantity")); j++) {
      formData.append(`paymentChanges[${j}][paid]`, data[`paid`]);
      formData.append(`paymentChanges[${j}][method]`, data[`returnedMethod${j + 1}`]);
      formData.append(`paymentChanges[${j}][amount]`, data[`returnedAmount${j + 1}`]);
      formData.append(`paymentChanges[${j}][amountBsS]`, data[`returnedAmountBsS${j + 1}`]);
      if (data[`returnedReference${j + 1}`]) {
        formData.append(`paymentChanges[${j}][ref]`, data[`returnedReference${j + 1}`]);
      }
      formData.append(`paymentChanges[${j}][hasEvidence]`, data[`returnedReference${j + 1}`] ? true : false);
      if (cashBackImage[`image${j + 1}`]) {
        formData.append(`paymentChangeEvidence`, cashBackImage[`image${j + 1}`]);
      }
    }


    return formData;
  };

  const handleCancelService = (incidence) => {
    if (incidence) {
      membershipService
        .getCancelReason(incidence?.caseType)
        .then((response) => {
          if (response.status === 200) {
            setShowCancelService({
              cancelOptions: response?.data?.newCancellationReasons,
              show: !showCancelService.show,
              selected: incidence ? incidence : '',
            });
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch((error) => { });
    } else {
      setShowCancelService({
        cancelOptions: [],
        show: !showCancelService.show,
        selected: incidence ? incidence : '',
      });
    }
  };

  const getPaymentMethods = () => {
    productService
      .getPaymentMethods('operations')
      .then((response) => {
        if (response.status === 200) {
          setPaymentMethods(response?.data);
          setFilteredMethods(response?.data?.filter((d) => d?.name !== metodosDePago?.CASH_USD));
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };

  const getInternalTowServices = () => {
    let params = {
      type: 'all',
    };

    providersService
      .getInternalTowServices(params)
      .then((response) => {
        if (response.status === 200) {
          setShowCraneCounterModal((prevState) => {
            return {
              ...prevState,
              data: response?.data,
            };
          });
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };

  const openDocumentModal = (src, service) => {
    setShowCraneCounterModal((prevState) => {
      return {
        ...prevState,
        show: !showCraneCounterModal?.show,
        src: !showCraneCounterModal?.src ? src : '',
        service: service,
      };
    });
  };

  const zoomDocumentModal = (zoom) => {
    setShowCraneCounterModal((prevState) => {
      return {
        ...prevState,
        zoom: zoom,
      };
    });
  };

  useEffect(() => {
    getInternalTowServices();
    getPaymentMethods();
    getStates();
    getFaults();
    getAVs();
    getVehicleBrands();
    getGarageLocation()
  }, []);
  return (
    <>
      <LoadSpinner label={'Cargando'} show={isLoading} />
      <CraneCounter
        showCraneCounterModal={showCraneCounterModal}
        openDocumentModal={openDocumentModal}
        zoomDocumentModal={zoomDocumentModal}
      />
      <MessageAVModal
        showSMSModal={showSMSModal}
        handleClose={openSMSModal}
        caseInfo={caseInfo}
        _case={_case}
        action={registerPayment}
        service={availableServices?.selected}
      />
      <MessageTowModal
        showSMSModal={showSMSTowModal}
        handleClose={openSMSTowModal}
        caseInfo={caseInfo}
        action={startTowService}
      />
      <CreateAVModal
        show={openPayModal}
        register={register3}
        openPayModal={openPayModal}
        handleClose={() => setOpenPayModal()}
        createAVService={createAVService}
        errors={errors3}
        options={{
          paymentMethods: paymentMethods,
          PaymentStatusOptions: PaymentStatusOptions,
          authorizedByOptions: authorizedByOptions,
          filteredMethods: filteredMethods,

        }}
        onChange={{
          changePaymentStatus: changePaymentStatus,
          restorePaymentMethods: restorePaymentMethods,
          setBsRate: setBsRate,
          validateMaxPaymentMethods: validateMaxPaymentMethods,
          handleCashbackMethodsQuantity: handleCashbackMethodsQuantity,
          handleCashbackBs: handleCashbackBs
        }}
        watch={watch3}
        setFileUploaded={setFileUploaded}
        fileUploaded={fileUploaded}
        bsDisabled={bsDisabled}
        handleDrop={handleDrop}
        cashBackImage={cashBackImage}
        removeFile={removeFile}
        errorsDropzone={errorsDropzone}
      />
      <CancelCaseModal
        showCancelCase={showCancelService}
        handleClose={() => handleCancelService()}
        refresh={refreshAfterCancel}
      />
      <Header />
      <ScheduleServicesModal
        show={showSchedule}
        handleClose={() => setShowSchedule(false)}
        register={register4}
        control={control4}
        errors={errors4}
        action={scheduleService}
        t={t}
      />
      <div className="container">
        <div className="card incidence-card">
          <div className="buttons-container">
            <span></span>
            <ActionButton
              label={`Incidencia Afiliado`}
              action={() => history.push('/incidence/create/affiliated')}
            />
          </div>
          <div className="title-center-component">
            <h4>Nueva Incidencia</h4>
            <h5>{`Cliente Particular`}</h5>
          </div>
          <IncidenceSearch
            clientSearch={clientSearch}
            onChange={handleClientSearchChange}
            search={getMembership}
            selectClient={selectClient}
            type={clientTypes.PARTICULAR}
          />

          {clientSearch?.selected && (
            <ClientDetailSectionForm
              type={clientSearch?.selected}
              t={t}
              register={register}
              watch={watch}
              errors={errors}
              getValues={getValues}
              onSubmit={
                clientSearch?.selected === notFound
                  ? createParticularCustomer
                  : editParticularCustomer
              }
              onChange={{ getVehicleModels: getVehicleModels }}
              options={{
                vehicleBrandOptions: vehicleBrandOptions,
                vehicleModelOptions: vehicleModelOptions,
              }}
            />
          )}

          {clientSearch?.selected && clientSearch?.selected !== notFound && (
            <IncidenceDetailForm
              register={register2}
              createCase={createParticularCase}
              editCase={editParticularCase}
              errors={errors2}
              setValue={setValue2}
              getValues={getValues2}
              watch={watch2}
              onChange={{
                getCities: getCities,
                selectGarage: selectGarage,
                clearDestinyData: clearDestinyData,
                forceRecalulateDistance: forceRecalulateDistance,
              }}
              options={{
                statesOptions: statesOptions,
                originCitiesOptions: originCitiesOptions,
                destinycitiesOptions: destinycitiesOptions,
                faultsOptions: faultsOptions,
                addressOption: addressOption,
              }}
              openMapModal={openMapModal}
              _case={_case?.selected}
            />
          )}

          <MapModal
            show={mapModalOpen}
            handleClose={handleCloseModal}
            origin={origin}
            setOrigin={setOrigin}
            destiny={destiny}
            setDestiny={setDestiny}
            route={route}
            setRoute={setRoute}
          />

          {_case?.selected && (
            <AvailableServices
              availableServices={availableServices}
              selectService={selectService}
            />
          )}

          {_case?.selected && availableServices?.selected && (
            <SelectedServices
              register={register}
              providersOptions={providersOptions}
              availableServices={availableServices}
              unSelectService={unSelectService}
              value={selectedBike}
              onChange={selectBikeProvider}
              openSMSModal={openSMSModal}
              openSMSTowModal={openSMSTowModal}
              clientType={clientTypes?.PARTICULAR}
            />
          )}
          {_case?.selected && availableServices?.selected && (
            <HandleCaseButtons
              setShowSchedule={setShowSchedule}
              startTowService={startTowService}
              selectedBike={selectedBike}
              service={availableServices?.selected}
              _case={_case?.selected}
              handleCancelService={handleCancelService}
              registerPayment={registerPayment}
              openModals={{
                openSMSTowModal: openSMSTowModal,
                openSMSModal: openSMSModal,
              }}
              bikerData={getOption(selectedBike, providersOptions)}
            />
          )}
        </div>
      </div>
    </>
  );
};
