import React, { useState, useEffect } from 'react';
import { FollowingTable, TrazeTable } from '../../common/Tables/Tables';
import {
  caseFollowingheader,
  trazeHeader,
} from '../../../assets/helpers/tables';
import { useParams } from 'react-router-dom';
import { Header } from '../../common/Header/Header';
import { ClientDetail } from './IncidenceSections';
import { caseService } from '../../../services/caseService';
import { notify } from '../../../assets/helpers/toast';
import { exists } from '../../../assets/helpers/funciones';
import { LoadSpinner } from '../../common/LoadSpinner/LoadSpinner';
import moment from 'moment';

import './IncidenceDetail.scss';

export const IncidenceDetail = () => {
  const [_case, setCase] = useState();
  const [isLoading, setIsLoading] = useState(false);
  let { caseId } = useParams();

  const getCaseById = (caseId) => {
    setIsLoading(true);
    caseService
      .getCaseById(caseId)
      .then((response) => {
        if (response.status === 200) {
          setCase(response?.data?.case);
          setIsLoading(false);
        } else {
          notify(`${response.message}`, 'error');
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCaseById(caseId);
  }, []);

  return (
    <>
      <Header />
      <LoadSpinner show={isLoading} />
      <div className="container">
        {_case && (
          <div className="card incidence-card">
            <div className="title-center-component">
              <h4>Caso nro. {_case?.caseId} </h4>
              <p style={{ color: '#6B6B6B', margin: '0px' }}>
                Fecha de creación:{' '}
                <b> {moment(_case?.createdAt).format('DD/MM/YYYY')}</b>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Hora de creación:&nbsp;
                <b>{moment(_case?.createdAt).format('LTS')}</b>
              </p>
            </div>
            <br />
            <div className="light-gray-line"></div>
            <ClientDetail membership={_case?.membership} _case={_case} />
            <div className="light-gray-line"></div>
            <CaseDetail
              title={'DETALLES DEL CASO'}
              data={{
                caseId: _case?.caseId,
                user: _case?.user,
                fault: _case?.fault,
              }}
            />
            <div className="light-gray-line"></div>
            <FollowingTable
              title={'SEGUIMIENTO DEL CASO'}
              header={caseFollowingheader}
              data={_case?.tracking}
            />
            <div className="light-gray-line"></div>
            <TrazeTable
              title={'TRAZABILIDAD'}
              header={trazeHeader}
              data={_case?.stages}
            />
          </div>
        )}
      </div>
    </>
  );
};

export const CaseDetail = (props) => {
  const { title, data } = props;

  return (
    <div className="incidence-table-section-container">
      <div className="filter-button-container"></div>
      <div className="buttons-container">
        <h6>{title}</h6>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6 col-xl-4">
          <p>
            <b>Avería: </b>
            {exists(data?.fault?.description)}
            <br />
            <b>Caso Nro: </b> {exists(data?.caseId)}
            <br />
            {/* <b>Código de servicio: </b> CCCT15-0024435 <br />  */}
            <b>Operador: </b>
            {exists(data?.user?.firstName) +
              '   ' +
              exists(data?.user?.lastName)}
          </p>
        </div>
      </div>
    </div>
  );
};
